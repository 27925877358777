import { request } from '@giftery/axios';
import { DBCollection } from '@giftery/enums';
import { firestore } from '@giftery/firebase';
import { AxiosResponse } from 'axios';
import type { Stripe } from 'stripe';
import firebase from 'firebase/app';
import 'firebase/functions';
export interface StripeCustomer {
  customer: Stripe.Customer;
  invoices: Stripe.ApiList<Stripe.Invoice>;
  paymentMethods: Stripe.ApiList<Stripe.PaymentMethod>;
}

export const createSetupIntent = async (
  store: string
): Promise<AxiosResponse<{ success: boolean; secret: string }>> => {
  return await request('post', '/checkout/stripe/intent/setup', { store });
};

export const retrieveStripeCustomer = async (
  store: string
): Promise<AxiosResponse<StripeCustomer>> => {
  return await request('get', `/stripe/customer`);
};

export const deleteStripePaymentMethod = async (
  id: string
): Promise<AxiosResponse<StripeCustomer>> => {
  return await request('delete', `/stripe/customer/payment/${id}`);
};

export const createCheckoutSession = async (
  uid: string,
  tier: string
): Promise<string> => {
  // 1. Fetch the plan
  const subscriptionDocs = await firestore
    .collection(DBCollection.subscriptions)
    .get();
  if (subscriptionDocs.empty)
    throw new Error('Config error: Subscription config is missing');
  // 1.1. Get the first sub
  const sub = subscriptionDocs.docs[0];
  // 1.2. Get the prices
  const subscriptionPriceDoc = await sub.ref
    .collection(DBCollection.prices)
    .doc(tier)
    .get();
  if (!subscriptionPriceDoc.exists)
    throw new Error('Config error: Subscription price config is missing');
  const price = subscriptionPriceDoc.id;
  const checkoutSessionRef = await firestore
    .collection('users')
    .doc(uid)
    .collection('checkout_sessions')
    .add({
      price,
      allow_promotion_codes: true,
      success_url: window.location.href,
      cancel_url: window.location.href,
      trial_from_plan: true,
    });
  return new Promise((resolve, reject) => {
    try {
      checkoutSessionRef.onSnapshot(async (snap) => {
        const { sessionId } = snap.data();
        if (sessionId) {
          resolve(sessionId);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const createPortalLink = async () => {
  const functionRef = firebase
    .functions()
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
  const { data } = await functionRef({ returnUrl: window.location.href });
  return data?.url;
};
