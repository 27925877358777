import { DBCollection, DBDoc } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useStore } from './UseStore';

export function useStoreSecure() {
  const store = useStore();
  useFirestoreConnect([
    {
      collection: DBCollection.suppliers,
      doc: store?.id || 'NOT_YET_LOADED',
      subcollections: [
        {
          collection: DBCollection.metadata,
          doc: DBDoc.secure,
        },
      ],
      storeAs: 'storeSecure',
    },
  ]);
  const storeSecure = useSelector(
    (state: SupplierRootState) =>
      state.firestore.ordered.storeSecure?.[0] || null
  );
  return storeSecure;
}
