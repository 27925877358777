import fb from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import * as geofirestore from 'geofirestore';

const devConfig = {
  apiKey: 'AIzaSyBzbYiXIwvpHpYsyEF0L9DwO5X1c_t_7AU',
  authDomain: 'giftery-development.firebaseapp.com',
  projectId: 'giftery-development',
  storageBucket: 'giftery-development.appspot.com',
  messagingSenderId: '132457420458',
  appId: '1:132457420458:web:9465126b15ed163ddbe2d3',
  measurementId: 'G-2HJRHMCT5L',
};

const prodConfig = {
  apiKey: 'AIzaSyBOt_oMTA05lsT5SgmOFs27iXokXZJAKJo',
  authDomain: 'give-production.firebaseapp.com',
  databaseURL: `https://give-production.firebaseio.com`,
  projectId: 'give-production',
  storageBucket: 'give-production.appspot.com',
  messagingSenderId: '497586387391',
  appId: '1:497586387391:web:542caf34eabe566bdd890a',
  measurementId: 'G-D8037MR0GN',
};

let selectedConfig = devConfig;

if (process.env.NODE_ENV === 'production') {
  selectedConfig = prodConfig;
}

// Initialize Firebase
fb.initializeApp(selectedConfig);
export const config = selectedConfig;
export const firebase = fb;
export const firestore = firebase.firestore();
export const GeoFirestore = geofirestore.initializeApp(firestore);
export const storage = firebase.storage();
