import React from 'react';
import Loader from 'react-loader-spinner';
import { colors } from '@giftery/theme';
import './ui-loading-button.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  [key: string]: any;
  outline?: boolean;
  active?: boolean;
  block?: boolean;
  color?: string;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLButtonElement>;
  size?: string;
  close?: boolean;
}

/* eslint-disable-next-line */
export interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  feedback: string;
  icon?:
    | 'Oval'
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'Puff'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots'
    | 'Watch'
    | 'RevolvingDot'
    | 'Triangle'
    | 'Plane'
    | 'MutatingDots'
    | 'CradleLoader';
}

export function LoadingButton(props: LoadingButtonProps) {
  const { loading, feedback, icon, ...rest } = props;
  if (!loading) return <button {...rest}>{props.children}</button>;
  return (
    <button {...rest}>
      <Loader
        type={icon || 'Oval'}
        color={colors.background[500]}
        height={16}
        width={16}
      />
      {feedback}
    </button>
  );
}

export default LoadingButton;
