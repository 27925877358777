import React, { useEffect, useState } from 'react';

import { FilterIcon, SearchIcon } from '@heroicons/react/solid';
import { Link, useParams } from 'react-router-dom';
import { useOrders } from '../../hooks';
import { OrderDetails } from './Order/Order';
import { Order, WithId } from '@giftery/api-interface';
import { format } from 'date-fns';
import { ProductImage } from './ProductImage';
import { isEqual } from 'lodash';
import { Helmet } from 'react-helmet';

export default function Orders() {
  const [order, setOrder] = useState<WithId<Order>>(null);
  const params = useParams<{ id: string }>();
  const orders = useOrders();
  useEffect(() => {
    if (!params.id || !orders) return;
    const selectedOrder = orders.find((o) => o.id === params.id);
    if (!selectedOrder) return;
    if (selectedOrder?.id !== order?.id || !isEqual(order, selectedOrder))
      setOrder(selectedOrder);
  }, [params.id, order, setOrder, orders]);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Helmet>
        <title>The Giftery | Orders</title>
      </Helmet>
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="flex-1 relative z-0 flex overflow-hidden">
          {/* here */}
          {order ? (
            <OrderDetails order={order} />
          ) : (
            <div className="w-screen h-screen flex items-center justify-center">
              <h1 className="text-2xl font-normal">
                Select an order from the list on the left to view it
              </h1>
            </div>
          )}
          <aside className="order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
            <div className="px-6 pt-6 pb-4">
              <h2 className="text-lg font-medium text-gray-900">Orders</h2>
              <p className="mt-1 text-sm text-gray-600">
                Search through {orders?.length || 0} order
                {orders?.length > 0 ? 's' : ''}
              </p>
              <form className="mt-6 flex space-x-4" action="#">
                <div className="flex-1 min-w-0">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <SearchIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="search"
                      name="search"
                      id="search"
                      className="py-2 focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-10 sm:text-sm border-gray-300 "
                      placeholder="Search"
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="inline-flex justify-center px-3.5 py-2 border border-gray-300 text-sm font-medium  text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                >
                  <FilterIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Search</span>
                </button>
              </form>
            </div>
            {/* Directory list */}
            <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="Orders">
              <div className="relative">
                <ul className="relative z-0 divide-y divide-gray-200">
                  {orders?.map((order) => {
                    const product =
                      order.products[Object.keys(order.products)[0]];
                    return (
                      <li key={order.id} className="h-full">
                        <div className="relative border-b border-b-gray-50 px-6 py-2 flex items-center space-x-3 hover:bg-gray-50 focus-within:bg-secondary-100 focus-within:ring-transparent">
                          <div className="flex-shrink-0">
                            <ProductImage product={product} showCount={true} />
                          </div>
                          <div className="flex-1 min-w-0">
                            <Link
                              to={`/orders/${order.id}`}
                              className="focus:outline-none hover:no-underline"
                            >
                              {/* Extend touch target to entire panel */}
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900 my-0">
                                {Object.keys(order.products).length} item
                                {Object.keys(order.products).length > 1
                                  ? 's'
                                  : ''}
                              </p>
                              <p className="text-sm text-gray-500 truncate my-0">
                                {format(
                                  (
                                    order.audit
                                      .createdAt as FirebaseFirestore.Timestamp
                                  ).toDate(),
                                  'dd/MM/yyyy HH:mm'
                                )}
                              </p>
                            </Link>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </nav>
          </aside>
        </div>
      </div>
    </div>
  );
}
