import { Product, WithId } from '@giftery/api-interface';
import React from 'react';
import './ProductList.scss';
import ProductItem from './components/ProductItem';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useStore } from '../../../hooks';
interface ProductListProps {
  onPage: (cursor?: any) => void;
  size: number;
  page: number;
  products: WithId<Product>[];
}

const ProductList: React.FC<ProductListProps> = ({
  products,
  size,
  page,
  ...props
}) => {
  const store = useStore();

  const canGoNext = () => {
    const totalProducts = store.counters.products.total;
    const maxInPage = Math.min(size * page, store.counters.products.total);
    return maxInPage < totalProducts;
  };

  const canGoPrev = () => {
    return page > 1;
  };

  return (
    <div>
      <div className="flex flex-col p-6 pt-0">
        <div className="overflow-y-visible w-full">
          <div className="pb-4 align-middle inline-block w-full">
            <div className="shadow  border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      <div className="-ml-2">Status</div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      &nbsp;
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {products.map((product) => (
                    <ProductItem key={product.id} product={product} />
                  ))}
                </tbody>
              </table>
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700 my-0">
                    Showing{' '}
                    <span className="font-bold">{size * page - size + 1}</span>{' '}
                    to{' '}
                    <span className="font-bold">
                      {Math.min(size * page, store.counters.products.total)}
                    </span>{' '}
                    of{' '}
                    <span className="font-bold">
                      {store.counters.products.total}
                    </span>{' '}
                    products
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  <button
                    type="button"
                    onClick={() => props.onPage()}
                    className="ml-3 relative inline-flex items-center
                      px-4 py-2 text-sm font-medium
                      text-secondary-500 hover:bg-secondary-50
                      disabled:pointer-events-none disabled:bg-gray-100 disabled:opacity-50
                      "
                    disabled={!canGoPrev()}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      props.onPage(products[products.length - 1].id)
                    }
                    disabled={!canGoNext()}
                    className="ml-3 relative inline-flex items-center
                      px-4 py-2 text-sm font-medium
                      text-secondary-500 hover:bg-secondary-50
                      disabled:pointer-events-none disabled:bg-gray-100 disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
