import { DBCollection } from '@giftery/enums';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import type { Stripe } from 'stripe';
import { SupplierRootState } from '../store/reducers';

export function useSubscription(uid: string) {
  const [plan, setPlan] = useState<Stripe.Price>();

  const subscription = useSelector(
    (state: SupplierRootState) =>
      state.firestore.ordered.subscriptions?.[0] || null
  );

  const invoices = useSelector((state: SupplierRootState) => {
    return state.firestore.ordered.invoices || null;
  });

  useEffect(() => {
    if (!subscription) return;
    if (plan) return;
    subscription.price.get().then((price) => {
      const p = price.data() as Stripe.Price;
      setPlan(p);
    });
  }, [subscription, plan]);

  const queries = [];

  if (uid) {
    if (!subscription) {
      // queries.push({
      //   collection: `${DBCollection.users}/${uid}/subscriptions`,
      //   orderBy: ['created', 'desc'],
      //   limit: 1,
      //   storeAs: 'subscriptions',
      // });
    }
    if (subscription && !invoices) {
      const invoicePath = `/${DBCollection.users}/${uid}/subscriptions/${subscription?.id}/invoices`;
      queries.push({
        collection: invoicePath,
        storeAs: 'invoices',
      });
    }
  }

  useFirestoreConnect(queries);

  const hasActiveSubscription = () => {
    const status = subscription?.status;
    const activeStatuses = ['active', 'past_due', 'trialing', 'unpaid'];
    return activeStatuses.includes(status);
  };

  const hasBellsAndWhistlesSubscription = () => {
    const plan = subscription?.items[0]?.plan.nickname;
    return plan?.toLowerCase() === 'the giftery + bells & whistles';
  };

  return {
    subscription,
    invoices,
    isSubscribed: hasActiveSubscription(),
    isBellsAndWhistles: hasBellsAndWhistlesSubscription(),
    plan,
  };
}
