import React, { useRef, useState } from 'react';
import {
  Age,
  Category,
  Gender,
  Hobby,
  NewProduct,
  WithId,
} from '@giftery/api-interface';
import { LoadingButton } from '@giftery/ui/loading-button';
import { ImageUpload, ImageUploadMethods } from '@giftery/ui/image-upload';
import { addProduct } from '../../../actions/Products';
import toast from 'react-hot-toast';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { remove } from 'lodash';
import { TagsInput } from '@giftery/ui/tagsinput';
import { isLoaded } from 'react-redux-firebase';
import { PageLoader } from '@giftery/ui/page-loader';
import { TextEditor } from '@giftery/ui/text-editor';
import { useProductMetadata } from '../../../hooks';
import Switch from 'react-switch';
import { colors } from '@giftery/theme';
import { FiLock } from 'react-icons/fi';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
interface NewProductProps {
  close: () => void;
}

const schema = yup
  .object({
    name: yup.string().required('Product name is a required field'),
    sku: yup.string().required('SKU is a required field'),
    description: yup.string().required('Description is a required field'),
    categories: yup
      .array()
      .length(1, 'You must specify at least one category')
      .required('You must specify at least one category'),
    hobbies: yup
      .array()
      .length(1, 'You must specify at least one interest')
      .required('You must specify at least one interest'),
    ages: yup
      .array()
      .length(1, 'You must specify at least one age group')
      .required('You must specify at least one age group'),
    stock: yup.number().min(0).required('Stock is a required field'),
    price: yup
      .number()
      .min(0.01, 'Price must be greater than 0')
      .required('Price is a required field'),
    images: yup
      .array()
      .length(1, 'You must provide at least one image (jpg/png)')
      .required('Images are a required field'),
  })
  .required();

const NewProductComponent: React.FC<NewProductProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [sku, setSku] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number>(0.0);
  const [stock, setStock] = useState<number>(1);
  const [images, setImages] = useState<string[]>([]);
  const [restricted, setRestricted] = useState<boolean>(false);
  const [categories, setCategories] = useState<WithId<Category>[]>([]);
  const [hobbies, setHobbies] = useState<WithId<Hobby>[]>([]);
  const [ages, setAges] = useState<WithId<Age>[]>([]);
  const [genders, setGenders] = useState<WithId<Gender>[]>([]);
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const imageUploadRef = useRef<ImageUploadMethods>();
  const [categoryData, hobbyData, ageData] = useProductMetadata();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const reset = () => {
    setLoading(false);
    setName('');
    setDescription('');
    setPrice(0.0);
    setStock(1);
    setImages([]);
    setHobbies([]);
    setCategories([]);
    imageUploadRef?.current.reset();
  };

  const cancel = () => {
    reset();
    props.close();
  };

  const createProduct = async () => {
    setLoading(true);
    try {
      const payload: NewProduct = {
        name,
        sku,
        description,
        images,
        categories,
        hobbies,
        ages,
        genders,
        stock,
        price,
        shopify: {
          locationId: null,
          productId: null,
          inventoryId: null,
        },
        variants: null,
        options: null,
        featured: false,
        shipping: {
          type: 0,
          override: null,
        },
        isRestricted: restricted,
      };
      await addProduct(payload);
      props.close();
      reset();
      toast.success(`New product added: ${payload.name}`);
    } catch (error) {
      toast.error(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isLoaded(!categoryData) || !isLoaded(hobbyData)) return <PageLoader />;

  return (
    <div>
      <h1 className="text-4xl mb-3 px-2">Add a Product</h1>
      <form onSubmit={handleSubmit(createProduct)}>
        <Scrollbars
          autoHeight
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}
          autoHeightMin="calc(96vh - 160px)"
          autoHide
        >
          <div className="p-2">
            <label>Name</label>
            <input
              {...register('name')}
              type="text"
              name="name"
              id="name"
              className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pr-12 sm:text-sm border-gray-300"
              value={name}
              onChange={(e) => setName(e.target.value || '')}
              placeholder="Product Title"
            />
            <p className="text-danger">{errors.name?.message}</p>
          </div>
          <div className="p-2">
            <label>SKU</label>
            <input
              {...register('sku')}
              type="text"
              name="sku"
              id="sku"
              className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pr-12 sm:text-sm border-gray-300"
              value={sku}
              onChange={(e) => setSku(e.target.value || '')}
              placeholder="SKU"
            />
            <p className="text-danger">{errors.sku?.message}</p>
          </div>
          <div className="p-2">
            <label>Description</label>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange } }) => (
                <TextEditor
                  className="block w-full p-2 rounded-0 min-h-32 sm:text-sm border-0 outline-none border border-gray-300 ring-1 ring-transparent focus:ring-secondary-500 focus:border-secondary-500"
                  placeholder="Product Description"
                  value={description}
                  onChange={(description) => {
                    setDescription(description);
                    onChange(description);
                  }}
                />
              )}
            />

            <p className="text-danger">{errors.description?.message}</p>
          </div>
          <div className="p-2">
            <label className="flex">
              <div className="flex flex-grow-0 items-center jusify-center text-center">
                <Switch
                  onColor={colors.danger}
                  checkedIcon={
                    <FiLock className="stroke-current text-white absolute top-1 left-2" />
                  }
                  uncheckedIcon={null}
                  onChange={(checked) => setRestricted(checked)}
                  checked={restricted}
                />
              </div>
              <div className="flex-grow pl-3">
                <p className="font-bold my-0">Restricted Item?</p>
                <p className="text-sm my-0 text-secondary-300 text-sm">
                  Is this product age-restricted? E.g. alcohol or tobacco
                  products.
                </p>
              </div>
            </label>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="p-2">
              <label>Categories</label>
              <Controller
                name="categories"
                control={control}
                render={({ field: { onChange } }) => (
                  <TagsInput
                    containerClassName="border border-gray-300 rounded-0"
                    inputClassName="border-0 focus:ring-0 py-1"
                    data={categoryData}
                    onChange={(tags) => {
                      setCategories(tags);
                      onChange(tags);
                    }}
                  />
                )}
              />
              <p className="text-danger">{errors.categories?.message}</p>
            </div>
            <div className="p-2">
              <label>Interests</label>
              <Controller
                name="hobbies"
                control={control}
                render={({ field: { onChange } }) => (
                  <TagsInput
                    containerClassName="border border-gray-300 rounded-0"
                    inputClassName="border-0 focus:ring-0 py-1"
                    data={hobbyData}
                    onChange={(tags) => {
                      setHobbies(tags);
                      onChange(tags);
                    }}
                  />
                )}
              />
              <p className="text-danger">{errors.hobbies?.message}</p>
            </div>
            <div className="p-2">
              <label>Ages</label>
              <Controller
                name="ages"
                control={control}
                render={({ field: { onChange } }) => (
                  <TagsInput
                    containerClassName="border border-gray-300 rounded-0"
                    inputClassName="border-0 focus:ring-0 py-1"
                    data={ageData}
                    onChange={(tags) => {
                      setAges(tags);
                      onChange(tags);
                    }}
                  />
                )}
              />
              <p className="text-danger">{errors.ages?.message}</p>
            </div>
            <div className="p-2"></div>
            <div className="p-2">
              <label>Stock</label>

              <div>
                <input
                  {...register('stock')}
                  name="stock"
                  id="stock"
                  type="number"
                  className="focus:ring-secondary-500 focus:border-secondary-500 block w-full sm:text-sm border-gray-300"
                  min={0}
                  value={stock}
                  onChange={(e) => setStock(parseFloat(e.target.value) || 0)}
                />
                <p className="text-danger">{errors.stock?.message}</p>
              </div>
            </div>
            <div className="p-2">
              <label>Price</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  {...register('price')}
                  type="text"
                  className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                  min={0.0}
                  value={price}
                  onChange={(e) => setPrice(parseFloat(e.target.value) || 0.01)}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    NZD
                  </span>
                </div>
              </div>
              <p className="text-danger">{errors.price?.message}</p>
            </div>
          </div>
          <div className="p-2">
            <Controller
              name="images"
              control={control}
              render={({ field: { onChange } }) => (
                <ImageUpload
                  ref={imageUploadRef}
                  onRequestClear={() => setImages([])}
                  onRequestRevert={(id: string) => {
                    setImages((images) => remove(images, id));
                    onChange(remove(images, id));
                  }}
                  onRequestSave={(id: string) => {
                    setImages((images) => [...images, id]);
                    onChange([...images, id]);
                  }}
                  defaultFiles={[]}
                />
              )}
            />
            <p className="text-danger">{errors.images?.message}</p>
          </div>
        </Scrollbars>
        <div className="grid grid-cols-2 gap-2">
          <button
            type="button"
            className="bg-transparent flex-1 text-secondary-500 text-xl font-normal py-2 px-3 border-2 border-secondary-500 hover:bg-secondary-400 hover:border-secondary-400"
            onClick={cancel}
          >
            Close
          </button>
          <LoadingButton
            type="submit"
            loading={loading}
            feedback={'Creating...'}
            color="primary"
            className="bg-secondary-500 flex-1 text-white text-xl font-normal py-2 px-3 border-2 border-secondary-500 hover:bg-secondary-400 hover:border-secondary-400"
          >
            Create Product
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

export default NewProductComponent;
