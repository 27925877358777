/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, LegacyRef, useState } from 'react';
import { flatten, chain, uniq, compact, map, find } from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { CSVReader } from 'react-papaparse';
import { colors } from '@giftery/theme';
import toast from 'react-hot-toast';
import { InlineDataGrid } from './DataGrid';
import {
  Category,
  ImportProduct,
  ProductVariantOption,
  WithId,
} from '@giftery/api-interface';
import { importProducts } from '../../../actions/Products';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaFile, FaShopify } from 'react-icons/fa';
import { ExclamationIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { ShopifyProductExport, WCProductExport } from './models';
import { CSVImportType } from './models/enums';
import { FiFile } from 'react-icons/fi';

const supportedFiles = ['application/vnd.ms-excel', 'text/csv'];

interface ProductImportModalProps {
  open: boolean;
  onClose: () => void;
}

const generateShopifyVariantOptions = (
  variants: ShopifyProductExport[]
): ProductVariantOption[] => {
  const options: ProductVariantOption[] = [];
  if (variants.length > 1) {
    for (const variant of variants) {
      if (variant.option1_value.trim() !== '') {
        if (!options[0]) {
          options.push({
            id: null,
            product_id: null,
            name: variant.option1_name,
            position: 1,
            values: [variant.option1_value],
          });
        } else {
          options[0].values.push(variant.option1_value);
        }
      }
      if (variant.option2_value.trim() !== '') {
        if (!options[1]) {
          options.push({
            id: null,
            product_id: null,
            name: variant.option2_name,
            position: 2,
            values: [variant.option2_value],
          });
        } else {
          options[1].values.push(variant.option2_value);
        }
      }
      if (variant.option3_value.trim() !== '') {
        if (!options[2]) {
          options.push({
            id: null,
            product_id: null,
            name: variant.option3_name,
            position: 3,
            values: [variant.option3_value],
          });
        } else {
          options[2].values.push(variant.option3_value);
        }
      }
    }
  }
  for (const option of options) {
    option.values = uniq(option.values);
  }
  return options;
};

const generateWooCommerceVariantOptions = (
  variants: WCProductExport[]
): ProductVariantOption[] => {
  const options: ProductVariantOption[] = [];
  if (variants.length > 1) {
    for (const variant of variants) {
      if (variant.attribute_1_values.trim() !== '') {
        if (!options[0]) {
          options.push({
            id: null,
            product_id: null,
            name: variant.attribute_1_name,
            position: 1,
            values: [variant.attribute_1_values],
          });
        } else {
          options[0].values.push(variant.attribute_1_values);
        }
      }
      if (variant.attribute_1_values.trim() !== '') {
        if (!options[1]) {
          options.push({
            id: null,
            product_id: null,
            name: variant.attribute_2_name,
            position: 2,
            values: [variant.attribute_2_values],
          });
        } else {
          options[1].values.push(variant.attribute_2_values);
        }
      }
      if (variant.attribute_3_values.trim() !== '') {
        if (!options[2]) {
          options.push({
            id: null,
            product_id: null,
            name: variant.attribute_3_name,
            position: 3,
            values: [variant.attribute_3_values],
          });
        } else {
          options[2].values.push(variant.attribute_3_values);
        }
      }
    }
  }
  for (const option of options) {
    option.values = uniq(option.values);
  }
  return options;
};

const getEntitiesFromString = (entityString: string) => {
  if (!entityString || entityString.trim() === '') return [];
  const entities = entityString.split(/[\s;,]+/);
  return entities;
};

const transformProductFromWooCommerce = (
  variants: WCProductExport[]
): ImportProduct => {
  const firstVariant = variants[0];

  let stock = 0;
  let price = 0;

  const productVariants = compact(
    variants.map((variant) => {
      if (!variant.name || !variant.regular_price) return;
      const v = {
        id: null,
        type: variant.attribute_1_name,
        price: parseFloat(variant.regular_price) ?? 0,
        quantity: parseInt(variant.stock, 10) ?? 0,
        option1: variant.attribute_1_values || null,
        option2: variant.attribute_2_values || null,
        option3: variant.attribute_3_values || null,
        sku: variant.sku,
        shopify: {
          inventoryId: null,
        },
      };
      stock += v.quantity ?? 0;
      price = v.price;
      return v;
    })
  );

  const product: ImportProduct = {
    selected: true,
    name: firstVariant.name,
    sku: firstVariant.sku,
    description: firstVariant.description,
    images: flatten(
      variants
        .filter((variant) => variant.images.trim() !== '')
        .map((v) => getEntitiesFromString(v.images))
    ),
    categories: flatten(
      variants
        .filter((variant) => variant.categories.trim() !== '')
        .map((v) => {
          const categories = getEntitiesFromString(v.categories);
          return categories.map((c) => ({ id: null, name: c }));
        })
    ),
    hobbies: [],
    genders: [],
    ages: [],
    stock: stock,
    price,
    featured: false,
    status: {
      inStock: false,
      active: firstVariant.published === '1',
      deleted: false,
      super: false,
      featured: false,
    },
    shipping: {
      type: 0,
      override: null,
    },
    variants: productVariants,
    options: generateWooCommerceVariantOptions(variants),
    shopify: {
      locationId: null,
      productId: null,
      inventoryId: null,
    },
    isRestricted: false,
  };
  return product;
};

const transformProductFromShopify = (
  variants: ShopifyProductExport[]
): ImportProduct => {
  const firstVariant = variants[0];
  const product: ImportProduct = {
    selected: true,
    name: firstVariant.title,
    sku: firstVariant.handle,
    description: firstVariant.body_html,
    images: flatten(
      variants
        .filter((variant) => variant.image_src.trim() !== '')
        .map((v) => getEntitiesFromString(v.image_src))
    ),
    categories:
      firstVariant.type?.trim() !== ''
        ? [{ id: null, name: firstVariant.type }]
        : [],
    hobbies: [],
    genders: [],
    ages: [],
    stock: parseInt(firstVariant.variant_inventory_qty, 10),
    price: parseFloat(firstVariant.variant_price),
    featured: false,
    status: {
      inStock: false,
      active: firstVariant.status === 'active',
      deleted: false,
      super: false,
      featured: false,
    },
    shipping: {
      type: 0,
      override: null,
    },
    variants: compact(
      variants.map((variant) => {
        if (!variant.title || !variant.variant_price) return;
        return {
          id: null,
          type: variant.option1_name,
          price: parseFloat(variant.variant_price),
          quantity: parseInt(variant.variant_inventory_qty, 10),
          option1: variant.option1_value || null,
          option2: variant.option2_value || null,
          option3: variant.option3_value || null,
          sku: variant.variant_sku,
          shopify: {
            inventoryId: null,
          },
        };
      })
    ),
    options: generateShopifyVariantOptions(variants),
    shopify: {
      locationId: null,
      productId: null,
      inventoryId: null,
    },
    isRestricted: false,
  };
  return product;
};

const determineImportType = (row: Record<string, unknown>): CSVImportType => {
  if (row.visibility_in_catalogue) return CSVImportType.woocommerce;
  if (row.handle) return CSVImportType.shopify;
};

export const ProductImportModal: React.FC<ProductImportModalProps> = ({
  open,
  ...props
}) => {
  const ref = React.createRef<CSVReader<ShopifyProductExport>>();
  const csvRef = ref as LegacyRef<CSVReader<ShopifyProductExport>>;
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ImportProduct[]>([]);

  const handleShopify = (data: any): void => {
    const shopifyProducts: Record<string, ShopifyProductExport[]> = chain(data)
      .map((datum) => datum.data)
      .groupBy('handle')
      .value();
    const importProducts = Object.values(shopifyProducts).map(
      transformProductFromShopify
    );
    setProducts(importProducts);
    setLoading(false);
  };

  const handleWooCommerce = (data: any): void => {
    const wooCommerceProducts: Record<string, WCProductExport[]> = {};
    map(data, (datum) => {
      let id = datum.data.id;
      if (datum.data.parent && datum.data.parent.indexOf('id:') > -1) {
        id = datum.data.parent.split(':').pop();
      }
      if (!wooCommerceProducts[id]) wooCommerceProducts[id] = [];
      wooCommerceProducts[id].push(datum.data);
    });

    const importProducts = Object.values(wooCommerceProducts).map(
      transformProductFromWooCommerce
    );
    setProducts(importProducts);
    setLoading(false);
  };

  const handleSquareSpace = (data: any): void => {
    return;
  };

  const handleOnDrop = async (data, file) => {
    setLoading(true);
    if (!supportedFiles.includes(file.type)) {
      ref.current.removeFile();
      const message = 'Could not process file: Only CSV files are supported';
      setError(message);
      toast.error(message);
      throw new Error(message);
    }
    const importType = determineImportType(data[0]?.data);
    switch (importType) {
      case CSVImportType.shopify:
        return handleShopify(data);
      case CSVImportType.woocommerce:
        return handleWooCommerce(data);
      case CSVImportType.squarespace:
        return handleSquareSpace(data);
      default:
        throw new Error('Unsupported import type');
    }
  };

  const isDisabled = () => {
    return loading || products.every((p) => p.selected === false);
  };

  const startImport = async () => {
    setLoading(true);
    const count = products.filter((p) => p.selected === true).length;
    await toast.promise(importProducts(products), {
      error: `Unable to import your products at this time. Please check your file and try again.`,
      success: `Great! We've successfully imported  ${count}`,
      loading: `Hang tight! We're importing ${count} products...`,
    });
    setLoading(false);
    props.onClose();
  };

  const handleOnError = (err, file, inputElem, reason) => {
    toast.error(`Error processing import file: ${reason}`);
    setError(reason);
    console.error(err);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto p-6"
        aria-disabled={isDisabled()}
        onClose={props.onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="
              inline-block align-middle bg-white px-6 pt-12 pb-4 text-left w-3/4 overflow-hidden shadow-xl transform transition-all my-8"
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={props.onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="w-full">
                <div className="grid grid-cols-1">
                  {/* Importer */}
                  <div className="my-3 mb-2 bg-tertiary-100 border-l-4 border-tertiary-400 p-4 rounded-md">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-tertiary-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-tertiary-700 my-0">
                          You have access to all premium features until{' '}
                          <span className="font-medium">1 December, 2021.</span>{' '}
                          <Link
                            to="/settings/billing"
                            className="font-medium underline text-tertiary-700 hover:text-tertiary-600"
                          >
                            Subscribe to continue accessing this feature once
                            your trial runs out.
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="my-3 mt-0">
                    <CSVReader
                      onDrop={handleOnDrop}
                      onError={handleOnError}
                      addRemoveButton
                      removeButtonColor={colors.secondary[500]}
                      onRemoveFile={() => setProducts([])}
                      ref={csvRef}
                      config={{
                        header: true,
                        trimHeaders: true,
                        skipEmptyLines: true,
                        dynamicTyping: false,
                        transformHeader: (header) =>
                          header
                            .toLowerCase()
                            .replace(/\s/g, '_')
                            .replace(/\/_/g, '')
                            .replace(/\(/g, '')
                            .replace(/\)/g, ''),
                      }}
                      style={{
                        dropArea: {
                          borderColor: colors.secondary[500],
                          color: colors.secondary[500],
                          borderRadius: 8,
                          borderWidth: 4,
                          marginBottom: 15,
                          opacity: 0.5,
                        },
                        dropAreaActive: {
                          borderColor: colors.secondary[500],
                          color: colors.secondary[500],
                          opacity: 1,
                        },
                        dropFile: {
                          width: '100%',
                          height: 30,
                          paddingTop: 10,
                          paddingBottom: 10,
                          background: 'transparent',
                        },
                        fileSizeInfo: {
                          color: colors.secondary[500],
                          backgroundColor: 'transparent',
                          borderRadius: 0,
                          lineHeight: 1,
                          marginBottom: '.5em',
                          padding: '0 0.4em',
                        },
                        fileNameInfo: {
                          color: colors.secondary[500],
                          backgroundColor: 'transparent',
                          borderRadius: 0,
                          fontSize: 12,
                          lineHeight: 1,
                          padding: '0 0.4em',
                        },
                        removeButton: {
                          color: colors.danger,
                          position: 'absolute',
                          right: 0,
                          top: 0,
                        },
                        progressBar: {
                          height: 3,
                          position: 'absolute',
                          bottom: -26,
                          backgroundColor: colors.tertiary[300],
                        },
                      }}
                    >
                      <div className="text-xl h-48 flex items-center justify-center">
                        <div className="flex flex-col items-center justify-center">
                          <FiFile
                            size={72}
                            className="stroke-current opacity-50 text-center"
                          />
                          <div>
                            Drop your Shopify or WooCommerce exported file here
                            or click to upload from your computer
                          </div>
                        </div>
                      </div>
                    </CSVReader>
                  </div>
                  {products.length > 0 && (
                    <Scrollbars
                      autoHeight
                      renderTrackHorizontal={(props) => (
                        <div
                          {...props}
                          style={{ display: 'none' }}
                          className="track-horizontal"
                        />
                      )}
                      className="flex flex-col"
                      renderTrackVertical={(props) => (
                        <div {...props} className="track-vertical" />
                      )}
                      autoHeightMin="60vh"
                      autoHide={false}
                    >
                      <InlineDataGrid
                        data={products}
                        onChange={(products: ImportProduct[]) =>
                          setProducts(products)
                        }
                      />
                    </Scrollbars>
                  )}
                </div>
              </div>
              <div className="mt-5 pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="
                  mt-3 w-full inline-flex justify-center
                  border border-transparent
                  px-4 py-2 bg-secondary-500 text-base font-medium text-white
                  hover:bg-secondary-700
                  focus:outline-none
                  disabled:opacity-50 disabled:pointer-events-none
                  sm:ml-3 w-auto"
                  disabled={isDisabled()}
                  onClick={startImport}
                >
                  Start Import
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center items-center
                   px-4 py-2 bg-white text-base font-medium text-gray-700
                   hover:text-gray-500
                   focus:outline-none
                   disabled:opacity-50 disabled:pointer-events-none
                   sm:mt-0 w-auto sm:text-sm"
                  disabled={loading}
                  onClick={props.onClose}
                >
                  Go Back
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
