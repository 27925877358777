import React from 'react';
import { Wizard } from 'react-wizard-primitive';
import { Helmet } from 'react-helmet';
import { ContactStep } from './steps/contact/ContactStep';
import './OnBoardingWizard.scss';
import { PageLoader } from '@giftery/ui/page-loader';
import { DBCollection } from '@giftery/enums';
import { NameStep } from './steps/name/NameStep';
import { PaymentStep } from './steps/payment/PaymentStep';
import { useSelector } from 'react-redux';
import { RootState } from '@giftery/ui/interfaces';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { ShippingStep } from './steps/shipping/ShippingStep';
import { PasswordStep } from './steps/pass/PassStep';

const OnBoardingWizard = () => {
  // get the current user.
  // this is safe because we've wrapped this component in an `AuthCheck` component.
  const user = useSelector((state: RootState) => state.firebase?.profile);
  // read the user details from Firestore based on the current user's ID
  useFirestoreConnect([
    {
      collection: DBCollection.suppliers,
      doc: user.supplier,
    },
  ]);
  const supplier = useSelector(
    (state: RootState) => state.firestore.ordered.suppliers?.[0] ?? null
  );

  if (!isLoaded(supplier) || !supplier) return <PageLoader />;
  return (
    <div className="OnBoardingWizardPage bg-secondary-50">
      <Helmet>
        <title>The Giftery | Get Started</title>
      </Helmet>
      <Wizard>
        <PasswordStep supplier={supplier} />
        <NameStep supplier={supplier} />
        <ContactStep supplier={supplier} />
        <ShippingStep supplier={supplier} />
        <PaymentStep supplier={supplier} />
      </Wizard>
    </div>
  );
};

export default OnBoardingWizard;
