import React, { useEffect, useState } from 'react';
import { noop, uniqBy } from 'lodash';
import ReactTagsInput, { RenderInputProps } from 'react-tagsinput';
import Autosuggest, { InputProps } from 'react-autosuggest';
import FuzzySearch from 'fuzzy-search';
import { classNames } from '@giftery/utils';
import { FiX } from 'react-icons/fi';

import './ui-tagsinput.scss';

/* eslint-disable-next-line */
export interface TagsInputProps {
  data?: Tag[];
  initial?: Tag[];
  autocompleteData?: Tag[];
  max?: number;
  onChange: (tags: Tag[]) => void;
  validationRegex?: RegExp;
  containerClassName?: string;
  tagClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  readonly?: boolean;
}

export type Tag = {
  id: string;
  name: string;
};

const AutoCompleteRenderInput = ({
  addTag,
  min,
  max,
  autocompleteData,
  tagClassName,
  inputClassName,
  value,
  ...props
}: RenderInputProps<Tag> & {
  data: Tag[];
  autocompleteData: Tag[];
}) => {
  const handleOnChange = (e, { newValue, method }) => {
    if (method === 'enter') {
      e.preventDefault();
    } else {
      props.onChange(e);
    }
  };

  const inputValue =
    (value && (value as unknown as string)?.trim().toLowerCase()) || '';
  const fuzzy = new FuzzySearch(autocompleteData || props.data, ['name'], {
    caseSensitive: false,
  });
  const suggestions = fuzzy.search(inputValue).slice(0, 10);

  return (
    <Autosuggest
      ref={props.ref}
      suggestions={suggestions}
      shouldRenderSuggestions={(value) => value && value?.trim().length > 0}
      getSuggestionValue={(suggestion) => suggestion.name}
      renderSuggestion={(suggestion) => (
        <span className={tagClassName || 'tag-capitalized'}>
          {suggestion.name}
        </span>
      )}
      inputProps={{
        ...props,
        // Type assertion as autosuggest is expecting a string but handles objects just fine
        value: value as unknown as string,
        className: inputClassName || 'react-tags-input',
        onChange: handleOnChange,
      }}
      onSuggestionSelected={(e, { suggestion }) => {
        addTag({ id: suggestion.id, name: suggestion.name });
      }}
      onSuggestionsClearRequested={noop}
      onSuggestionsFetchRequested={noop}
    />
  );
};

export const TagsInput: React.FC<TagsInputProps> = ({
  tagClassName,
  inputClassName,
  containerClassName,
  initial,
  data,
  max,
  autocompleteData,
  placeholder,
  readonly = false,
  ...props
}) => {
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    setTags(initial || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  const handleChange = (newTags: Tag[]) => {
    const tags = newTags.map((tag) => {
      if (typeof tag === 'string') {
        return { id: null, name: tag };
      }
      return tag;
    });
    const finalTags = uniqBy(tags, 'name').slice(0, max);
    setTags(finalTags);
    props.onChange(finalTags);
  };

  const renderTag = (tag: Tag | string) => {
    return typeof tag === 'string' ? tag : tag?.name || null;
  };

  return (
    <ReactTagsInput
      maxTags={10}
      className={classNames(containerClassName, 'react-tagsinput py-1')}
      renderTag={({
        tag,
        key,
        disabled,
        onRemove,
        getTagDisplayValue,
        classNameRemove,
        ...other
      }) => {
        return (
          <span key={key} {...other}>
            {renderTag(tag)}
            {!disabled && (
              // eslint-disable-next-line
              <a
                className={classNames(
                  'inline-block float-right mt-1 react-tagsinput-remove',
                  classNameRemove
                )}
                onClick={() => onRemove(key)}
              ></a>
            )}
          </span>
        );
      }}
      renderInput={(renderProps: RenderInputProps) => {
        if (readonly === true) return null;
        return AutoCompleteRenderInput({
          ...renderProps,
          data,
          autocompleteData,
          tagClassName,
          inputClassName,
        });
      }}
      inputProps={{
        placeholder,
      }}
      validationRegex={props.validationRegex}
      value={tags}
      addOnBlur={false}
      onChange={handleChange}
    />
  );
};
