import { request } from '@giftery/axios';

export const connectShopify = async (hmac: string, code: string) => {
  return await request('post', '/shopify/connect', { hmac, code });
};

export const disconnectShopify = async () => {
  return await request('post', '/shopify/disconnect');
};

export const syncProductsFromShopify = async () => {
  return await request('get', '/shopify/import');
};

export const publishProductToShopify = async (id: string) => {
  return await request('post', `/shopify/publish/${id}`);
};
