import { useEffect, useState } from 'react';

interface UseGeolocationOptions {
  enableHighAccuracy?: boolean;
  maximumAge?: number;
  timeout?: number;
}

export interface GeoLocationResults {
  distance: number;
  geolocation: GeolocationPosition;
}

export const useGeolocation = (
  options: UseGeolocationOptions = {
    enableHighAccuracy: false,
    timeout: 60000,
    maximumAge: 60000,
  },
  callback?: (props: GeolocationPosition) => void
): GeolocationPosition | Error => {
  const { enableHighAccuracy, maximumAge, timeout } = options;
  const [coordinates, setCoordinates] = useState<GeolocationPosition>({
    coords: {
      accuracy: null,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      latitude: null,
      longitude: null,
      speed: null,
    },
    timestamp: null,
  });
  const [errors, setErrors] = useState<Error>(null);

  useEffect(() => {
    let didCancel: boolean;
    const updateCoordinates = (position: GeolocationPosition) => {
      if (!didCancel) {
        setCoordinates(position);
        if (callback instanceof Function) {
          callback(position);
        }
      }
    };

    const setError = (error) => {
      if (!didCancel) {
        setCoordinates({
          coords: {
            accuracy: null,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            latitude: null,
            longitude: null,
            speed: null,
          },
          timestamp: null,
        });
        setErrors(error);
      }
    };

    let watchId;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateCoordinates, setError);
      watchId = navigator.geolocation.watchPosition(
        updateCoordinates,
        setError,
        { enableHighAccuracy, maximumAge, timeout }
      );
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
      didCancel = true;
    };
  }, [callback, enableHighAccuracy, maximumAge, timeout]);

  return errors || coordinates;
};

export default useGeolocation;
