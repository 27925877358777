import React from 'react';
import { Bar } from '@reactchartjs/react-chart.js';
import { format } from 'date-fns';
import { map } from 'lodash';
import numeral from 'numeral';
import { usePayout } from '../../../../hooks';
import { classNames } from '@giftery/utils';

const PayoutDetails = () => {
  const [nextAmount, nextDate] = usePayout();
  return (
    <div className="w-full grid grid-cols-2 gap-2">
      <div className="bg-white p-6">
        <h1 className="text-2xl my-0 font-bold">
          {nextDate ? format(nextDate, 'dd LLLL yyyy') : '-'}
        </h1>
        <p className="my-0">Next Payout Date</p>
      </div>
      <div className="bg-white p-6">
        <h1 className="text-2xl my-0 font-bold">
          <span
            className={classNames(
              nextAmount > 0 ? 'text-tertiary-500' : 'text-gray-300'
            )}
          >
            +{numeral(nextAmount).format('$0,0.00')}
          </span>
        </h1>
        <p className="my-0">Next Payout Amount</p>
      </div>
    </div>
  );
};

export default PayoutDetails;
