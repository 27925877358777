import { CheckCircleIcon, CheckIcon } from '@heroicons/react/solid';
import { useStripe } from '@stripe/react-stripe-js';
import {
  useMe,
  useSubscription,
  useSubscriptionPricing,
} from '../../../../../hooks';
import React, { useState } from 'react';
import { chain } from 'lodash';
import {
  createCheckoutSession,
  createPortalLink,
} from '../../../../../actions/Accounts';
import toast from 'react-hot-toast';
import { addDays, formatDistance } from 'date-fns';
import { classNames } from '@giftery/utils';

const AccountPlanDetails: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const me = useMe();
  const subscriptions = useSubscriptionPricing();
  const { subscription, plan, isSubscribed } = useSubscription(me.id);
  const stripe = useStripe();

  const upgrade = async (tier: string) => {
    if (!me.id) return;
    setLoading(true);
    const sessionId = await createCheckoutSession(me.id, tier);
    setLoading(false);
    await stripe.redirectToCheckout({ sessionId });
  };

  const visitStripePortal = async () => {
    try {
      setLoading(true);
      const portalLink = await createPortalLink();
      if (!portalLink)
        return toast.error(
          'Unable to access subscription portal at this time. Please try again later'
        );
      window.location.assign(portalLink);
    } catch (err) {
      return toast.error(
        `Unable to access subscription portal at this time. Please try again later. ${err.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  const getFeatures = (metadata: { [key: string]: any }): string[] => {
    return chain(metadata)
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .filter((item, key) => {
        return key.indexOf('feature_') >= -1;
      })
      .map()
      .value();
  };

  return (
    <section>
      {isSubscribed ? (
        <div className="relative">
          <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
            <div className="flex-1 bg-white px-6 py-8 lg:p-12">
              <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                You're Subscribed
              </h3>
              <p className="mt-6 text-base text-gray-500">
                You have an active premium subscription to The Giftery, giving
                you these great benefits
              </p>
              <div className="mt-8">
                <div className="flex items-center">
                  <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-secondary-500">
                    What's included
                  </h4>
                  <div className="flex-1 border-t-2 border-gray-200" />
                </div>
                <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-1 lg:gap-x-8 lg:gap-y-5">
                  {getFeatures(plan?.metadata).map((feature) => (
                    <li
                      key={feature}
                      className="flex items-start lg:col-span-1"
                    >
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-3 text-sm text-gray-700 my-0">
                        {feature}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
              <p className="text-lg leading-6 font-medium text-gray-900">
                Thank you!
              </p>
              <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                <span>${plan?.unit_amount / 100 ?? '-'}</span>
                <span className="ml-3 text-xl font-medium text-gray-500">
                  /month
                </span>
              </div>
              <p className="mt-4 text-sm">
                <a href="#" className="font-medium text-gray-500 underline">
                  Learn about our membership policy
                </a>
              </p>
              <div className="mt-6">
                <div className="rounded-md shadow">
                  <button
                    onClick={visitStripePortal}
                    type="button"
                    className="flex items-center justify-center w-full px-2 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondary-500 hover:bg-secondary-800 disabled:bg-grey-500 disabled:opacity-50"
                  >
                    {loading ? 'Please wait...' : 'Manage My Subscription'}
                  </button>
                </div>
              </div>
              <div className="mt-4 text-sm">
                <p className="font-medium text-gray-900">
                  Renews{' '}
                  {subscription?.days_until_due
                    ? formatDistance(
                        addDays(new Date(), subscription?.days_until_due),
                        new Date(),
                        {
                          addSuffix: true,
                        }
                      )
                    : formatDistance(
                        (
                          subscription?.trial_end as unknown as FirebaseFirestore.Timestamp
                        ).toDate(),
                        new Date(),

                        {
                          addSuffix: true,
                        }
                      )}
                </p>
                <p className="font-normal text-gray-500">
                  Then NZ$
                  {plan?.unit_amount / 100 ?? '-'}
                  /m thereafter
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8">
          {subscriptions?.map((tier, index) => (
            <div
              key={tier.id}
              className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
            >
              <div className="flex-1">
                <h3 className="text-xl font-semibold text-gray-900">
                  {tier.description}
                </h3>
                {tier.description === 'The Giftery + Bells & Whistles' ? (
                  <p className="absolute top-0 py-1.5 px-4 bg-secondary-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                    Most popular
                  </p>
                ) : null}
                <p className="mt-4 flex items-baseline text-gray-900">
                  <span className="text-5xl font-extrabold tracking-tight">
                    ${tier.unit_amount / 100}
                  </span>
                  <span className="ml-1 text-xl font-semibold">
                    {tier.recurring.interval}
                  </span>
                </p>
                <p className="mt-6 text-gray-500">{tier.description}</p>

                {/* Feature list */}
                <ul className="mt-6 space-y-6">
                  {getFeatures(tier.metadata).map((feature) => (
                    <li key={feature} className="flex">
                      <CheckIcon
                        className="flex-shrink-0 w-6 h-6 text-secondary-500"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <button
                type="button"
                onClick={() => upgrade(tier.id)}
                className={classNames(
                  tier.description === 'The Giftery + Bells & Whistles'
                    ? 'bg-secondary-500 text-white hover:bg-secondary-600'
                    : 'bg-secondary-50 text-secondary-700 hover:bg-secondary-100',
                  'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
                )}
              >
                {loading ? 'Please Wait' : 'Get Started'}
              </button>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default AccountPlanDetails;
