import { ImportProduct } from '@giftery/api-interface';
import { SanitizeHTML } from '@giftery/ui/sanitize-html';
import { classNames } from '@giftery/utils';
import React, { useEffect, useState } from 'react';

interface InlineDatagridProps {
  data: ImportProduct[];
  onChange: (data: ImportProduct[]) => void;
}

export const InlineDataGrid: React.FC<InlineDatagridProps> = ({
  data,
  ...props
}) => {
  const [products, setProducts] = useState<ImportProduct[]>(data);
  const [selectAll, setSelectAll] = useState<boolean>(
    data.every((p) => p.selected === true)
  );
  useEffect(() => {
    setProducts(data);
  }, [data]);

  useEffect(() => {
    let productsCopy = [...products];
    productsCopy = productsCopy.map((p) => ({
      ...p,
      selected: selectAll,
    }));
    setProducts(productsCopy);
    props.onChange(productsCopy);
  }, [selectAll]);

  const onChangeProduct = (
    key: keyof ImportProduct,
    value: any,
    index: number
  ) => {
    const p = [...products];
    p[index] = {
      ...p[index],
      [key]: value,
    };
    props.onChange(p);
  };

  return (
    <table className="relative min-w-full table-auto">
      <thead className="relative bg-gray-50">
        <tr>
          <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <div className="flex items-center h-5">
              <input
                id="select-all"
                name="select-all"
                type="checkbox"
                checked={products.every((p) => p.selected === true)}
                onChange={() => setSelectAll(!selectAll)}
                className="focus:border-secondary-500 h-4 w-4 text-secondary-600 border-gray-300"
              />
              <span className="ml-2">Select All</span>
            </div>
          </th>
          <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            SKU
          </th>
          <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Details
          </th>
          <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Category
          </th>
          <th className="sticky top-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
          <th className="sticky top-0 bg-gray-50 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            Stock
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {data.map((product, index) => (
          <tr
            key={`${product.sku}-${index}`}
            className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
          >
            <td className="px-6 py-4 truncate" style={{ maxWidth: '1px' }}>
              <div className="flex items-center h-5">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  checked={product.selected}
                  onChange={() =>
                    onChangeProduct('selected', !product.selected, index)
                  }
                  className="focus:ring-secondary-500 h-4 w-4 text-secondary-600 border-gray-300"
                />
              </div>
            </td>
            <td className="px-6 py-4 truncate" style={{ maxWidth: '1px' }}>
              {product.sku ?? '-'}
            </td>
            <td className="px-6 py-4 truncate" style={{ maxWidth: '1px' }}>
              <div className="flex items-center">
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    {product.name || '-'}
                  </div>
                  <div className="text-sm text-gray-500">
                    <SanitizeHTML
                      html={product.description || '-'}
                      summary={true}
                      className="w-96 overflow-ellipsis overflow-hidden"
                    />
                  </div>
                </div>
              </div>
            </td>
            <td className="px-6 py-4 truncate" style={{ maxWidth: '1px' }}>
              <div className="text-sm text-gray-900">
                {product.categories?.[0]?.name || '-'}
              </div>
              <div className="text-sm text-gray-500">
                {product.hobbies?.[0]?.name || '-'}
              </div>
            </td>
            <td className="px-6 py-4 truncate" style={{ maxWidth: '1px' }}>
              <span
                className={classNames(
                  product.status.active
                    ? 'bg-green-100 text-green-800'
                    : 'bg-gray-50 text-gray-700',
                  'px-2 inline-flex text-xs leading-5 font-semibold rounded-full'
                )}
              >
                {product.status.active ? 'Active' : 'Draft'}
              </span>
            </td>
            <td
              className="px-6 py-4 truncate text-sm text-center text-gray-500"
              style={{ maxWidth: '1px' }}
            >
              {product.stock || '0'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
