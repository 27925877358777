import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@giftery/ui/interfaces';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { PageLoader } from '@giftery/ui/page-loader';
import { axios } from '@giftery/axios';

export const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state: RootState) => state.firebase?.auth ?? null);
  const user = useSelector(
    (state: RootState) => state.firebase?.profile ?? null
  );
  // Always update the token
  useEffect(() => {
    if (auth?.stsTokenManager?.accessToken) {
      axios.defaults.headers[
        'Authorization'
      ] = `Bearer ${auth?.stsTokenManager.accessToken}`;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  if (!isLoaded(auth) || !isLoaded(user)) return <PageLoader />;

  return children;
};

export const useClaims = () => {
  const claims = useSelector(
    (state: RootState) => state.firebase?.profile?.token?.claims ?? null
  );
  return claims;
};

export interface AuthenticatedRouteProps {
  roles?: string[];
  path: string;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  roles,
  children,
  ...rest
}) => {
  const auth = useSelector((state: RootState) => state.firebase?.auth ?? null);
  const claims = useClaims();
  if (roles) {
    if (!claims || !roles.some((role) => claims.roles?.includes(role)))
      return <Redirect to="/login" />;
  }
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const isAuthenticated = isLoaded(auth) && !isEmpty(auth);
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
