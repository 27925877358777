import React, { MouseEventHandler } from 'react';
import { IconType } from 'react-icons/lib';
import './ui-expanding-button.scss';

/* eslint-disable-next-line */
export interface ExpandingButtonProps {
  icon: IconType;
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export function ExpandingButton(props: ExpandingButtonProps) {
  return (
    <button type="button" className="expanding-button" onClick={props.onClick}>
      <span className="icon">
        <props.icon />
      </span>
      <span className="text">{props.text}</span>
    </button>
  );
}

export default ExpandingButton;
