import { Product, WithId } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import './ProductItem.scss';
import numeral from 'numeral';
import { storage } from '@giftery/firebase';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { classNames, getRelation } from '@giftery/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@giftery/ui/interfaces';
import { validURL } from '@giftery/utils';
import { FiAlertTriangle, FiBox } from 'react-icons/fi';

interface ProductItemProps {
  product: WithId<Product>;
}

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  const [image, setImage] = useState<string>();
  const categoryData = useSelector(
    (state: RootState) => state.firestore.ordered.categories
  );
  const hobbyData = useSelector(
    (state: RootState) => state.firestore.ordered.hobbies
  );
  const getImage = async () => {
    if (!product || !product.images || isEmpty(product.images)) return null;
    const mainImage = product.images[0];
    let imageUrl = mainImage;
    if (!mainImage) return;
    if (!validURL(imageUrl)) {
      imageUrl = await storage.ref(`/images/${mainImage}`).getDownloadURL();
    }
    setImage(imageUrl);
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderImage = () => {
    const imgUrl = image?.indexOf('http') > -1 ? image : null;
    if (!imgUrl) {
      return (
        <div className="h-10 w-10 rounded-full p-1 flex items-center justify-center">
          <FiBox size={24} className="stroke-current text-gray-300" />
        </div>
      );
    }
    return <img className="h-10 w-10 rounded-full" src={imgUrl} alt="" />;
  };

  if (!product) return <>Loading</>;
  const stock = product.variants
    .map((v) => v.quantity)
    .reduce((v, p) => v + p, 0);
  return (
    <div className="grid grid-cols-12 p-4">
      <div className="col-span-1">{renderImage()}</div>
      <div className="col-span-4">
        <Link to={`/products/${product.id}`}>
          <p className="my-0">{product.name}</p>
          <p className="text-muted my-0">{product.description}</p>
        </Link>
      </div>
      <div className="col-span-2 flex items-center">
        <p className="my-0">
          {getRelation(categoryData, product.categories[0], 'name')}
        </p>
      </div>
      <div className="col-span-2 flex items-center">
        <p className="my-0">
          {getRelation(hobbyData, product.hobbies[0], 'name')}
        </p>
      </div>

      <div className="col-span-1 flex items-center">
        <p className="my-0">{numeral(product.price).format('$0,0.00')}</p>
      </div>
      <div className="col-span-2 flex items-center justify-center">
        <p
          className={classNames(
            stock > 0 ? 'bg-tertiary-500 text-white' : '',
            'col-span-1 text-xs my-0 py-1 px-3 text-center flex flex-row items-center justify-center'
          )}
        >
          {stock > 0 ? (
            `${stock} In Stock`
          ) : (
            <span className="flex flex-row items-center justify-center text-danger">
              <FiAlertTriangle className="mr-1" />
              <span>Out of Stock</span>
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default ProductItem;
