import React from 'react';
import './ui-sanitize-html.scss';
import sanitizeHtml from 'sanitize-html';
import { classNames } from '@giftery/utils';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'br', 'p', 'h1', 'h2', 'h3'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: [],
};

const sanitize = (dirtyHtml: string, options: sanitizeHtml.IOptions = {}) => ({
  __html: sanitizeHtml(dirtyHtml, { ...defaultOptions, ...options }),
});

export interface SanitizeHtmlProps {
  html: string;
  options?: sanitizeHtml.IOptions;
  summary?: boolean;
  className?: string;
}

export const SanitizeHTML: React.FC<SanitizeHtmlProps> = ({
  html,
  options,
  summary,
  className,
}) => {
  const data = sanitize(html, options);
  if (summary) {
    const doc = new DOMParser().parseFromString(data.__html, 'text/html');
    const textSummary = doc.querySelector('p');
    if (textSummary) {
      return (
        <div
          className={classNames('sanitize-html', className ?? '')}
          dangerouslySetInnerHTML={{ __html: textSummary.outerHTML }}
        ></div>
      );
    }
  }
  return (
    <div
      className={classNames('sanitize-html', className ?? '')}
      dangerouslySetInnerHTML={data}
    />
  );
};
