import {
  Product,
  ProductVariant,
  ProductVariantOption,
  WithId,
} from '@giftery/api-interface';
import { Tag, TagsInput } from '@giftery/ui/tagsinput';
import { recursivelyCombine } from '@giftery/utils';
import { map, chain, find } from 'lodash';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { FiPlus, FiTrash } from 'react-icons/fi';
import CreatableSelect from 'react-select/creatable';

interface ProductVariantsProps {
  product: WithId<Product>;
  onChange: (
    variants: ProductVariant[],
    options: ProductVariantOption[]
  ) => void;
}

interface VariantOption {
  value: string;
  label: string;
}

const VariantLoader = (props) => {
  return (
    <ContentLoader viewBox="0 0 960 110" height={110} {...props}>
      <rect x="0" y="10" rx="0" ry="0" width={960} height="20" />
      <rect x="0" y="40" rx="0" ry="0" width={960} height="20" />
      <rect x="0" y="70" rx="0" ry="0" width={960} height="20" />
    </ContentLoader>
  );
};

export const ProductVariants: React.FC<ProductVariantsProps> = ({
  product,
  ...props
}) => {
  const [variantsLoading, setVariantsLoading] = useState<boolean>(true);
  const [variants, setVariants] = useState<ProductVariant[]>([]);
  const [options, setOptions] = useState<ProductVariantOption[]>([
    {
      id: null,
      product_id: product.shopify.productId ?? null,
      name: 'Default',
      position: 1,
      values: [],
    },
  ]);

  useEffect(() => {
    setVariants(product.variants);
    if (product.options && product.options.length > 0) {
      setVariantsLoading(true);
      setOptions(product.options);
      setVariants(product.variants);
    }
    setVariantsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.variants, product.options]);

  const buildVariant = (label: string, index: number): ProductVariant => {
    const variantOptions = label.split('/');
    const existingVariant = find(product.variants, (v) => v.type === label);
    if (existingVariant) return existingVariant;
    return {
      id: product?.variants[index]?.id || null,
      type: label,
      price: product?.variants[index]?.price || 0,
      quantity: product?.variants[index]?.quantity || 0,
      option1: variantOptions[0],
      option2: variantOptions[1] || null,
      option3: variantOptions[2] || null,
      sku: product?.variants[index]?.sku || '',
      shopify: {
        inventoryId: product?.variants[index]?.shopify.inventoryId || null,
      },
    };
  };

  useEffect(() => {
    const variantList = recursivelyCombine(options.map((o) => o.values));
    const newVariants = variantList?.map(buildVariant) || [];
    if (newVariants.length === variants.length) return;
    setVariants(newVariants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, variants]);

  useEffect(() => {
    const dispatchVariants: ProductVariant[] = []
      .concat(chain(variants).flatMap().value())
      .filter((v) => v.type.trim() !== '');
    props.onChange(dispatchVariants, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  const updateVariant = (variant: ProductVariant, index) => {
    const newVariants = [...variants];
    newVariants[index] = variant;
    setVariants(newVariants);
  };

  const concatVariantOptions = (variant: ProductVariant) => {
    let label = `${variant.option1}`;
    if (variant.option2) {
      label += `/${variant.option2}`;
    }
    if (variant.option3) {
      label += `/${variant.option3}`;
    }
    return label;
  };

  const updateOption = (option: VariantOption, index: number) => {
    const optionsCopy = [...options];
    optionsCopy[index] = {
      ...optionsCopy[index],
      name: option.value,
    };
    setOptions(optionsCopy);
  };

  const updateOptionValues = (tags: Tag[], index: number) => {
    const optionsCopy = [...options];
    optionsCopy[index] = {
      ...optionsCopy[index],
      values: tags.map((t) => t.name),
    };
    setOptions(optionsCopy);
  };

  const addOption = () => {
    const option: ProductVariantOption = {
      id: null,
      product_id: product.shopify.productId ?? null,
      name: '',
      position: options.length,
      values: [],
    };
    setOptions([...options, option]);
  };

  const removeOption = (index: number) => {
    const optionsCopy = [...options];
    optionsCopy.splice(index, 1);
    setOptions(optionsCopy);
  };

  const renderVariant = (variant: ProductVariant, index: number) => {
    return (
      <div
        className="product-variant grid grid-cols-4 md:grid-cols-12 gap-2 pb-2"
        key={`variant-${variant.type}-${index}`}
      >
        <div className="col-span-4 lg:col-span-3">
          <label className="inline-block lg:hidden">Label</label>
          <input
            type="text"
            name="variant-label"
            disabled={true}
            className="focus:ring-secondary-500 focus:border-secondary-500 block w-full sm:text-sm border-gray-300 disabled:bg-gray-200"
            value={concatVariantOptions(variant)}
          />
        </div>
        <div className="col-span-4 lg:col-span-3">
          <label className="inline-block lg:hidden">SKU</label>
          <input
            type="text"
            name="variant-sku"
            className="focus:ring-secondary-500 focus:border-secondary-500 block w-full sm:text-sm border-gray-300"
            value={variant.sku}
            onChange={(e) =>
              updateVariant({ ...variant, sku: e.target.value || '' }, index)
            }
            placeholder="SKU"
          />
        </div>
        <div className="col-span-4 lg:col-span-3">
          <label className="inline-block lg:hidden">Price</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300"
              placeholder="0.00"
              aria-describedby="price-currency"
              min={0.0}
              value={variant.price}
              onChange={(e) =>
                updateVariant(
                  {
                    ...variant,
                    price: parseFloat(e.target.value) || 0,
                  },
                  index
                )
              }
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                NZD
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-4 lg:col-span-3">
          <label className="inline-block lg:hidden">Quantity</label>
          <input
            type="number"
            name="variant-stock"
            className="focus:ring-secondary-500 focus:border-secondary-500 block w-full sm:text-sm border-gray-300"
            value={variant.quantity}
            min={1}
            onChange={(e) =>
              updateVariant(
                {
                  ...variant,
                  quantity: parseInt(e.target.value) || 0,
                },
                index
              )
            }
            placeholder="Stock"
          />
        </div>
      </div>
    );
  };

  if (variantsLoading) return <VariantLoader />;

  return (
    <div>
      <div>
        {/* Options definitions */}
        {map(options, (option, index) => (
          <div
            key={`options-${index}`}
            className="grid grid-cols-12 sm:grid-cols-12 gap-2 py-2"
          >
            <div className="col-span-4">
              <CreatableSelect
                id="variant-options"
                value={{ label: option.name, value: option.name }}
                onChange={(newOption) => updateOption(newOption, index)}
              />
            </div>
            <div className="col-span-7">
              <TagsInput
                placeholder="Add an Option"
                inputClassName="border-0 focus:ring-0 py-1"
                data={option.values.map((o) => ({ id: null, name: o }))}
                initial={option.values.map((o) => ({ id: null, name: o }))}
                onChange={(tags) => updateOptionValues(tags, index)}
              />
            </div>
            <div className="col-span-1">
              {index > 0 && (
                <button
                  type="button"
                  className="text-primary-500 disabled:opacity-50 flex items-center justify-center w-full py-2"
                  onClick={() => removeOption(index)}
                >
                  <FiTrash />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <button
          type="button"
          className="text-secondary-500 disabled:opacity-50 flex items-center justify-center w-full py-2"
          disabled={options.length >= 3}
          onClick={addOption}
        >
          <FiPlus /> Add Option
        </button>
      </div>
      <div>
        {/* Variant details */}
        {variants.length > 0 && (
          <>
            <div className="product-variant hidden lg:grid grid-cols-12 sm:grid-cols-12 gap-2">
              <label className="col-span-3 font-bold py-2 my-0">Label</label>
              <label className="col-span-3 font-bold py-2 my-0">SKU</label>
              <label className="col-span-3 font-bold py-2 my-0">Price</label>
              <label className="col-span-3 font-bold py-2 my-0">Stock</label>
            </div>
            {map(variants, (item, i) => renderVariant(item, i))}
          </>
        )}
      </div>
    </div>
  );
};
