import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import LoginPage from './components/Auth/Login';
import ReactDOM from 'react-dom';
import { configureAppStore } from './store/store';
import reportWebVitals from './reportWebVitals';
import AppWithSideBar from './containers/AppWithSidebar';
import { Toaster } from 'react-hot-toast';
import { createBrowserHistory } from 'history';
import 'firebase/firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { firebase } from '@giftery/firebase';
import { AuthenticatedRoute, AuthIsLoaded } from '@giftery/ui/auth-check';
import 'tailwindcss/tailwind.css';
import './Variables.scss';
import './Theme.scss';
import DashboardPage from './components/Dashboard/Dashboard';
import OnBoardingWizard from './components/OnboardingWizard/OnBoardingWizard';
import ProductsPage from './components/Products/Products';
import ProductPage from './components/Product/Product';
import OrdersPage from './components/Orders/Orders';
import SettingsPage from './containers/SettingsPage';
import NotificationsPage from './components/Settings/Notifications/NotificationsPage';
import BillingPage from './components/Settings/Billing/BillingPage';
import ProfilePage from './components/Settings/Profile/ProfilePage';
import IntegrationsPage from './components/Settings/Integrations/integrationsPage';
import { DeactivatedPage } from './components/Deactivated/DeactivatedPage';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import ShippingPage from './components/Settings/Shipping/ShippingPage';
Sentry.init({
  dsn: 'https://a992a85743ca4ee0997d51cb8103bd41@o976959.ingest.sentry.io/5933448',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const history = createBrowserHistory();

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true,
};

firebase.firestore();

const store = configureAppStore(history);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const App = () => {
  return (
    <Router>
      <AuthIsLoaded>
        <Toaster position="top-right" containerStyle={{ right: '1.75em' }} />
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <AuthenticatedRoute roles={['supplier']} path="/dashboard">
            <AppWithSideBar>
              <DashboardPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/orders/:id">
            <AppWithSideBar>
              <OrdersPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/orders">
            <AppWithSideBar>
              <OrdersPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/products/:id">
            <AppWithSideBar>
              <ProductPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/products">
            <AppWithSideBar>
              <ProductsPage />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/account-deactivated">
            <DeactivatedPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/settings/profile">
            <AppWithSideBar>
              <SettingsPage component={ProfilePage} />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute
            roles={['supplier']}
            path="/settings/notifications"
          >
            <AppWithSideBar>
              <SettingsPage component={NotificationsPage} />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/settings/shipping">
            <AppWithSideBar>
              <SettingsPage component={ShippingPage} />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/settings/billing">
            <AppWithSideBar>
              <SettingsPage component={BillingPage} />
            </AppWithSideBar>
          </AuthenticatedRoute>
          <AuthenticatedRoute
            roles={['supplier']}
            path="/settings/integrations"
          >
            <AppWithSideBar>
              <SettingsPage component={IntegrationsPage} />
            </AppWithSideBar>
          </AuthenticatedRoute>

          <AuthenticatedRoute roles={['supplier']} path="/settings">
            <Redirect to="/settings/profile" />
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/get-started">
            <OnBoardingWizard />
          </AuthenticatedRoute>
          <AuthenticatedRoute roles={['supplier']} path="/">
            <Redirect to="/dashboard" />
          </AuthenticatedRoute>
        </Switch>
      </AuthIsLoaded>
    </Router>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
