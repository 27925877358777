import React, { forwardRef, useImperativeHandle } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { nanoid } from 'nanoid';
import firebase from 'firebase/app';
import { storage } from '@giftery/firebase';

// And import the necessary css
import 'filepond/dist/filepond.min.css';
import './ui-file-upload.scss';
import { ActualFileObject } from 'filepond';

// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginFileValidateType);

/* eslint-disable-next-line */
export interface FileUploadProps {
  onRequestSave: (id: string) => void;
  onRequestClear: () => void;
  onRequestRevert: (id: string) => void;
  label?: string;
  limit?: number;
  accepted?: string[];
  path: string;
  defaultFiles: File[];
}

export interface FileUploadMethods {
  reset: () => void;
  remove: (index: number) => void;
}

export const FileUpload = forwardRef<FileUploadMethods, FileUploadProps>(
  (props, ref) => {
    const {
      onRequestSave,
      onRequestClear,
      onRequestRevert,
      label = 'Drag & drop your files here here or',
      limit = 5,
      defaultFiles,
      path,
      accepted,
    } = props;
    // use a useState hook to maintain our files collection
    const [files, setFiles] = React.useState<File[]>(defaultFiles);

    const server = {
      // this uploads the file using firebase
      process: (
        fieldName: any,
        file: ActualFileObject,
        metadata: any,
        load: (arg0: string) => void,
        error: (arg0: string) => void,
        progress: (arg0: boolean, arg1: number, arg2: number) => void,
        abort: any
      ) => {
        // create a unique id for the file
        // upload the image to firebase
        const task = storage.ref().child(`${path}/${file.name}`).put(file, {
          contentType: file.type,
        });

        // monitor the task to provide updates to FilePond
        task.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snap) => {
            // provide progress updates
            progress(true, snap.bytesTransferred, snap.totalBytes);
          },
          (err) => {
            // provide errors
            error(err.message);
          },
          () => {
            // the file has been uploaded
            load(file.name);
            onRequestSave(file.name);
          }
        );
      },

      revert: (uniqueFieldId: string, load: any, error: any) => {
        onRequestRevert(uniqueFieldId);
      },

      // this loads an already uploaded image to firebase
      load: (
        source: string,
        load: (arg0: any) => void,
        error: (arg0: any) => void,
        progress: (arg0: boolean, arg1: number, arg2: number) => void,
        abort: () => void
      ) => {
        // reset our progress
        progress(true, 0, 1024);

        // fetch the download URL from firebase
        storage
          .ref()
          .child(`${path}/${source}`)
          .getDownloadURL()
          .then((url) => {
            // fetch the actual file using the download URL
            // and provide the blob to FilePond using the load callback
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function (event) {
              const blob = xhr.response;
              load(blob);
            };
            xhr.open('GET', url);
            xhr.send();
          })
          .catch((err) => {
            error(err.message);
            abort();
          });
      },
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      reset() {
        setFiles(defaultFiles);
      },
      remove(index) {
        if (!files[index]) return;
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      },
    }));

    return (
      <FilePond
        labelIdle={`${label} <span class="filepond--label-action">Browse Your Computer</span>`}
        files={files}
        allowMultiple={true}
        acceptedFileTypes={accepted}
        maxFiles={limit}
        onupdatefiles={(fileItems) => {
          if (fileItems.length === 0) {
            onRequestClear();
          }
          setFiles(fileItems.map((fileItem) => fileItem.file) as File[]);
        }}
        server={server} // todo: add custom server functionality using firebase
      />
    );
  }
);
