import algoliasearch from 'algoliasearch';

const environment =
  process.env.NODE_ENV === 'production'
    ? 'give-production'
    : 'giftery-development';
let ALGOLIA_ID = 'EH3B7M1VA0';
let ALGOLIA_SEARCH_KEY = 'f4758e7a395ee285a4d74030a2b5d983';
if (environment === 'giftery-development') {
  ALGOLIA_ID = `QGPJLO0WN1`;
  ALGOLIA_SEARCH_KEY = `521b52c9934c370233ca6478cd277c66`;
}
export const client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY);
