import { useNotifications, useStore } from '../../../hooks';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { FiInbox } from 'react-icons/fi';
import { updateSupplier } from '../../../actions/Supplier';
import { isValidEmail } from '@giftery/utils';
import toast from 'react-hot-toast';

const NotificationsPage = () => {
  const [notifications] = useNotifications();
  const [newOrderNotification, setNewOrderNotification] = useState('');
  const history = useHistory();
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const reset = () => {
    setNewOrderNotification(store?.emailNotifications?.orders?.[0] || '');
  };

  const save = async () => {
    setLoading(true);
    if (!isValidEmail(newOrderNotification))
      return toast.error('Please provide a valid email address.');
    const promise = updateSupplier(
      store.id,
      { emailNotifications: { orders: [newOrderNotification] } },
      null
    );
    await toast.promise(promise, {
      loading: `Updating your notification preferences, please wait.`,
      success: `Success! We've updated your notification preferences.`,
      error: `Oh no! Something went wrong while updating your notification preferences. Please try again later.`,
    });
    setLoading(false);
  };

  return (
    <>
      <section aria-labelledby="billing-history-heading">
        <div className="bg-white pt-6 shadow sm:overflow-hidden">
          <div className="px-4 sm:px-6">
            <h2
              id="billing-history-heading"
              className="text-xl font-bold leading-6 text-gray-900"
            >
              Notification History
            </h2>
          </div>
          <div className="mt-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden border-t border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                      {notifications?.map((notification) => (
                        <tr
                          key={notification.id}
                          onClick={() =>
                            notification.url
                              ? history.push(notification.url)
                              : null
                          }
                          className="grid grid-flow-col auto-cols-max"
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            <time
                              dateTime={(
                                notification.date as FirebaseFirestore.Timestamp
                              )
                                .toDate()
                                .toISOString()}
                            >
                              {format(
                                (
                                  notification.date as FirebaseFirestore.Timestamp
                                ).toDate(),
                                'dd MMM yyyy'
                              )}
                            </time>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {notification.message}
                          </td>
                        </tr>
                      ))}
                      {notifications.length <= 0 && (
                        <div className="text-center flex flex-col items-center justify-center">
                          <FiInbox
                            size={48}
                            className="stroke-current text-gray-200 mx-auto mt-24"
                          />
                          <p className="text-center p-0 mb-24">
                            You have no recent notifications
                          </p>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section aria-labelledby="billing-history-heading">
        <div className="bg-white pt-6 shadow sm:overflow-hidden">
          <div className="px-4 sm:px-6">
            <h2
              id="billing-history-heading"
              className="text-xl font-bold leading-6 text-gray-900"
            >
              Notification Preferences
            </h2>
          </div>
          <div className="mt-6 flex flex-col">
            {/* New Order Notifications */}
            <div className="flex px-6 items-start py-2">
              <div className="w-full">
                <label>New Order Notifications</label>
                <div className="relative">
                  <input
                    type="email"
                    className="focus:ring-secondary-500 focus:border-secondary-500 block w-full sm:text-sm border-gray-300 disabled:bg-gray-100 disabled:pointer-events-none"
                    placeholder={`e.g. orders@yourbusiness.co.nz`}
                    aria-describedby="notification-email"
                    min={0.0}
                    onFocus={(event) => event.target.select()}
                    value={newOrderNotification}
                    onChange={(e) =>
                      setNewOrderNotification(e.target.value || '')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="py-4 px-4 flex justify-end sm:px-6">
            <button
              type="button"
              onClick={reset}
              disabled={loading}
              className="ml-5 border-secondary-500 border-2
             py-2 px-4 inline-flex justify-center text-sm font-medium text-secondary-500
             hover:bg-secondary-800 focus:outline-none"
            >
              Reset
            </button>
            <button
              type="button"
              disabled={loading}
              onClick={save}
              className="
            ml-2 bg-secondary-500 text border border-transparent
            py-2 px-4 inline-flex justify-center text-sm font-medium text-white
            hover:bg-secondary-800 focus:outline-none disabled:opacity-50"
            >
              {loading ? 'Please Wait...' : 'Save'}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotificationsPage;
