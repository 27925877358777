import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useMe } from './UseMe';

export function useStore() {
  const me = useMe();
  useFirestoreConnect([
    {
      collection: DBCollection.suppliers,
      doc: me?.supplier || 'LOADING_SUPPLIER',
    },
  ]);
  const store = useSelector((state: SupplierRootState) => {
    return state.firestore.data.suppliers?.[me?.supplier]
      ? { ...state.firestore.data.suppliers?.[me?.supplier], id: me?.supplier }
      : null;
  });
  return store;
}
