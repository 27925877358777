import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { RootState } from '@giftery/ui/interfaces';
import { History } from 'history';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SupplierRootState extends RootState {}

const rootReducer = (history: History) =>
  combineReducers<SupplierRootState>({
    router: connectRouter(history) as Reducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });

export default rootReducer;
