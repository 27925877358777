import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';

export function useSubscriptionPricing() {
  useFirestoreConnect([
    {
      collectionGroup: DBCollection.prices,
      where: ['active', '==', true],
      storeAs: 'prices',
    },
  ]);
  const subscriptions = useSelector(
    (state: SupplierRootState) => state.firestore.ordered.prices || null
  );
  return subscriptions;
}
