import React, { useEffect, useState } from 'react';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from 'react-instantsearch-dom';
import './ProductSearch.scss';
import { storage } from '@giftery/firebase';
import { Link } from 'react-router-dom';
import { client } from '@giftery/algolia-client';
import { FiBox } from 'react-icons/fi';
import { useMe } from '../../../../hooks';

const Hit = (props) => {
  const { hit } = props;
  const [image, setImage] = useState<string>();
  useEffect(() => {
    if (!image) {
      const getImage = async () => {
        const mainImage = hit.images[0];
        if (mainImage) {
          let imageUrl = mainImage;
          if (mainImage.indexOf('http') === -1) {
            imageUrl = await storage
              .ref(`/images/${mainImage}`)
              .getDownloadURL();
          }
          setImage(imageUrl);
        }
      };
      getImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, hit]);
  return (
    <Link
      className="hit border-0 hover:no-underline w-full flex items-center justify-start"
      to={`/products/${hit.objectID}`}
    >
      <div className="flex-shrink-0 h-10 w-10">
        {image ? (
          <img
            className="h-10 w-10 rounded-full mr-2"
            src={image}
            alt={hit.name}
          />
        ) : (
          <div className="h-10 w-10 rounded-full mr-2 flex items-center justify-center">
            <FiBox size={24} className="stroke-current text-gray-300" />
          </div>
        )}
      </div>
      <div className="col-span-10 no-underline text-grey-500">
        <div className="text-sm font-medium text-gray-900">
          {hit.name} <span className="text-grey-500">(SKU: {hit.sku})</span>
        </div>
        <div className="text-sm text-gray-500">{hit.description}</div>
      </div>
    </Link>
  );
};

const ProductSearch = () => {
  const [isSearching, setisSearching] = useState<boolean>(false);
  const me = useMe();
  const onSearchStateChange = (state) => {
    setisSearching(state.query?.length > 0);
  };
  return (
    <div className="ProductSearchParent rounded-0 w-full">
      <div className="ProductSearch rounded-0 font-sans relative">
        <InstantSearch
          indexName="products"
          searchClient={client}
          onSearchStateChange={onSearchStateChange}
        >
          <Configure
            filters={`status.active:true AND supplierId:${me?.supplier}`}
          />
          <SearchBox
            translations={{
              placeholder: 'Search For A Product...',
            }}
          />
          {/* eslint-disable-next-line */}
          {isSearching && <Hits hitComponent={Hit} />}
        </InstantSearch>
      </div>
    </div>
  );
};

export default ProductSearch;
