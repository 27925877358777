import { PageLoader } from '@giftery/ui/page-loader';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Redirect } from 'react-router';
import PopularProducts from './components/PopularProducts/PopularProducts';
import ProductSearch from './components/ProductSearch/ProductSearch';
import RecentOrders from './components/RecentOrders/RecentOrders';
import SalesHistory from './components/SalesHistory/SalesHistory';
import StoreSummary from './components/StoreSummary/StoreSummary';
import './Dashboard.scss';
import { NumberCards } from './components/NumberCards/NumberCards';
import { useStore, useMe } from '../../hooks';
import PayoutDetails from './components/PayoutDetails/PayoutDetails';

const DashboardPage = () => {
  const me = useMe();
  const supplier = useStore();
  if (me && me.supplier === null) {
    return <Redirect to="/login" />;
  }
  if (!supplier) {
    return <PageLoader />;
  }
  if (!supplier.status.active && !supplier.status.deleted) {
    return <Redirect to="/get-started" />;
  }
  if (
    (!supplier.status.active && supplier.status.deleted) ||
    supplier.status.suspended
  ) {
    return <Redirect to="/account-deactivated" />;
  }

  return (
    <div className="grid grid-cols-12">
      <Helmet>
        <title>The Giftery | Dashboard</title>
      </Helmet>
      <div className="col-span-12 lg:col-span-8 order-2 lg:order-1 p-8">
        <Scrollbars
          autoHeight
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          autoHeightMin="96vh"
          autoHide={false}
        >
          <div className="flex w-full p-1">
            <ProductSearch />
          </div>
          <div className="flex w-full p-1">
            <PayoutDetails />
          </div>
          <div className="flex w-full p-1">
            <div className="w-full grid grid-cols-12 gap-2">
              <div className="col-span-12 xl:col-span-5">
                <NumberCards />
              </div>
              <SalesHistory className="col-span-12 xl:col-span-7" />
            </div>
          </div>
          <div className="flex w-full p-1">
            <PopularProducts />
          </div>
        </Scrollbars>
      </div>

      <div className="h-auto xl:h-screen col-span-12 lg:col-span-4 order-1 lg:order-2 p-8">
        <StoreSummary />
        <RecentOrders />
      </div>
    </div>
  );
};

export default DashboardPage;
