import React, { useEffect, useState } from 'react';
import { useMe, useSubscription } from '../../../../../hooks';
import { format } from 'date-fns';
import { FiDownloadCloud } from 'react-icons/fi';
import numeral from 'numeral';
import type { Stripe } from 'stripe';

const BillingHistory = () => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<Stripe.Product>();
  const me = useMe();
  const { subscription, invoices } = useSubscription(me.id);
  const getProduct = async () => {
    const productRef = (me?.subscription as any)
      ?.product as FirebaseFirestore.DocumentReference;
    const productDoc = await productRef.get();
    setProduct(productDoc.data() as Stripe.Product);
  };

  useEffect(() => {
    if (!product && subscription) getProduct().then();
  }, [subscription]);

  const downloadInvoice = async (pdfUrl: string) => {
    setLoading(true);
    window.open(pdfUrl, '_blank');
    setLoading(false);
  };

  if (!me?.subscription) return null;

  return (
    <section aria-labelledby="billing-history-heading">
      <div className="bg-white pt-6 shadow sm:overflow-hidden">
        <div className="px-4 sm:px-6">
          <h2
            id="billing-history-heading"
            className="text-xl font-bold leading-6 text-gray-900"
          >
            Billing history
          </h2>
        </div>
        <div className="mt-6 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden border-t border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Amount Due
                      </th>
                      {/*
                        `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                      */}
                      <th
                        scope="col"
                        className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {invoices?.map((invoice) => (
                      <tr key={invoice.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <time
                            dateTime={new Date(
                              invoice.created * 1000
                            ).toISOString()}
                          >
                            {format(
                              new Date(invoice.created * 1000),
                              'dd MMM yyyy'
                            )}
                          </time>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {product?.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          ${numeral(invoice.amount_due).format('0,0.00')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            type="button"
                            onClick={() => downloadInvoice(invoice.invoice_pdf)}
                            className="text-secondary-500 mr-2 flex row justify-end items-center hover:text-orange-900"
                          >
                            <FiDownloadCloud className="mr-2" /> Download
                            Invoice
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BillingHistory;
