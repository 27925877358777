import { Order } from '@giftery/api-interface';
import { chain, map } from 'lodash';
import React from 'react';
import { formatDistance } from 'date-fns';
import numeral from 'numeral';
import { OrderProductItem } from './OrderProductItem';
import { Link } from 'react-router-dom';

interface OrderItemProps {
  order: Order;
}

const renderDate = (date: FirebaseFirestore.Timestamp | Date) => {
  if (!date) return null;
  return formatDistance(
    (date as FirebaseFirestore.Timestamp).toDate(),
    new Date(),
    {
      addSuffix: true,
    }
  );
};

const getOrderTotal = (order: Order): string => {
  const total =
    chain(order?.products)
      .filter((p) => p.status.selected)
      .reduce((v, p) => (v += p.price * p.quantity || 0), 0)
      .value() || 0;

  return numeral(total).format(`$0,0.00`);
};

export const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
  return (
    <div className="relative block bg-white px-6 py-4 cursor-pointer hover:border-gray-400 hover:no-underline hover:shadow-md sm:flex sm:justify-between focus:outline-none flex flex-col">
      <Link to={`/orders/${order.id}`} className="hover:no-underline">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8 flex items-center">
            <div className="text-sm text-left">
              <p className="font-bold text-gray-900 my-0 font-serif">
                {Object.keys(order.products).length} item
                {Object.keys(order.products).length > 1 ? 's' : ''}
              </p>
              <div className="text-gray-500">
                <p className="sm:inline my-0">
                  {renderDate(order.audit.createdAt)}
                </p>{' '}
                <span className="hidden sm:inline sm:mx-1" aria-hidden="true">
                  &middot;
                </span>{' '}
              </div>
            </div>
          </div>
          <div className="col-span-4 mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
            <div className="font-bold text-green-600">
              +{getOrderTotal(order)}
            </div>
          </div>
        </div>
        {map(order.products, (product) => (
          <OrderProductItem key={product.id} product={product} />
        ))}
      </Link>
    </div>
  );
};
