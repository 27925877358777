import { Product, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';

export function useProduct(id: string): [WithId<Product> | null, boolean] {
  const product = useSelector((state: SupplierRootState) => {
    if (!state.firestore.ordered?.product?.[0]) return null;

    return state.firestore.ordered?.product?.[0];
  });
  useFirestoreConnect([
    {
      collection: DBCollection.products,
      doc: id,
      storeAs: 'product',
    },
  ]);

  const loading = !isLoaded(product);
  return [loading ? null : product, loading];
}
