import { Gifter } from '@giftery/ui/gifter';
import { Logo } from '@giftery/ui/logo';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { useStore } from '../../hooks';

export const DeactivatedPage = () => {
  const store = useStore();

  if (store && !store.status.deleted) return <Redirect to="/" />;

  return (
    <div
      className="
    fixed top-0 bottom-0 left-0 right-0
    bg-secondary-50 text-secondary-500
    flex items-center justify-center flex-col"
    >
      <Helmet>
        <title>The Giftery | Account Deactivated</title>
      </Helmet>
      <div className="mb-2">
        <Gifter className="h-36 w-36 stroke-current fill-current text-secondary-600" />
        <Logo
          className="h-12 stroke-current fill-current text-secondary-600"
          color="secondary-600"
        />
      </div>
      <h1>Your account has been deactivated.</h1>
      <p>
        If you believe this was an error, please{' '}
        <a href="mailto:support@thegiftery.co.nz">contact our support team.</a>
      </p>
    </div>
  );
};
