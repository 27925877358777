import React from 'react';
import { Helmet } from 'react-helmet';
import { Lottie } from '@giftery/ui/lottie';
import animation from '@giftery/ui/lottie/animations/gifter.json';

import './page-loader.scss';

interface PageLoaderProps {
  className?: string;
}

export const PageLoader: React.FC<PageLoaderProps> = ({ className }) => {
  return (
    <div className={`PageLoader ${className}`}>
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <Lottie
        animation={animation}
        className="w-72 h-72 flex flex-col justify-end"
      />
    </div>
  );
};
