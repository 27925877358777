import React, { useState } from 'react';
import { useWizardStep } from 'react-wizard-primitive';
import { Supplier, WithId } from '@giftery/api-interface';
import { LoadingButton } from '@giftery/ui/loading-button';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import { Gifter } from '@giftery/ui/gifter';
import { Logo } from '@giftery/ui/logo';
import { updatePassword } from '../../../../actions/Supplier';
import toast from 'react-hot-toast';

interface PasswordStepProps {
  supplier: WithId<Supplier>;
}

export const PasswordStep: React.FC<PasswordStepProps> = ({ supplier }) => {
  const { isActive, nextStep } = useWizardStep();
  const firebase = useFirebase();
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  if (!isActive) return null;

  const submit = async () => {
    if (password !== repeatPassword)
      return toast.error(
        'Passwords do not match! Please ensure your password matches and try again.'
      );
    if (password.length < 6)
      return toast.error(
        'Your password should be a minimum of 6 characters. Please pick a longer password and try again.'
      );
    setLoading(true);
    await updatePassword(password);
    setLoading(false);
    nextStep();
  };

  return (
    <div className="WizardStep text-secondary-500">
      <Gifter className="h-36 w-36 stroke-current fill-current text-secondary-600" />
      <Logo
        className="h-12 stroke-current fill-current text-secondary-600"
        color="secondary-600"
      />
      <div className="py-3">
        <h1 className="text-3xl font-serif">Getting Started</h1>
        <p className="text-xl font-sans">
          Change your password. Pick a password that is 6 characters or longer
          to continue.
        </p>
      </div>
      <div>
        <label>Password</label>
        <input
          type="password"
          name="password"
          className="w-full border-1 border-secondary-500 text-secondary-500 py-3 px-6"
          id="password"
          placeholder="**********"
          value={password}
          onChange={(event) => setPassword(event.target.value || '')}
        />
      </div>
      <div>
        <label>Repeat Password</label>
        <input
          type="password"
          name="repeat-password"
          className="w-full border-1 border-secondary-500 text-secondary-500 py-3 px-6"
          id="repeat-password"
          placeholder="**********"
          value={repeatPassword}
          onChange={(event) => setRepeatPassword(event.target.value || '')}
        />
      </div>
      <div className="footer">
        <button
          className="bg-transparent
          text-secondary-500 text-xl font-normal
          py-2 px-3 mt-3
          mr-3
          hover:text-secondary-700
          disabled:opacity-50 disabled:pointer-events-none"
          onClick={nextStep}
        >
          Skip
        </button>
        <LoadingButton
          loading={loading}
          feedback="Sit Tight..."
          className="bg-secondary-600
          text-white text-xl font-normal
          py-2 px-3 mt-3
          hover:bg-secondary-700
          disabled:opacity-50 disabled:pointer-events-none"
          disabled={password.length < 6}
          onClick={submit}
        >
          Continue
        </LoadingButton>
      </div>
    </div>
  );
};
