import { Product, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useMe } from './UseMe';

export function useProducts(
  cursor: any,
  orderBy = '__name__',
  size = 10
): [WithId<Product>[] | null, boolean] {
  const me = useMe();

  const products = useSelector((state: SupplierRootState) => {
    return state.firestore.ordered.products;
  });
  useFirestoreConnect([
    {
      collection: DBCollection.products,
      where: [
        ['status.deleted', '==', false],
        ['supplierId', '==', me?.supplier ?? 'LOADING'],
      ],
      startAt: cursor,
      orderBy: [orderBy, 'desc'],
      limit: size,
    },
  ]);

  const loading = !isLoaded(products);

  return [products, loading];
}
