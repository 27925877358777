import { Order, User, WithId } from '@giftery/api-interface';
import { MailIcon } from '@heroicons/react/outline';
import { map, chain } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-tiny-fab/dist/styles.css';
import { ProductImage } from '../ProductImage';
import GoogleMapReact from 'google-map-react';
import numeral from 'numeral';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { FiMapPin, FiTruck } from 'react-icons/fi';
import { useFirestore } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import { validURL } from '@giftery/utils';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { OrderDispatchModal } from './OrderDispatchModal';

interface OrderDetailsProps {
  order: WithId<Order>;
}

const getOrderTotal = (order: Order): string => {
  const total =
    chain(order?.products)
      .filter((p) => p.status.selected)
      .reduce((v, p) => (v += p?.variant?.price || 0 * p.quantity || 0), 0)
      .value() || 0;

  return numeral(total).format(`$0,0.00`);
};

const AnyReactComponent = ({ text, lat, lng }) => (
  <div>
    <FiMapPin size={24} className="current-color text-red-400 -mt-12" />
  </div>
);

export const OrderDetails: React.FC<OrderDetailsProps> = ({ order }) => {
  const [orderId, setOrderId] = useState(order.id);
  const [dispatchModalOpen, setDispatchModalOpen] = useState(false);
  const person = `${order.logistics.shipping.person.firstName} ${order.logistics.shipping.person.lastName}`;
  const [giftee, setGiftee] = useState(person || '');
  const [address, setAddress] = useState<string>(
    order.logistics.shipping.address.address || ''
  );
  const [city, setCity] = useState<string>(
    order.logistics.shipping.address.city || ''
  );
  const [postcode, setPostcode] = useState<string>(
    order.logistics.shipping.address.postcode || ''
  );
  const [region, setRegion] = useState<string>(
    order.logistics.shipping.address.region || ''
  );
  const [country, setCountry] = useState<string>(
    order.logistics.shipping.address.country || ''
  );
  const [trackingNumber, setTrackingNumber] = useState<string>(
    order.trackingNumber || ''
  );
  const [trackingError, setTrackingError] = useState<string>('');
  const firestore = useFirestore();

  useEffect(() => {
    if (orderId === order.id) return;
    setOrderId(order.id);
    setAddress(order.logistics.shipping.address.address || '');
    setPostcode(order.logistics.shipping.address.postcode || '');
    setCity(order.logistics.shipping.address.city || '');
    setTrackingNumber(order.trackingNumber || '');
    setRegion(order.logistics.shipping.address.region || '');
    setCountry(order.logistics.shipping.address.country || '');
  }, [order]);

  const center = {
    lat: -43.507894846521445,
    lng: 172.65057935663796,
  };
  const zoom = 16;

  const updateTrackingNumber = (code: string) => {
    if (validURL(code)) {
      setTrackingError(
        `Tracking number should not include the full URL, only the tracking code.`
      );
    } else {
      setTrackingError('');
      setTrackingNumber(code);
    }
  };

  const onSaveShipping = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await firestore
        .collection(DBCollection.orders)
        .doc(order.id)
        .update({
          'logistics.shipping.address': {
            address,
            city,
            postcode,
            region,
            country,
          },
          trackingNumber: trackingNumber.trim() !== '' ? trackingNumber : null,
        });
      toast.success('Success! Shipping details updated.');
    } catch (error) {
      toast.error(`Error updating shipping address`);
      console.error(error);
    }
  };

  const markAsSent = async () => {
    setDispatchModalOpen(true);
  };

  return (
    <div className="w-full">
      <Helmet>
        <title>The Giftery | Order | {order.id}</title>
      </Helmet>
      <OrderDispatchModal
        order={order}
        trackingNumber={trackingNumber}
        open={dispatchModalOpen}
        onClose={() => setDispatchModalOpen(false)}
      />
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
        <Scrollbars
          autoHeight
          autoHeightMin="100vh"
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}
          className="testing"
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          autoHide={false}
        >
          <article>
            {/* Profile header */}
            <div>
              <div className="h-32 w-full object-cover lg:h-48">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyDsPgoK7bWdsbOXU159HFcYi41rZf-rx0U',
                  }}
                  defaultCenter={center}
                  defaultZoom={zoom}
                >
                  <AnyReactComponent
                    lat={-43.507894846521445}
                    lng={172.65057935663796}
                    text="My Marker"
                  />
                </GoogleMapReact>
              </div>
              <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-2 sm:mt-2 sm:flex sm:items-end sm:space-x-5">
                  <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                    <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                      <h1 className="text-2xl font-bold text-gray-900 truncate">
                        Order ID: {order.id}
                      </h1>
                    </div>
                    <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                      <a
                        href={`mailto:${(order.giftGiver as User).email}`}
                        className="group inline-flex justify-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700
                      hover:bg-secondary-500 hover:no-underline hover:text-white
                      focus:outline-none"
                      >
                        <MailIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-white"
                          aria-hidden="true"
                        />
                        <span>Email Billing Contact</span>
                      </a>
                      <button
                        type="button"
                        disabled={order.status.dispatched}
                        onClick={markAsSent}
                        className="group inline-flex justify-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700
                      hover:bg-secondary-500 hover:text-white
                      focus:outline-none"
                      >
                        {order.status.dispatched ? (
                          <>
                            <CheckCircleIcon
                              className="-ml-1 mr-2 h-5 w-5 text-green-400 group-hover:text-white"
                              aria-hidden="true"
                            />
                            <span>Order Sent</span>
                          </>
                        ) : (
                          <>
                            <FiTruck
                              className="-ml-1 mr-2 h-5 w-5 text-gray-400 group-hover:text-white"
                              aria-hidden="true"
                            />
                            <span>Dispatch Order</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl font-bold text-gray-900 truncate">
                    Order ID: {order.id}
                  </h1>
                </div>
              </div>
            </div>

            {/* Description list */}
            <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <a
                      rel="noreferrer"
                      href={`mailto:${(order.giftGiver as User).email}`}
                      target="_blank"
                    >
                      {(order.giftGiver as User).email}
                    </a>
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Order Total
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {getOrderTotal(order)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Date Received
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {format(
                      (
                        order.statusDates
                          .selected as FirebaseFirestore.Timestamp
                      ).toDate(),
                      'dd/MM/yyyy HH:mm'
                    )}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Date Dispatched
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {order.statusDates.dispatched
                      ? format(
                          (
                            order.statusDates
                              .dispatched as FirebaseFirestore.Timestamp
                          ).toDate(),
                          'dd/MM/yyyy HH:mm'
                        )
                      : 'Not Yet Dispatched'}
                  </dd>
                </div>
              </dl>
            </div>

            {/* Product list */}
            <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8 pb-2">
              <h2 className="text-sm font-medium text-gray-500">
                Products in this order
              </h2>
              <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {map(order.products, (product) => (
                  <div
                    key={product.id}
                    className="relative  border border-gray-300 bg-white px-4 py-2  flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary-500"
                  >
                    <div className="flex-shrink-0">
                      <ProductImage product={product} />
                    </div>
                    <div className="flex-1 min-w-0">
                      <Link
                        to={`/products/${product.id}`}
                        className="focus:outline-none"
                      >
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900 my-0">
                          {product.name}
                        </p>
                        {product.variant?.type && (
                          <p className="text-sm font-medium text-gray-400 my-0">
                            {product.variant?.type}
                          </p>
                        )}
                        <p className="text-sm text-gray-500 truncate my-0">
                          Quantity: <strong>{product.quantity}</strong>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Shipping details */}
            <div className="mt-8 max-w-5xl mx-auto px-4 space-y-6 sm:px-6 lg:px-0 lg:col-span-9 pb-36">
              <section aria-labelledby="payment_details_heading">
                <form onSubmit={onSaveShipping}>
                  <div className="sm:overflow-visible">
                    <div className="bg-white py-6 px-0 sm:p-6">
                      <div>
                        <h2
                          id="payment_details_heading"
                          className="font-bold text-lg leading-6 font-medium text-gray-900"
                        >
                          Shipping details
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          View customer shipping information and add tracking
                          number
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-4 gap-6">
                        <div className="col-span-4 sm:col-span-2">
                          <label
                            htmlFor="first_name"
                            className="font-bold block text-sm font-medium text-gray-700"
                          >
                            Recipient
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            name="address"
                            id="address"
                            autoComplete="off"
                            value={giftee}
                            className="mt-1 block w-full border border-gray-300   py-2 px-3 focus:outline-none focus:border-gray-900 sm:text-sm disabled:bg-gray-50"
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-2"></div>
                        <div className="col-span-4 sm:col-span-2">
                          <label
                            htmlFor="first_name"
                            className="font-bold block text-sm font-medium text-gray-700"
                          >
                            Address
                          </label>
                          <input
                            disabled={order.status.dispatched}
                            type="text"
                            name="address"
                            id="address"
                            autoComplete="off"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="mt-1 block w-full border border-gray-300   py-2 px-3 focus:outline-none focus:border-gray-900 sm:text-sm disabled:bg-gray-50"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label
                            htmlFor="last_name"
                            className="font-bold block text-sm font-medium text-gray-700"
                          >
                            City/Town
                          </label>
                          <input
                            disabled={order.status.dispatched}
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="off"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 py-2 px-3 focus:outline-none focus:border-gray-900 sm:text-sm disabled:bg-gray-50"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label
                            htmlFor="email_address"
                            className="font-bold block text-sm font-medium text-gray-700"
                          >
                            Region
                          </label>
                          <input
                            disabled={order.status.dispatched}
                            type="text"
                            name="state"
                            id="state"
                            autoComplete="off"
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                            className="mt-1 block w-full border border-gray-300   py-2 px-3 focus:outline-none focus:border-gray-900 sm:text-sm disabled:bg-gray-50"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label
                            htmlFor="postal_code"
                            className="font-bold block text-sm font-medium text-gray-700"
                          >
                            Postcode
                          </label>
                          <input
                            disabled={order.status.dispatched}
                            type="text"
                            name="postal_code"
                            id="postal_code"
                            autoComplete="off"
                            value={postcode}
                            onChange={(e) => setPostcode(e.target.value)}
                            className="mt-1 block w-full border border-gray-300   py-2 px-3 focus:outline-none focus:border-gray-900 sm:text-sm disabled:bg-gray-50"
                          />
                        </div>
                        <div className="col-span-8 sm:col-span-4">
                          <label
                            htmlFor="postal_code"
                            className="font-bold block text-sm font-medium text-gray-700"
                          >
                            Tracking Number
                          </label>
                          {trackingError && (
                            <label className="text-danger">
                              {trackingError}
                            </label>
                          )}
                          <input
                            disabled={order.status.dispatched}
                            type="text"
                            name="postal_code"
                            id="postal_code"
                            autoComplete="off"
                            value={trackingNumber}
                            onChange={(e) =>
                              updateTrackingNumber(e.target.value)
                            }
                            className="mt-1 block w-full border border-gray-300 py-2 px-3 focus:outline-none focus:border-gray-900 sm:text-sm disabled:bg-gray-50"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-0 py-2 text-right sm:px-6">
                      <button
                        disabled={order.status.dispatched}
                        type="submit"
                        className="bg-secondary-500 border border-transparent py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-secondary-900 focus:outline-none focus:ring-secondary-500 disabled:opacity-50"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </article>
        </Scrollbars>
      </main>
    </div>
  );
};
