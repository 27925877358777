import React from 'react';
import {
  BellIcon,
  CreditCardIcon,
  UserCircleIcon,
  TruckIcon,
  ViewGridAddIcon,
} from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { useMe, useSubscription } from '../hooks';
import { classNames } from '@giftery/utils';
import { Helmet } from 'react-helmet';
import { version } from '../../../../package.json';

interface SettingsPageProps {
  component: React.FC | React.ComponentClass;
}

export const subNavigation = [
  {
    name: 'Profile',
    page: 'profile',
    icon: UserCircleIcon,
    enabled: true,
    bellsAndWhistles: false,
  },
  {
    name: 'Notifications',
    page: 'notifications',
    icon: BellIcon,
    enabled: true,
    bellsAndWhistles: false,
  },
  {
    name: 'Shipping',
    page: 'shipping',
    icon: TruckIcon,
    enabled: true,
    bellsAndWhistles: false,
  },
  {
    name: 'Plans & Billing',
    page: 'billing',
    icon: CreditCardIcon,
    enabled: true,
    bellsAndWhistles: false,
  },

  {
    name: 'Integrations',
    page: 'integrations',
    icon: ViewGridAddIcon,
    enabled: false,
    bellsAndWhistles: true,
  },
];

interface RouteItem {
  name: string;
  page: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  bellsAndWhistles: boolean;
}

const SettingsPage: React.FC<SettingsPageProps> = (
  props: SettingsPageProps
) => {
  const { component: ChildComponent, ...rest } = props;
  const history = useHistory();
  const me = useMe();

  const navigateTo = (item: RouteItem) => {
    // if (item.bellsAndWhistles && !isBellsAndWhistles) return;
    history.push(`/settings/${item.page}`);
  };
  return (
    <main className="max-w-7xl ml-0 pl-4 pb-10 lg:py-12 lg:px-8">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-4">
          <nav className="space-y-1">
            {subNavigation.map((item) => (
              <div key={item.name}>
                {window.location.pathname.indexOf(item.page) > -1 && (
                  <Helmet>
                    <title>The Giftery | {item.name}</title>
                  </Helmet>
                )}
                <button
                  type="button"
                  onClick={() => navigateTo(item)}
                  className={classNames(
                    window.location.pathname.indexOf(item.page) > -1
                      ? 'bg-white text-secondary-500 hover:bg-white'
                      : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                    classNames(
                      !item.enabled ? 'opacity-50 pointer-events-none' : ''
                    ),
                    'group px-3 py-2 flex items-center text-sm font-medium w-full'
                  )}
                  aria-current={
                    window.location.pathname.indexOf(item.page) > -1
                      ? 'page'
                      : undefined
                  }
                >
                  <item.icon
                    className={classNames(
                      window.location.pathname.indexOf(item.page) > -1
                        ? 'text-secondary-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-1 h-6 w-12'
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                  {item.bellsAndWhistles && (
                    <div className="bg-secondary-500 text-xs text-white px-2 py-1 ml-2 text-uppercase">
                      coming soon
                    </div>
                  )}
                </button>
              </div>
            ))}
          </nav>
          <div className="px-4 mt-12 text-sm text-gray-300">
            The Giftery
            <br />
            Version: {version}
          </div>
        </aside>

        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-8">
          <ChildComponent {...rest} />
        </div>
      </div>
    </main>
  );
};

export default SettingsPage;
