import { NewProduct, UpdateProduct } from '@giftery/api-interface';
import { request } from '@giftery/axios';

export const importProducts = async (products: NewProduct[]) => {
  return await request('post', '/products/import', { products });
};

export const addProduct = async (product: NewProduct) => {
  return await request('post', '/products', { ...product });
};

export const updateProduct = async (
  id: string,
  product: Partial<UpdateProduct>
) => {
  return await request('put', `/products/${id}`, { ...product });
};

export const deleteProduct = async (id: string) => {
  return await request('delete', `/products/${id}`);
};
