module.exports = {
  purge: [
    './apps/retail/**/*.{js,ts,jsx,tsx}',
    './apps/supplier/**/*.{js,ts,jsx,tsx}',
    './apps/admin/**/*.{js,ts,jsx,tsx}',
    './libs/**/*.{js,ts,jsx,tsx}',
  ],
  darkMode: "class",
  i18n: {
    locales: ["en-NZ"],
    defaultLocale: "en-NZ",
  },
  theme: {
    zIndex: {
      '0': 0,
     '10': 10,
     '20': 20,
     '30': 30,
     '40': 40,
     '50': 50,
     '25': 25,
     '50': 50,
     '75': 75,
     '100': 100,
     'top': 9999,
      'auto': 'auto',
    },
    fontFamily: {
      'sans': ['Beatrice', 'system-ui'],
      'serif': ['BasisGrotesque', 'Georgia'],
    },

    extend: {
      typography: (theme) => ({
        DEFAULT: {
          css: {
            color: theme('colors.grey.700'),
            h1: {
              fontFamily: 'serif',
              color: theme('colors.grey.800'),
            },
            h2: {
              fontFamily: 'serif',
              color: theme('colors.grey.800'),
            },
            h3: {
              fontFamily: 'serif',
              color: theme('colors.grey.800'),
            },
            h4: {
              fontFamily: 'serif',
              color: theme('colors.grey.800'),
            },
            h5: {
              fontFamily: 'serif',
              color: theme('colors.grey.800'),
            },
            strong: {
              color: theme('colors.grey.800'),
            },
            a: {
              color: theme('colors.grey.800'),
              '&:hover': {
                color: theme('colors.grey.700')
              },
            },
          },
        },
      }),
      borderRadius: {
        'theme': '0',
        '4xl': '1.75rem'
       },
      colors: {
        "shaded": "#efe8e1",
        // Primary is red
        "primary": {
          "50": "#100103103",
          "100": "#fdf4f2",
          "200": "#f7d3c9",
          "300": "#f2b1a1",
          "400": "#ed947d",
          "500": "#e77254",
          "600": "#db441f",
          "700": "#a13217",
          "800": "#67200e",
          "900": "#2d0e06"
        },
        // secondary is blue
        "secondary": {
          "50": "#d5dfe7",
          "100": "#f2f7fc",
          "200": "#b3cdef",
          "300": "#74a4e2",
          "400": "#357ad5",
          "500": "#21579E",
          "600": "#1b4883",
          "700": "#153865",
          "800": "#0f2848",
          "900": "#09172a"
        },
        // tertiary is green
        "tertiary": {
          "50": "#10d10610b",
          "100": "#f5faf6",
          "200": "#c5e2cb",
          "300": "#95cba1",
          "400": "#65b376",
          "500": "#458c54",
          "600": "#387144",
          "700": "#2c5936",
          "800": "#1e3e25",
          "900": "#112215"
        },
        "lowLight": {
          "50": "#fffcfa",
          "100": "#fffcfa",
          "200": "#fdf1e8",
          "300": "#fce8da",
          "400": "#faddc7",
          "500": "#f8d2b4",
          "600": "#f4b586",
          "700": "#ee9149",
          "800": "#d76814",
          "900": "#8c440d"
        },
        "grey": {
          "50": "#f3f3f3",
          "100": "#ced8e4",
          "200": "#f4f5f5",
          "300": "#f1f2f3",
          "400": "#eff0f1",
          "500": "#23568e",
          "600": "#b3b8bc",
          "700": "#7d858c",
          "800": "#4a4f54",
          "900": "#181a1b"
        },
        "highLight": {
          "50": "#fffcfa",
          "100": "#fffcfa",
          "200": "#fdede2",
          "300": "#fbdbc6",
          "400": "#f9ccae",
          "500": "#f7b78c",
          "600": "#f49c62",
          "700": "#f17b2d",
          "800": "#cd5b0e",
          "900": "#94410a"
        },
        "background": {
          "50": "#fbfaf5",
          "100": "#fdf9f1",
          "200": "#fbf1df",
          "300": "#f9eacd",
          "400": "#f7e2ba",
          "500": "#f5d9a5",
          "600": "#ecb755",
          "700": "#cf8e17",
          "800": "#81590e",
          "900": "#2e2005"
        },
        "danger": "#ff4136"
      },
      borderColor: theme => ({
         ...theme('colors')
       }),
    }
  },
  variants: {
    extend: {
      backgroundColor: ["checked","disabled"],
      borderColor: ["checked","focus"],
      inset: ["checked"],
      zIndex: ["hover", "active"],
      opacity: ['disabled'],
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
  ],
  future: {
    purgeLayersByDefault: true,
  },
}
