import tailwindConfig from '../../../tailwind.config.js';

export const colors = {
  danger: '#f27474',
  ...tailwindConfig.theme.extend.colors,
};

export const selectStyleFactory = (
  color: 'primary' | 'secondary' | 'tertiary' | 'grey',
  borderWidth = 2
) => ({
  control: (existing) => ({
    ...existing,
    borderRadius: 0,
    borderWidth: borderWidth,
    borderColor: colors[color][200],
    boxShadow: 'none',
    outline: 0,
    ':hover': {
      boxShadow: 'none',
      borderColor: colors[color][300],
    },
    'input:focus': {
      outline: 'none',
      boxShadow: 'none',
      border: '0',
      borderColor: colors[color][500],
    },
    ':focus': {
      outline: 'none',
      boxShadow: 'none',
      border: '0',
      borderColor: colors[color][500],
    },
  }),
  menu: (existing) => ({
    ...existing,
    borderRadius: 0,
  }),
  option: (existing) => ({
    ...existing,
    borderRadius: 0,
    ':hover': {
      boxShadow: 'none',
      backgroundColor: colors[color][50],
    },
  }),
  input: () => ({
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    outline: 0,
    ':hover': {
      border: 'none',
      boxShadow: 'none',
      borderColor: 'transparent !important',
    },
    'input:focus': {
      boxShadow: 'none',
    },
  }),
});
