/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import { ImportProduct, Order, WithId } from '@giftery/api-interface';
import { dispatchOrder } from '../../../actions/Orders';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { isEmpty, map, remove } from 'lodash';
import { FileUpload, FileUploadMethods } from '@giftery/ui/file-upload';
import { useStore } from '../../../hooks';
interface OrderDispatchModalProps {
  trackingNumber: string;
  open: boolean;
  order: WithId<Order>;
  onClose: () => void;
}

export const OrderDispatchModal: React.FC<OrderDispatchModalProps> = ({
  open,
  order,
  trackingNumber,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<string[]>([]);
  const store = useStore();
  const isDisabled = () => {
    return loading || isEmpty(files);
  };

  const startDispatch = async () => {
    setLoading(true);
    await toast
      .promise(dispatchOrder(order.id, trackingNumber, files), {
        error: `Unable complete your order dispatch. Please check your file(s) and try again.`,
        success: `Great! We've successfully dispatched your order.`,
        loading: `Hang tight! We're completing your order dispatch`,
      })
      .finally(() => setLoading(false));
    props.onClose();
  };

  const fileUploadRef = useRef<FileUploadMethods>();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto p-6"
        aria-disabled={isDisabled()}
        onClose={props.onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="
              inline-block align-middle bg-white px-6 pt-12 pb-4 text-left w-3/4 overflow-hidden shadow-xl transform transition-all my-8"
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={props.onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="w-full">
                <h1 className="text-2xl">Dispatch Order</h1>
                <p className="mb-4">Please attach your invoice to continue.</p>
                <div className="grid grid-cols-1">
                  {/* Importer */}
                  {store && (
                    <FileUpload
                      path={`order/${order.id}`}
                      limit={1}
                      accepted={['application/pdf']}
                      ref={fileUploadRef}
                      onRequestClear={() => setFiles([])}
                      onRequestRevert={(id: string) =>
                        setFiles((uFiles) => remove(uFiles, id))
                      }
                      onRequestSave={(id: string) =>
                        setFiles((uFiles) => [...uFiles, id])
                      }
                      defaultFiles={[]}
                    />
                  )}
                </div>
              </div>
              <div className="mt-5 pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="
                  mt-3 w-full inline-flex justify-center
                  border border-transparent
                  px-4 py-2 bg-secondary-500 text-base font-medium text-white
                  hover:bg-secondary-700
                  focus:outline-none
                  disabled:opacity-50 disabled:pointer-events-none
                  sm:ml-3 w-auto"
                  disabled={isDisabled()}
                  onClick={startDispatch}
                >
                  Dispatch Order
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center items-center
                   px-4 py-2 bg-white text-base font-medium text-gray-700
                   hover:text-gray-500
                   focus:outline-none
                   disabled:opacity-50 disabled:pointer-events-none
                   sm:mt-0 w-auto sm:text-sm"
                  disabled={loading}
                  onClick={props.onClose}
                >
                  Go Back
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
