export const enum DBCollection {
  _metadata = '_metadata_',
  users = 'users',
  cart = 'cart',
  orders = 'orders',
  suppliers = 'suppliers',
  metadata = 'metadata',
  products = 'products',
  categories = 'categories',
  hobbies = 'hobbies',
  ages = 'ages',
  genders = 'genders',
  lists = 'lists',
  reviews = 'reviews',
  mail = 'mail',
  stats = 'stats',
  mailTemplates = 'emailTemplates',
  smsTemplates = 'smsTemplates',
  subscriptions = 'subscriptions',
  prices = 'prices',
  notifications = 'notifications',
  checkoutSessions = 'checkout_sessions',
  stockAdjustments = 'stockAdjustments',
  shopifyWebhookRequests = 'shopifyWebhookRequests',
  vouchers = 'vouchers',
  magicTokens = 'magicTokens',
  settings = 'settings',
  charges = 'charges',
  files = 'files',
}

export const enum DBDoc {
  secure = 'secure',
  cart = 'cart',
  total = 'total',
  showcase = 'showcase',
}

export enum Role {
  User = 'user',
  Admin = 'admin',
  Supplier = 'supplier',
}

export enum EOrderStatus {
  ordered = 'Ordered',
  charged = 'Card Charged',
  voucher = 'Voucher',
  processing = 'Processing',
  ready = 'Ready',
  sent = 'Sent',
  delivered = 'Delivered',
}

export enum ETemplateEmail {
  forgotPassword = 'forgotPassword',
  supplierInvite = 'supplierInvite',
  retailInvite = 'retailInvite',
  orderConfirmationGift = 'orderConfirmationGift',
  orderConfirmationSale = 'orderConfirmationSale',
  orderExpired = 'orderExpired',
  selectGift = 'selectGift',
  selectionConfirmation = 'selectionConfirmation',
  giftDelivered = 'giftDelivered',
  notifyGifterOfSelection = 'notifyGifterOfSelection',
  orderDispatched = 'orderDispatched',
  giftVoucher = 'giftVoucher',
  notifySupplierOfOrder = 'notifySupplierOfOrder',
  notifySenderOfDispatch = 'notifySenderOfDispatch',
}

export enum OrderTrackingMethod {
  phone = 'phone',
  email = 'email',
}

export enum ESupplierSubscriptionLevel {
  free = 'free',
  premium = 'premium',
}

export enum ETrackingType {
  view = 'views',
  impression = 'impressions',
  sale = 'sales',
}

export enum EApplicationSource {
  retail = 'retail',
  vendor = 'vendor',
}

export enum EShippingArea {
  north = 'NorthIsland',
  south = 'SouthIsland',
  auckland = 'Auckland',
  wellington = 'Wellington',
  christchurch = 'Christchurch',
  waiheke = 'WaihekeIsland',
}

export const ShopifyLimits = {
  // Maximum length of title (1000 chosen arbitrarily, probably won't be longer than 255 anyway)
  title: 1000,

  // Maximum length of handle
  handle: 255,
};
