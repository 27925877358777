import { OrderProduct, Product } from '@giftery/api-interface';
import { storage } from '@giftery/firebase';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

interface ProductImageProps {
  product: OrderProduct;
  className?: string;
  showCount?: boolean;
}

export const ProductImage: React.FC<ProductImageProps> = ({
  product: rawProduct,
  className,
  showCount = false,
}) => {
  const [product, setProduct] = useState<OrderProduct>(null);
  useEffect(() => {
    if (rawProduct && !product) {
      const getImage = async () => {
        const p = Object.assign(
          {},
          {
            ...rawProduct,
            images: rawProduct.images ? [...rawProduct.images] : [],
          }
        );
        const mainImage = p.images[0];
        if (mainImage && mainImage.indexOf('http') < 0) {
          p.images[0] = await storage
            .ref(`/images/${mainImage}`)
            .getDownloadURL();
        }
        setProduct(p);
      };
      getImage().then();
    }
  }, [rawProduct, product]);

  const image = () => {
    if (!product || !product.images || isEmpty(product.images)) return null;
    return product?.images[0].indexOf('http') > -1 ? (
      <div className="relative">
        <img
          className={className || 'ProductItemImage h-12 w-12'}
          src={product?.images[0]}
          alt={product.name}
        />
      </div>
    ) : null;
  };

  return image();
};
