import React, { useState, useEffect } from 'react';
import { useStore, useStoreSecure } from '../../../hooks';
import { map, isNil, chain, cloneDeep } from 'lodash';
import { PageLoader } from '@giftery/ui/page-loader';
import { sentenceOnCase } from '@giftery/utils';
import { EShippingArea } from '@giftery/enums';
import { ShippingRate, Supplier } from '@giftery/api-interface';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { updateSupplier } from '../../../actions/Supplier';
import toast from 'react-hot-toast';

const ShippingPage = () => {
  const [rates, setRates] = useState<ShippingRate[]>();
  const [isFlatRate, setIsFlatRate] = useState(true);
  const [flatRate, setFlatRate] = useState(0);
  const [ruralSurcharge, setRuralSurcharge] = useState(0);
  const [loading, setLoading] = useState(false);
  const store = useStore();
  const reset = () => {
    setRates(store.shipping.rates || []);
    setIsFlatRate(
      !isNil(store.shipping.isFlatRate) ? store.shipping.isFlatRate : true
    );
    setFlatRate(store.shipping.flatRate || 0);
    setRuralSurcharge(store.shipping.ruralSurcharge || 0);
  };

  useEffect(() => {
    if (!store || rates) return;
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  if (!rates) return <PageLoader />;

  const save = async () => {
    setLoading(true);
    const update: Partial<Supplier> = {
      shipping: {
        ruralSurcharge,
        isFlatRate,
        flatRate,
        rates,
      },
    };
    await toast.promise(updateSupplier(store.id, update, null), {
      loading: `Hang tight, we're saving your settings...`,
      success: `Settings saved successfully!`,
      error: `There was an error updating your settings. Please try again later`,
    });
    setLoading(false);
  };

  const updateShippingType = (value: string, index: number) => {
    const shippingCopy: ShippingRate[] = cloneDeep(rates);
    shippingCopy[index] = {
      ...shippingCopy[index],
      name: value,
    };
    setRates(shippingCopy);
  };

  const updateShippingValue = (
    value: string,
    index: number,
    key: EShippingArea
  ) => {
    const shippingCopy: ShippingRate[] = cloneDeep(rates);
    shippingCopy[index].cost[key].amount = parseFloat(value);
    setRates(shippingCopy);
  };

  const updateFlatRate = (value: string) => {
    setFlatRate(parseFloat(value));
  };

  const updateRuralSurcharge = (value: string) => {
    setRuralSurcharge(parseFloat(value));
  };

  const addShippingOption = () => {
    const shippingCopy = [...rates, { ...cloneDeep(rates[0]), name: '' }];
    setRates(shippingCopy);
  };

  const removeShippingOption = (index: number) => {
    const shippingCopy = cloneDeep(rates);
    shippingCopy.splice(index, 1);
    setRates(shippingCopy);
  };

  const addOptionDisabled = (): boolean => {
    return !rates.every((s) => s.name.trim() !== '');
  };

  const renderRates = () => {
    return (
      <div className="my-2 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-t border-gray-200">
              <div className="p-8 grid grid-cols-3 gap-4">
                {map(rates, (area, index) => (
                  <div key={`area-${index}`}>
                    {
                      <div>
                        <label className="flex items-center justify-between">
                          <h2
                            className="inline-block text-sm font-b
                          old leading-6 text-gray-900"
                          >
                            Name
                          </h2>
                          {index > 0 && (
                            <button
                              className="group text-sm px-2 text-secondary-400 flex items-center hover:text-red-500"
                              onClick={() => removeShippingOption(index)}
                            >
                              <FiTrash2
                                size={12}
                                className="-mt-1 stroke-current text-secondary-400 group-hover:text-red-500"
                              />{' '}
                              Remove
                            </button>
                          )}
                        </label>
                        <input
                          type="text"
                          className="focus:ring-secondary-500 focus:border-secondary-500 block w-full sm:text-sm border-gray-300"
                          placeholder="Product Type, e.g. Large"
                          aria-describedby="price-currency"
                          onFocus={(event) => event.target.select()}
                          value={area.name}
                          onChange={(e) =>
                            updateShippingType(e.target.value || '', index)
                          }
                        />
                      </div>
                    }
                    <div className="bg-grey-50 px-3 py-1 mt-2">
                      {map(
                        chain(area.cost)
                          .map((a, key) => ({ ...a, key }))
                          .sortBy('order')
                          .keyBy('key')
                          .value(),
                        (location, key) => (
                          <div key={key} className="my-4 flex">
                            <div>
                              <label>{sentenceOnCase(key)}</label>
                              <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500 sm:text-sm">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300"
                                  placeholder="0.00"
                                  aria-describedby="price-currency"
                                  min={0.0}
                                  onFocus={(event) => event.target.select()}
                                  value={rates[index].cost[key].amount}
                                  onChange={(e) =>
                                    updateShippingValue(
                                      e.target.value || '',
                                      index,
                                      key as EShippingArea
                                    )
                                  }
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <span
                                    className="text-gray-500 sm:text-sm"
                                    id="price-currency"
                                  >
                                    NZD
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
                {rates.length < 3 && (
                  <button
                    onClick={addShippingOption}
                    className="
                      h-full border-dashed border-2 border-gray-300
                      cursor:pointer text-gray-300 flex flex-col items-center justify-center text-center
                      disabled:opacity-50"
                  >
                    <FiPlus size={48} />
                    <h2>Add a shipping option</h2>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section aria-labelledby="billing-history-heading">
      <div className="bg-white pt-6 shadow sm:overflow-hidden">
        <div className="px-4 sm:px-6">
          <h2
            id="billing-history-heading"
            className="text-xl font-bold leading-6 text-gray-900"
          >
            Shipping
          </h2>
        </div>
        <div className="my-2 grid grid-cols-2">
          {/* Flat Checkbox */}
          <div className="relative flex items-start px-6 py-2">
            <div className="flex items-center h-5">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                checked={isFlatRate}
                onChange={() => setIsFlatRate(!isFlatRate)}
                className="h-4 w-4 text-secondary-600 border-gray-300"
              />
            </div>
            <div className="ml-3">
              <label htmlFor="comments" className="font-medium">
                Flat Rate Shipping
              </label>
              <p id="comments-description" className="text-gray-500  text-sm">
                Use a single rate for all shipping from your store.
              </p>
            </div>
          </div>
          {/* Flat Rate */}
          <div className="flex flex-col px-6 justify-start items-start py-2">
            <div className="w-full">
              <label>Flat Rate</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 disabled:bg-gray-100 disabled:pointer-events-none"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                  min={0.0}
                  onFocus={(event) => event.target.select()}
                  value={flatRate}
                  disabled={!isFlatRate}
                  onChange={(e) => updateFlatRate(e.target.value || '')}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    NZD
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="mt-2">
                <label htmlFor="comments" className="font-medium">
                  Rural Charge
                </label>
                <p id="comments-description" className="text-gray-500 text-sm">
                  Will be added to the shipping cost for rural areas.
                </p>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 disabled:bg-gray-100 disabled:pointer-events-none"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                  min={0.0}
                  onFocus={(event) => event.target.select()}
                  value={ruralSurcharge}
                  onChange={(e) => updateRuralSurcharge(e.target.value || '')}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    NZD
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Rates */}
        {!isFlatRate && renderRates()}
        <div className="py-4 px-4 flex justify-end sm:px-6">
          <button
            type="button"
            onClick={reset}
            disabled={loading}
            className="ml-5 border-secondary-500 border-2
             py-2 px-4 inline-flex justify-center text-sm font-medium text-secondary-500
             hover:bg-secondary-800 focus:outline-none"
          >
            Reset
          </button>
          <button
            type="button"
            disabled={loading || addOptionDisabled()}
            onClick={save}
            className="
            ml-2 bg-secondary-500 text border border-transparent
            py-2 px-4 inline-flex justify-center text-sm font-medium text-white
            hover:bg-secondary-800 focus:outline-none disabled:opacity-50"
          >
            {loading ? 'Please Wait...' : 'Save'}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ShippingPage;
