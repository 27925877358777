import { AppMetadata } from '@giftery/api-interface';
import { useSelector } from 'react-redux';
import { SupplierRootState } from '../store/reducers';
import packageJson from '../../../../package.json';

export function useAppMetadata(): [AppMetadata, string] {
  const metadata: AppMetadata = useSelector(
    (state: SupplierRootState) =>
      (state.firestore.data._metadata_ as unknown as AppMetadata) || null
  );
  const currentVersion = packageJson.version;
  return [metadata, currentVersion];
}
