import { DBDoc } from '@giftery/enums';
import { RootState } from '@giftery/ui/interfaces';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useMe, useStore } from '../../../hooks';
import { updateSupplier } from '../../../actions/Supplier';
import toast from 'react-hot-toast';
import { SupplierSecure } from '@giftery/api-interface';
import { uniqueId } from 'lodash-es';
import {
  connectShopify,
  disconnectShopify,
  syncProductsFromShopify,
} from '../../../actions/Shopify';
import { useHistory } from 'react-router-dom';
import { FiDownloadCloud, FiTrash2 } from 'react-icons/fi';
import { FaShopify } from 'react-icons/fa';
import swal from 'sweetalert2';
import psl from 'psl';
import { extractHostname } from '@giftery/utils';
import { colors } from '@giftery/theme';
import { Helmet } from 'react-helmet';

const IntegrationsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [shopifyStoreUrl, setShopifyStoreUrl] = useState<string>('');
  const me = useMe();
  const history = useHistory();
  const store = useStore();
  useFirestoreConnect([
    {
      collection: `suppliers/${me?.supplier}/metadata`,
      doc: DBDoc.secure,
      storeAs: 'supplierSecure',
    },
  ]);
  const storeSecure = useSelector(
    (state: RootState) => state.firestore.ordered.supplierSecure?.[0] ?? null
  );

  const reset = async () => {
    if (!store || !storeSecure) return;
    setShopifyStoreUrl(storeSecure.shopify?.storeUrl || '');
    setLoaded(true);
  };

  const save = async () => {
    setLoading(true);
    try {
      const storeUrlRegex = /([a-z0-9]+[.])*myshopify[.]com/;
      const matches = shopifyStoreUrl.match(storeUrlRegex);
      if (!matches)
        return toast.error(
          'Store URL is not valid, please use your shopify store URL,e.g. my-store.myshopify.com'
        );
      const parsed = psl.parse(extractHostname(shopifyStoreUrl));
      const subdomain = parsed.input;
      const secureUpdate: Partial<SupplierSecure> = {
        shopify: {
          webhooks: null,
          accessToken: null,
          scope: null,
          storeUrl: subdomain,
        },
      };
      await updateSupplier(me.supplier, {}, secureUpdate);
      toast.success(`Settings saved successfully!`);
      window.location.assign(
        `https://${subdomain}/admin/oauth/authorize?client_id=83856e7978dec583eaae987eec916217&scope=read_products,read_orders,read_inventory,write_inventory,write_products&redirect_uri=${
          window.location.href
        }&state=${uniqueId(store.id)}&grant_options[]=value`
      );
    } catch (err) {
      toast.error(
        err?.response?.message ||
          'An unknown error occurred. Please try again later.'
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loaded && isLoaded(store) && isLoaded(storeSecure)) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, storeSecure]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    const code = params.get('code');
    const hmac = params.get('hmac');
    if (!code || !hmac) return;
    connectShopify(hmac, code)
      .then(() => history.push('/settings/integrations'))
      .catch((err) => {
        toast.error(err.message);
        history.push(`/settings/integrations`);
      });
  }, []);

  const renderNotConnected = () => {
    return (
      <>
        <div className="mt-3">
          <label>
            <div className="flex flex-row items-center text-xl">
              <FaShopify className="mr-2" size={24} />
              Shopify
            </div>
            <small className="sub-label text-muted">
              Enter your Shopify store URL (e.g. https://my-store.myshopify.com)
              to connect it to the Giftery.
            </small>
          </label>
          <input
            type="text"
            name="company"
            id="company"
            autoComplete="organization"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
            value={shopifyStoreUrl}
            onChange={(e) => setShopifyStoreUrl(e.target.value || '')}
          />
        </div>
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
          <button
            type="button"
            onClick={save}
            className="ml-5 bg-secondary-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-secondary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-300"
          >
            {loading ? 'Please Wait...' : 'Save'}
          </button>
        </div>
      </>
    );
  };

  const importStore = async () => {
    const result = await swal.fire({
      title: `Are you sure?`,
      text: `You are about to import all products from: ${shopifyStoreUrl}`,
      showCancelButton: true,
      icon: 'question',
      cancelButtonText: 'No, Go Back',
      confirmButtonText: `Yes, Sync This Store`,
      confirmButtonColor: colors.secondary[500],
      cancelButtonColor: colors.grey[100],
    });
    if (result.isConfirmed) {
      toast(
        `Syncing your products from Shopify in the background. They should start appearing in your store within a few minutes.`,
        { icon: 'ℹ️' }
      );
      await syncProductsFromShopify();
    }
  };

  const unlinkStore = async () => {
    const result = await swal.fire({
      title: `Are you sure?`,
      text: `This action cannot be undone! Please confirm you'd like to unlink the following shopify store: ${shopifyStoreUrl}`,
      icon: 'error',
      showCancelButton: true,
      cancelButtonText: 'No, Go Back',
      confirmButtonText: `Yes, Unlink This Store`,
      confirmButtonColor: colors.danger,
      cancelButtonColor: colors.grey[100],
    });
    // TODO: Create unlink backend method and actually delete the webhooks
    if (!result.isConfirmed) {
      const promise = disconnectShopify();
      toast.promise(promise, {
        loading: 'Unlinking your shopify store, please wait...',
        success: `Successfully disconnected Shopify store`,
        error: `We could not unlink your Shopify store at this time. Please try again later.`,
      });
    }
  };

  const renderConnected = () => {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md mt-3">
        <ul className="divide-y divide-gray-200">
          <li>
            <div className="block">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    <a href={shopifyStoreUrl}>{shopifyStoreUrl}</a>
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      Connected
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <div className="flex items-center text-sm text-gray-500">
                      <FaShopify
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Shopify
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <button
                      type="button"
                      className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 mr-5"
                      onClick={importStore}
                    >
                      <FiDownloadCloud
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <p className="my-0">Import Products</p>
                    </button>
                    <button
                      type="button"
                      className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
                      onClick={unlinkStore}
                    >
                      <FiTrash2
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <p className="my-0">Unlink Store</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <form
          className="divide-y divide-gray-200 lg:col-span-12"
          action="#"
          method="POST"
        >
          {/* Profile section */}
          <div className="py-6 px-4 sm:p-6 lg:pb-8">
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Integrations
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Connect your Giftery account to other services
              </p>
            </div>
            {!storeSecure?.shopify.accessToken
              ? renderNotConnected()
              : renderConnected()}
            <div className="mt-6 flex flex-col lg:flex-row">
              <div className="flex-grow space-y-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IntegrationsPage;
