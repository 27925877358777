import React from 'react';
import { Bar } from '@reactchartjs/react-chart.js';
import './SalesHistory.scss';
import { colors } from '@giftery/theme';
import { useOrders } from '../../../../hooks';
import { Order, WithId } from '@giftery/api-interface';
import { format } from 'date-fns';
import { map } from 'lodash';
import numeral from 'numeral';

type OrderCountAggregates = Record<string, { orders: number; total: number }>;
type OrderAggregates = Record<string, WithId<Order>[]>;

const getLastTwelveMonths = () => {
  const date = new Date();
  const months = [],
    monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  for (let i = 0; i < 12; i++) {
    const d = new Date(date.getFullYear(), date.getMonth() - i, 1);
    const month = monthNames[d.getMonth()];
    months.push(month);
  }
  return months.reverse();
};

const processOrders = (
  raw: WithId<Order>[]
): [OrderAggregates, OrderCountAggregates, number] => {
  const orders: OrderAggregates = {};
  const monthlyTotals: OrderCountAggregates = {};
  let total = 0;
  raw.forEach((order) => {
    const statsDateId = format(
      (order.audit.createdAt as FirebaseFirestore.Timestamp).toDate(),
      'MMMM'
    );
    if (!orders[statsDateId]) orders[statsDateId] = [];
    orders[statsDateId].push(order);
    const orderTotal = map(order.products, (p) => p.price).reduce(
      (p, c) => p + c,
      0
    );
    if (!monthlyTotals[statsDateId])
      monthlyTotals[statsDateId] = { orders: 0, total: 0 };
    monthlyTotals[statsDateId].orders += 1;
    monthlyTotals[statsDateId].total += orderTotal;
    total += orderTotal;
  });
  return [orders, monthlyTotals, total];
};

interface SalesHistoryProps {
  className?: string;
}

const SalesHistory: React.FC<SalesHistoryProps> = ({ className }) => {
  const rawOrders = useOrders();
  const [orders, monthlyTotals, total] = processOrders(rawOrders);
  const orderValues: number[] = new Array(
    12 - Object.keys(monthlyTotals).length
  ).fill(0);
  const orderCounts: number[] = new Array(
    12 - Object.keys(monthlyTotals).length
  ).fill(0);

  map(monthlyTotals, (t) => {
    orderValues.push(t.total);
    orderCounts.push(t.orders);
  });
  const data = {
    labels: getLastTwelveMonths(),
    datasets: [
      {
        label: 'Orders',
        data: orderCounts,
        backgroundColor: colors.grey[100],
      },
      {
        label: 'Revenue/month',
        data: orderValues,
        backgroundColor: colors.secondary[500],
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <div className={`SalesHistoryContainer h-full	 ${className || ''}`}>
      <div className="SalesHistory h-full">
        <div className="SalesSummary">
          <div className="Amount">
            <div className="Label font-sans mb-1">Total Sales</div>
            <div className="Value font-serif font-bold">
              {numeral(total).format('$0,0.00')}
            </div>
          </div>
          <div className="Period font-serif font-bold">Last 12 Months</div>
        </div>
        <Bar type="bar" data={data} options={options} />
      </div>
    </div>
  );
};

export default SalesHistory;
