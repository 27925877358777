import { map } from 'lodash';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useStore } from './UseStore';
import { useStoreSecure } from './UseStoreSecure';
import { startOfDay } from 'date-fns';
import { Order, WithId } from '@giftery/api-interface';

const getStartOfLastFinancialMonth = () => {
  const d = new Date();
  d.setDate(21);
  d.setMonth(d.getMonth() - 1);
  return startOfDay(d);
};

const getNextPayoutDate = () => {
  const d = new Date();
  const date = d.getDate();
  if (date >= 20) {
    d.setMonth(d.getMonth() + 1);
  }
  d.setDate(20);
  return startOfDay(d);
};

const getOrderTotal = (raw: WithId<Order>[], supplierId: string): number => {
  let total = 0;
  if (!raw) return total;
  raw.forEach((order) => {
    const orderTotal = map(order.products, (p) =>
      p.storeId === supplierId ? p.price : 0
    ).reduce((p, c) => p + c, 0);
    total += orderTotal;
  });
  return total;
};

export function usePayout(): [number, Date] {
  const store = useStore();
  const storeSecure = useStoreSecure();
  const commission = 0.15; //storeSecure?.commission?.base || null;
  const startOfFinancialMonth = getStartOfLastFinancialMonth();
  useFirestoreConnect([
    {
      collection: DBCollection.orders,
      where: [
        ['stores', 'array-contains', store.id || 'USER_NOT_LOADED'],
        ['audit.createdAt', '>=', startOfFinancialMonth],
        ['status.dispatched', '==', true],
      ],
      storeAs: 'dispatchedOrdersLastMonth',
    },
  ]);
  const { orders } = useSelector((state: SupplierRootState) => {
    return {
      orders: state.firestore.ordered.dispatchedOrdersLastMonth,
    };
  });
  if (!commission) return [null, null];
  const orderTotal = getOrderTotal(orders, store?.id || 'USER_NOT_LOADED');
  const nextPayoutDate = getNextPayoutDate();
  const lessCommission = orderTotal - orderTotal * commission;
  const nextPayoutAmount = parseFloat(lessCommission.toFixed(2));
  return [nextPayoutAmount, nextPayoutDate];
}
