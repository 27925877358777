import React, { useState } from 'react';
import { useWizardStep } from 'react-wizard-primitive';
import InputMask from 'react-input-mask';
import './PaymentStep.scss';
import { Supplier, WithId } from '@giftery/api-interface';
import { LoadingButton } from '@giftery/ui/loading-button';
import { DBCollection, DBDoc } from '@giftery/enums';
import { stripSpaces } from '@giftery/utils';
import { Redirect } from 'react-router';
import { useFirestore } from 'react-redux-firebase';
import { Gifter } from '@giftery/ui/gifter';
import { Logo } from '@giftery/ui/logo';

interface PaymentStepProps {
  supplier: WithId<Supplier>;
}

export const PaymentStep: React.FC<PaymentStepProps> = ({ supplier }) => {
  const { isActive } = useWizardStep();
  const firestore = useFirestore();
  const [bankAccount, setBankAccount] = useState<string>('');
  const [gstNumber, setGstNumber] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  if (supplier && supplier.status.active) return <Redirect to="/" />;

  if (!isActive) return null;

  const submit = async () => {
    setLoading(true);
    const update = {
      bankAccount: stripSpaces(bankAccount),
      gstNumber: stripSpaces(gstNumber),
    };
    const supplierDoc = firestore
      .collection(DBCollection.suppliers)
      .doc(supplier.id);

    await supplierDoc
      .collection(DBCollection.metadata)
      .doc(DBDoc.secure)
      .update(update);
    await supplierDoc.update({
      'status.active': true,
    });
    setLoading(false);
  };
  return (
    <div className="WizardStep text-secondary-500">
      <Gifter className="h-36 w-36 stroke-current fill-current text-secondary-600" />
      <Logo
        className="h-12 stroke-current fill-current text-secondary-600"
        color="secondary-600"
      />
      <div className="py-3">
        <h1 className="text-3xl font-serif">Getting Started</h1>
        <p className="text-xl font-sans">
          Where would you like to be paid out to?
        </p>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <label>Bank Account</label>
          <small className="sub-label text-muted">
            Your NZ Bank Account Number
          </small>
          <InputMask
            mask="99 - 9999 - 9999999 - 999"
            value={bankAccount}
            alwaysShowMask={true}
            onChange={(e) => setBankAccount(e.target.value)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type="text"
                className="w-full border-2 border-secondary-500 text-secondary-500 py-3 px-6 focus:border-secondary-800"
              />
            )}
          </InputMask>
        </div>
        <div>
          <label>GST Number</label>
          <small className="sub-label text-muted">
            Leave blank if you are not GST registered
          </small>
          <InputMask
            mask="999 - 999 - 999"
            value={gstNumber}
            alwaysShowMask={true}
            onChange={(e) => setGstNumber(e.target.value)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type="text"
                className="w-full border-2 border-secondary-500 text-secondary-500 py-3 px-6 focus:border-secondary-800"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="footer">
        <LoadingButton
          loading={loading}
          feedback="Sit Tight..."
          className="bg-secondary-600
          text-white text-xl font-normal
          py-2 px-3 mt-3
          hover:bg-secondary-700"
          onClick={submit}
        >
          Continue
        </LoadingButton>
      </div>
    </div>
  );
};
