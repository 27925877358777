import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ProductList from './ProductList/ProductList';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { FiPlus, FiUploadCloud } from 'react-icons/fi';
import NewProduct from './NewProduct/NewProduct';
import { Drawer } from '@giftery/ui/drawer';
import ProductSearch from '../Dashboard/components/ProductSearch/ProductSearch';
import { ProductImportModal } from './ImportModal/ProductImportModal';
import { useProducts } from '../../hooks';
import { PageLoader } from '@giftery/ui/page-loader';

const ProductsPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string>(undefined);
  const [previousCursors, setPreviousCursors] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState<string>(undefined);
  const [importOpen, setImportOpen] = useState(false);
  const [products, loading] = useProducts(cursor, orderBy);

  if (loading) return <PageLoader />;

  const onPage = (cursor) => {
    if (cursor) {
      const p = [...previousCursors];
      p.push(cursor);
      setPreviousCursors(p);
      setCursor(cursor);
    } else {
      setCursor(previousCursors[previousCursors.length - 2]);
      const p = previousCursors.slice(0, -2);
      setPreviousCursors(p);
    }
  };

  return (
    <>
      <Helmet>
        <title>The Giftery | Products</title>
      </Helmet>
      <div>
        <ProductImportModal
          open={importOpen}
          onClose={() => setImportOpen(false)}
        />

        {!isEmpty(products) ? (
          <>
            <div className="flex flex-col p-6 pb-0 sm:px-4 lg:px-6 mb-3">
              <div className="flex">
                <div className="flex-grow mr-3">
                  <ProductSearch />
                </div>
                <div className="flex-none">
                  <button
                    className="
                      h-full flex items-center justify-center
                      py-2 px-4
                      text-secondary-500 text-lg bg-secondary-50
                      "
                    onClick={() => setImportOpen(true)}
                  >
                    <FiUploadCloud className="stroke-current mr-2" /> Import
                    Products
                  </button>
                </div>
              </div>
            </div>
            <ProductList
              products={products}
              onPage={onPage}
              size={10}
              page={previousCursors.length + 1}
            />
          </>
        ) : (
          <div className="text-center text-grey-600 flex-col h-screen flex items-center justify-center">
            <h2 className="font-normal text-center">
              You currently have no products. Try adding one by clicking the
            </h2>
            <FiPlus size={48} />
            <h2 className="font-normal text-center">
              button down the bottom right.
            </h2>
            <button
              className="
                      flex items-center justify-center
                      py-2 px-4
                      text-secondary-500 text-lg bg-secondary-50
                      "
              onClick={() => setImportOpen(true)}
            >
              <FiUploadCloud className="stroke-current mr-2" /> Import Products
            </button>
          </div>
        )}

        {!sidebarOpen ? (
          <Fab
            icon={<FiPlus />}
            event="click"
            alwaysShowTitle={false}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
        ) : null}
      </div>
      <Drawer
        open={sidebarOpen}
        width="25vw"
        close={false}
        overlay={true}
        closeOnOverlay={true}
        onClose={() => setSidebarOpen(!sidebarOpen)}
        placement="right"
      >
        <NewProduct close={() => setSidebarOpen(false)} />
      </Drawer>
    </>
  );
};

export default ProductsPage;
