import React, { forwardRef, useImperativeHandle } from 'react';
import shortid from 'shortid';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import firebase from 'firebase/app';
import { storage } from '@giftery/firebase';

// And import the necessary css
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import './ui-image-upload.scss';

// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginImageExifOrientation);
// Register image resize plugin
registerPlugin(FilePondPluginImageResize);
// Register the ImageTransform plugin
registerPlugin(FilePondPluginImageTransform);
// Register file validation
registerPlugin(FilePondPluginFileValidateType);

/* eslint-disable-next-line */
export interface ImageUploadProps {
  onRequestSave: (id: string) => void;
  onRequestClear: () => void;
  onRequestRevert: (id: string) => void;
  label?: string;
  limit?: number;
  defaultFiles: File[];
}

export interface ImageUploadMethods {
  reset: () => void;
  remove: (index: number) => void;
}

export const ImageUpload = forwardRef<ImageUploadMethods, ImageUploadProps>(
  (props, ref) => {
    const {
      onRequestSave,
      onRequestClear,
      onRequestRevert,
      label = 'Drag & drop your images here or',
      limit = 5,
      defaultFiles,
    } = props;
    // use a useState hook to maintain our files collection
    const [files, setFiles] = React.useState<File[]>(defaultFiles);

    const server = {
      // this uploads the image using firebase
      process: (fieldName, file, metadata, load, error, progress, abort) => {
        // create a unique id for the file
        const id = shortid.generate();

        // upload the image to firebase
        const task = storage
          .ref()
          .child('images/' + id)
          .put(file, {
            contentType: 'image/jpeg',
          });

        // monitor the task to provide updates to FilePond
        task.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snap) => {
            // provide progress updates
            progress(true, snap.bytesTransferred, snap.totalBytes);
          },
          (err) => {
            // provide errors
            error(err.message);
          },
          () => {
            // the file has been uploaded
            load(id);
            onRequestSave(id);
          }
        );
      },

      revert: (uniqueFieldId, load, error) => {
        onRequestRevert(uniqueFieldId);
      },

      // this loads an already uploaded image to firebase
      load: (source, load, error, progress, abort) => {
        // reset our progress
        progress(true, 0, 1024);

        // fetch the download URL from firebase
        storage
          .ref()
          .child('images/' + source)
          .getDownloadURL()
          .then((url) => {
            // fetch the actual image using the download URL
            // and provide the blob to FilePond using the load callback
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function (event) {
              const blob = xhr.response;
              load(blob);
            };
            xhr.open('GET', url);
            xhr.send();
          })
          .catch((err) => {
            error(err.message);
            abort();
          });
      },
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      reset() {
        setFiles(defaultFiles);
      },
      remove(index) {
        if (!files[index]) return;
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      },
    }));

    return (
      <FilePond
        labelIdle={`${label} <span class="filepond--label-action">Browse Your Computer</span>`}
        files={files}
        allowMultiple={true}
        allowImageResize={true}
        allowImageTransform={true}
        imageTransformCanvasBackgroundColor={'#fff'}
        imageTransformOutputMimeType="image/jpeg"
        acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png']}
        maxFiles={limit}
        onupdatefiles={(fileItems) => {
          if (fileItems.length === 0) {
            onRequestClear();
          }
          setFiles(fileItems.map((fileItem) => fileItem.file) as File[]);
        }}
        server={server} // todo: add custom server functionality using firebase
      />
    );
  }
);
