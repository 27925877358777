import { DBDoc } from '@giftery/enums';
import { RootState } from '@giftery/ui/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useMe, useStore } from '../../../hooks';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { Place } from '@googlemaps/google-maps-services-js';
import { find, includes } from 'lodash';
import InputMask from 'react-input-mask';
import { ImageUpload, ImageUploadMethods } from '@giftery/ui/image-upload';
import { storage } from '@giftery/firebase';
import { stripSpaces } from '@giftery/utils';
import placeholder from '../../../images/store-placeholder.png';
import { updateSupplier } from '../../../actions/Supplier';
import toast from 'react-hot-toast';
import { Supplier } from '@giftery/api-interface';

const ProfilePage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editingAddress, setEditingAddress] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tradingName, setTradingName] = useState<string>('');
  const [logo, setLogo] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [bankAccount, setBankAccount] = useState<string>('');
  const [gstNumber, setGstNumber] = useState<string>('');

  const [googlePlace, setGooglePlace] = useState<{
    label: string;
    value: Place;
  }>();

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const imageUploadRef = useRef<ImageUploadMethods>();

  const me = useMe();
  const store = useStore();
  useFirestoreConnect([
    {
      collection: `suppliers/${me?.supplier}/metadata`,
      doc: DBDoc.secure,
      storeAs: 'supplierSecure',
    },
  ]);
  const storeSecure = useSelector(
    (state: RootState) => state.firestore.ordered.supplierSecure?.[0] ?? null
  );

  const getLogo = async () => {
    if (store.logo) {
      const imageUrl = await storage
        .ref(`/images/${store.logo}`)
        .getDownloadURL();
      setLogo(imageUrl);
    }
  };

  const reset = async () => {
    if (!store || !storeSecure) return;
    await getLogo();
    setTradingName(store.tradingName);
    setAddress(store.address);
    setCity(store.city);
    setPostcode(store.postcode);
    setRegion(store.region);
    setBankAccount(storeSecure.bankAccount);
    setGstNumber(storeSecure.gstNumber);
    imageUploadRef?.current?.reset();
    setLoaded(true);
  };

  const save = async () => {
    setLoading(true);
    const update: Partial<Supplier> = {
      tradingName,
      address,
      city,
      postcode,
      region,
      logo: logo.indexOf('https:') > -1 ? store.logo : logo,
    };
    const secureUpdate = {
      bankAccount: bankAccount ? stripSpaces(bankAccount) : null,
      gstNumber: gstNumber ? stripSpaces(gstNumber) : null,
    };
    await toast.promise(updateSupplier(me.supplier, update, secureUpdate), {
      loading: `Hang tight, we're saving your settings...`,
      success: `Settings saved successfully!`,
      error: `There was an error updating your settings. Please try again later`,
    });
    setLoading(false);
  };

  const findAddressComponent = (
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string | string[]
  ) => {
    return find(addressComponents, (component) =>
      includes(component.types, type)
    );
  };

  const cancelEditingAddress = () => {
    setEditingAddress(false);
    setAddress(store.address);
    setCity(store.city);
    setPostcode(store.postcode);
    setRegion(store.region);
  };

  useEffect(() => {
    if (googlePlace && googlePlace.value.place_id) {
      geocodeByPlaceId(googlePlace.value.place_id)
        .then((results) => {
          const addressComponents = results[0]?.address_components;
          if (!addressComponents) return;
          // Extract address details from results
          // 1. Get Address
          const streetNumber = findAddressComponent(
            addressComponents,
            'street_number'
          );
          const streetName = findAddressComponent(addressComponents, 'route');
          if (streetName && streetNumber) {
            setAddress(`${streetNumber.long_name} ${streetName.long_name}`);
          } else {
            setAddress(null);
          }
          // 2. Get city
          const city = findAddressComponent(addressComponents, 'locality');
          setCity(city?.long_name ?? null);
          // 3. Get postcode
          const postcode = findAddressComponent(
            addressComponents,
            'postal_code'
          );
          setPostcode(postcode?.long_name ?? null);
          // 4. Get Region
          const region = findAddressComponent(
            addressComponents,
            'administrative_area_level_1'
          );
          setRegion(region?.long_name ?? null);
        })
        .catch((error) => console.error(error));
    }
  }, [googlePlace]);

  useEffect(() => {
    if (!loaded && isLoaded(store) && isLoaded(storeSecure)) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, storeSecure]);

  return (
    <div className="overflow-hidden">
      <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <form
          className="divide-y divide-gray-200 lg:col-span-12"
          action="#"
          method="POST"
        >
          {/* Profile section */}
          <div className="py-6 px-4 pt-0 pl-0 sm:p-6 lg:pb-8">
            <div className="bg-white shadow= py-6 px-4 ">
              <div className="mt-2 flex flex-col lg:flex-row">
                <div className="flex-grow space-y-6">
                  <div>
                    <div>
                      <label>
                        <h2 className="text-xl font-bold leading-6 text-gray-900">
                          Trading Name
                        </h2>
                        <small className="sub-label text-muted">
                          Your Business' Name
                        </small>
                      </label>
                      <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="organization"
                        className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                        value={tradingName}
                        onChange={(e) => setTradingName(e.target.value || '')}
                      />
                    </div>
                    <div>
                      <div className="flex row justify-between py-3">
                        <div className="flex-shrink mx-3">
                          <img
                            src={logo || placeholder}
                            alt={tradingName || 'logo'}
                            className="max-h-20 max-w-20 rounded-full"
                          />
                        </div>
                        <div className="flex-1 pr-3">
                          <ImageUpload
                            ref={imageUploadRef}
                            onRequestClear={() => setLogo(null)}
                            limit={1}
                            label="Drag and drop your logo here or "
                            onRequestRevert={() => setLogo(null)}
                            onRequestSave={(id: string) => {
                              setLogo(id);
                            }}
                            defaultFiles={[]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-12 gap-6">
              <div className="col-span-12">
                {/* Address */}
                <div className="bg-white shadow">
                  <div className="px-4 py-4 sm:p-6">
                    <h2 className="text-xl font-bold leading-6 text-gray-900">
                      Address
                    </h2>
                    <div className="mt-2">
                      <div className="bg-gray-50 px-6 py-2 flex items-start justify-between">
                        <div className="flex items-center">
                          <div className="my-3 ml-2">
                            <div className="text-sm font-medium text-gray-900">
                              <h3>{address}</h3>
                            </div>
                            <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                              <div>{region}</div>
                            </div>
                            <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                              <div>{city}</div>
                              <span
                                className="hidden sm:mx-2 sm:inline"
                                aria-hidden="true"
                              >
                                &middot;
                              </span>
                              <div>
                                <strong>{postcode}</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                          {editingAddress ? (
                            <button
                              type="button"
                              onClick={() => cancelEditingAddress()}
                              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            >
                              Cancel
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => setEditingAddress(true)}
                              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                      {editingAddress && (
                        <div className="mt-2">
                          <GooglePlacesAutocomplete
                            apiOptions={{ language: 'en', region: 'nz' }}
                            apiKey="AIzaSyBOt_oMTA05lsT5SgmOFs27iXokXZJAKJo"
                            selectProps={{
                              googlePlace,
                              onChange: setGooglePlace,
                              placeholder: 'Search for an address',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* End Address */}
              </div>
              <div className="bg-white shadow sm:rounded-lg py-6 px-4 col-span-12 grid grid-cols-2 gap-4">
                <div>
                  <label>
                    <h2 className="text-xl font-bold leading-6 text-gray-900">
                      Bank Account
                    </h2>
                    <small className="sub-label text-muted">
                      The account you wish to be paid to
                    </small>
                  </label>
                  <InputMask
                    mask="99 - 9999 - 9999999 - 999"
                    value={bankAccount}
                    alwaysShowMask={true}
                    onChange={(e) => {
                      setBankAccount(e.target.value);
                    }}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                      />
                    )}
                  </InputMask>
                </div>
                <div>
                  <label>
                    <h2 className="text-xl font-bold leading-6 text-gray-900">
                      GST Number
                    </h2>
                    <small className="sub-label text-muted">
                      Leave blank if you are not GST registered
                    </small>
                  </label>

                  <InputMask
                    mask="999 - 999 - 999"
                    value={gstNumber}
                    alwaysShowMask={true}
                    onChange={(e) => setGstNumber(e.target.value)}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                      />
                    )}
                  </InputMask>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="py-4 px-4 flex justify-end sm:px-6">
        <button
          type="button"
          onClick={reset}
          disabled={loading}
          className="ml-5 border-secondary-500 border-2
             py-2 px-4 inline-flex justify-center text-sm font-medium text-secondary-500
             hover:bg-secondary-800 focus:outline-none"
        >
          Reset
        </button>
        <button
          type="button"
          disabled={loading}
          onClick={save}
          className="
          ml-2 bg-secondary-500 text border border-transparent
            py-2 px-4 inline-flex justify-center text-sm font-medium text-white
            hover:bg-secondary-800 focus:outline-none disabled:opacity-50"
        >
          {loading ? 'Please Wait...' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default ProfilePage;
