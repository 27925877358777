import { find, sum } from 'lodash';
import {
  BillingAddress,
  CartProduct,
  Order,
  OrderProduct,
  ShoppingCart,
  WithId,
} from '@giftery/api-interface';
import postcodeData from './postcodes.json';

export const wrapInPromise = async <T extends unknown>(
  fn: (...args) => T
): Promise<T> => {
  return new Promise((resolve, reject) => {
    try {
      const result = fn();
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });
};

export const sleep = (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const stripSpaces = (str: string): string => {
  return str.replace(/\s/g, '');
};

export const makeIdSafe = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/\s/gi, '_');
};

export const makeSkuSafe = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[^\w\s-]/gi, '')
    .replace(/\s/gi, '-');
};

export const dotReference = (obj: unknown, str: string): unknown => {
  return str.split('.').reduce((o, i) => o[i], obj);
};

export const getRelation = <T>(
  obj: Record<string, any>[],
  id: string,
  field: string
): string => {
  const found = find(obj, (item: WithId<T>) => item.id === id);
  if (found) return found[field];
  return '';
};

// This function handles arrays and objects
export const eachRecursive = <T>(
  obj: Record<string, unknown>,
  predicate: (item: unknown, key: string) => unknown
) => {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      const predicatedChild = predicate(obj[k], k);
      if (
        typeof obj[k] === 'object' &&
        !(obj[k] instanceof Date) &&
        obj[k] !== null
      ) {
        obj[k] = eachRecursive(
          predicatedChild as Record<string, unknown>,
          predicate
        );
      }
      obj[k] = predicatedChild;
    }
  }
  return obj as T;
};

export const getFirestoreData = <T extends Record<string, unknown>>(
  data: T
) => {
  const dated = eachRecursive<T>(data, (child) => {
    if (Object.prototype.hasOwnProperty.call(child, 'toDate')) {
      return (child as any).toDate();
    }
    return child;
  });
  return dated as T;
};

export const isProduction = (): boolean => {
  return process.env.NODE_ENV === 'production';
};

// eslint-disable-next-line
export const emailValidationRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const isValidEmail = (email: string): boolean => {
  return emailValidationRegex.test(email);
};

export const getFirstSentence = (text: string): string => {
  const regex = /^(.*?)[.?!]\s/g;
  const matches = text.match(regex);
  if (!matches) return text;
  return matches[0];
};

export const extractHostname = (url: string): string => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const validURL = (str: string): boolean => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const truncateWithEllipsis = (str: string, length: number): string => {
  return str?.length > length ? str.substring(0, length - 1) + '…' : str;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const recursivelyCombine = ([
  head,
  ...[headTail, ...tailTail]
]: string[][]): string[] => {
  if (!headTail) return head;

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => `${h}/${x}`));
  }, []);

  return recursivelyCombine([combined, ...tailTail]);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const splitOnCase = (str: string) => {
  if (!str) return null;
  return str.match(/[A-Z][a-z]+/g) || [str];
};

export const sentenceOnCase = (str: string) => {
  if (!str) return str;
  const split = splitOnCase(str);
  if (!split) return str;
  return split.join(' ');
};

export const getPostcodeMetaData = (
  postcode: string
): { allocation: string; region: string; island: string } => {
  const firstTwoDigits = postcode.slice(0, 2);
  const lookup = postcodeData[firstTwoDigits];
  return lookup || null;
};

export const calculateProductShipping = (
  product: CartProduct,
  shippingAddress?: BillingAddress
): number => {
  const isFlatRate = product.shipping?.supplier?.isFlatRate ?? null;
  const flatRate = product.shipping?.supplier?.flatRate ?? null;
  const ruralSurcharge = product.shipping?.supplier?.ruralSurcharge ?? null;
  // 1. If there is a product override, use that
  if (product.shipping?.product?.override) {
    return product.shipping?.product?.override;
  }
  // 2. If it's flat rated, return the flat rate
  if (isFlatRate) {
    if (!shippingAddress) return flatRate;
    if (shippingAddress && !shippingAddress.address?.isRural) {
      return flatRate;
    }
    if (shippingAddress && shippingAddress.address?.isRural) {
      return flatRate + ruralSurcharge;
    }
    return flatRate;
  }
  // 3. Try to fall back to the region rate
  const type = product.shipping?.product?.type ?? null;
  if (shippingAddress) {
    // 1. Look up by city

    const cityRate =
      product?.shipping?.supplier?.rates?.[type || 0]?.cost?.[
        shippingAddress.address.city
      ]?.amount ?? null;
    if (cityRate !== null) return cityRate;
    // 2. Look up by island
    const region = shippingAddress.address.postcode
      ? getPostcodeMetaData(shippingAddress.address.postcode)
      : null;
    if (!region) return flatRate;
    const island = region ? region.island.replace(' ', '') : null;
    const islandRate =
      product?.shipping?.supplier?.rates?.[type || 0]?.cost?.[island]?.amount ??
      null;
    if (islandRate) return islandRate;
    return flatRate;
  } else {
    // No address known, return most expensive option
    const values = Object.values(
      product.shipping?.supplier?.rates?.[type || 0]?.cost || {}
    );
    let total = 0;
    values.map((v) => {
      if (v.amount > total) total = v.amount;
    });
    return total;
  }
};

export const calculateShippingSupplier = (
  products: Record<string, CartProduct> | Record<string, OrderProduct>,
  shippingAddress: BillingAddress,
  supplierId: string
) => {
  const supplierTotals = {};
  for (const product of Object.values(products)) {
    const productShipping = calculateProductShipping(product, shippingAddress);
    // if we have a shipping rate for this store, and this product is
    // less than or equal to that shipping cost, ignore it.
    if (
      productShipping === null ||
      (supplierTotals[product.storeId] &&
        supplierTotals[product.storeId] <= productShipping)
    )
      continue;
    // We don't have a supplier rate yet, or this product is more expensive;
    supplierTotals[product.storeId] = productShipping;
  }
  return supplierTotals[supplierId];
};

export const calculateShippingAllProducts = (
  products: Record<string, CartProduct> | Record<string, OrderProduct>,
  shippingAddress: BillingAddress
): [number, number] => {
  const supplierTotals = {};
  for (const product of Object.values(products)) {
    const productShipping = calculateProductShipping(product, shippingAddress);
    // if we have a shipping rate for this store, and this product is
    // less than or equal to that shipping cost, ignore it.
    if (
      productShipping === null ||
      (supplierTotals[product.storeId] &&
        supplierTotals[product.storeId] <= productShipping)
    )
      continue;
    // We don't have a supplier rate yet, or this product is more expensive;
    supplierTotals[product.storeId] = productShipping;
  }
  const total = sum(Object.values(supplierTotals));
  const shipping: [number, number] = [
    total,
    Object.keys(supplierTotals).length,
  ];
  return shipping;
};

export const calculateShippingGifts = (
  cart: ShoppingCart | Order,
  shippingAddress?: BillingAddress
): [number, number] => {
  // 1. Find the most expensive shipping cost
  let shippingCost = 0;
  for (const product of Object.values(cart.products)) {
    const productShipping = calculateProductShipping(product, shippingAddress);
    if (productShipping > shippingCost) shippingCost = productShipping;
  }
  return [shippingCost, 1];
};

export const calculateShipping = (
  cart: ShoppingCart,
  shippingAddress?: BillingAddress
): [number, number] => {
  if (cart.allowAll || !cart.listId) {
    return calculateShippingAllProducts(cart.products, shippingAddress);
  }
  return calculateShippingGifts(cart, shippingAddress);
};

export const calculateShippingOrder = (order: Order): [number, number] => {
  if (!order.listId)
    return calculateShippingAllProducts(
      order.products,
      order.logistics.shipping
    );
  return calculateShippingGifts(order, order.logistics.shipping);
};

export const calculateGSTFromTotal = (price: number) => {
  return parseFloat(((price * 3) / 23).toFixed(2));
};

export const calculateGST = (price: number) => {
  return price * 1.15;
};

export const getBaseUrl = (
  subdomain: 'shop' | 'admin' | 'supplier'
): string => {
  let port = 4100;
  switch (subdomain) {
    case 'shop':
      port = 4100;
      break;
    case 'admin':
      port = 4200;
      break;
    case 'supplier':
      port = 4300;
      break;
    default:
      break;
  }
  const environment =
    process.env.NODE_ENV === 'production'
      ? 'give-production'
      : 'giftery-development';
  let redirectUrl = `https://${subdomain}.thegiftery.co.nz`;
  if (environment === 'giftery-development' && process.env.FUNCTIONS_EMULATOR) {
    redirectUrl = `http${port === 4100 ? 's' : ''}://localhost:${port}`;
  } else if (environment === 'giftery-development') {
    redirectUrl = `https://${subdomain}.beta.thegiftery.nz`;
  }
  return redirectUrl;
};
