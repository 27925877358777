import {
  UpdateProduct,
  Product,
  WithId,
  Category,
  Hobby,
  Age,
  Gender,
  ProductVariant,
  ProductVariantOption,
} from '@giftery/api-interface';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { DBCollection } from '@giftery/enums';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { PageLoader } from '@giftery/ui/page-loader';
import { ImageUpload, ImageUploadMethods } from '@giftery/ui/image-upload';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { storage } from '@giftery/firebase';
import { compact, isEmpty, noop } from 'lodash';
import { LoadingButton } from '@giftery/ui/loading-button';
import { TextEditor } from '@giftery/ui/text-editor';
import 'react-tiny-fab/dist/styles.css';
import toast from 'react-hot-toast';
import { FiImage, FiLock, FiX } from 'react-icons/fi';
import { updateProduct } from '../../actions/Products';
import { Link } from 'react-router-dom';
import { isLoaded, useFirestore } from 'react-redux-firebase';
import { getRelation, validURL } from '@giftery/utils';
import { TagsInput } from '@giftery/ui/tagsinput';
import Switch from 'react-switch';
import { HomeIcon } from '@heroicons/react/solid';
import { ExpandingButton } from '@giftery/ui/expanding-button';
import { ProductVariants } from './Variants';
import { useBreakpoint } from '@giftery/ui/hooks';
import { useProduct, useProductMetadata, useStore } from '../../hooks';
import Select from 'react-select';
import { colors, selectStyleFactory } from '@giftery/theme';
import { Carousel } from 'react-responsive-carousel';

type PathParams = {
  id: string;
};

const ProductPage: React.FC<RouteComponentProps<PathParams>> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [sku, setSku] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [stock, setStock] = useState<number>(1);
  const [shippingType, setShippingType] = useState(0);
  const [shippingOverridePrice, setShippingOverridePrice] =
    useState<number>(null);
  const [images, setImages] = useState<string[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [featured, setFeatured] = useState<boolean>(false);
  const [restricted, setRestricted] = useState<boolean>(false);
  const [categories, setCategories] = useState<WithId<Category>[]>([]);
  const [hobbies, setHobbies] = useState<WithId<Hobby>[]>([]);
  const [ages, setAges] = useState<WithId<Age>[]>([]);
  const [genders, setGenders] = useState<WithId<Gender>[]>([]);
  const [product, setProduct] = useState<WithId<Product>>(null);
  const [variants, setVariants] = useState<ProductVariant[]>([]);
  const [options, setOptions] = useState<ProductVariantOption[]>([]);
  const [hasVariants, setHasVariants] = useState<boolean>(false);
  const breakpoint = useBreakpoint();
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const store = useStore();
  const imageUploadRef = useRef<ImageUploadMethods>();
  const firestore = useFirestore();
  const history = useHistory();
  const [categoryData, hobbyData, ageData, genderData] = useProductMetadata();

  const productId = props.match.params.id;
  const [rawProduct, productLoading] = useProduct(productId);
  const pages: { name: string; href: string; current: boolean }[] = [
    { name: 'Products', href: '/products', current: false },
    {
      name: rawProduct?.variants?.[0]?.sku || 'unknown',
      href: `/products/${rawProduct?.id}`,
      current: true,
    },
  ];

  const shippingOptions =
    store?.shipping?.rates?.map((s, i) => ({
      label: s.name,
      value: i,
    })) || [];

  const reset = () => {
    if (!rawProduct) {
      return;
    }
    setProduct(rawProduct);
    setName(rawProduct.name);
    setSku(rawProduct.sku);
    setDescription(rawProduct.description);
    setImages(rawProduct.images);
    setShippingType(rawProduct.shipping?.type || 0);
    setRestricted(rawProduct.isRestricted || false);
    setShippingOverridePrice(rawProduct.shipping?.override || null);
    setPrice(rawProduct.variants?.[0]?.price.toFixed(2) || '');
    setStock(rawProduct.variants?.[0]?.quantity ?? 0);
    setVariants(rawProduct.variants);
    setOptions(rawProduct.options);
    setHasVariants(rawProduct.variants?.length > 1 ? true : false);
    setFeatured(rawProduct.status.featured);
    imageUploadRef?.current?.reset();
    setLoading(false);
  };

  // TODO: clean up image handling, create useImages hook
  const fetchImages = (images = rawProduct.images) => {
    const getImage = async () => {
      const p = [];
      for (const image of images) {
        if (image && !validURL(image)) {
          try {
            const imageUrl = await storage
              .ref(`/images/${image}`)
              .getDownloadURL();
            p.push(imageUrl);
          } catch (err) {
            p.push(`${window.location.origin}/images/broken-image.png`);
          }
        } else {
          p.push(image);
        }
      }
      setPreviewImages(p);
    };
    getImage();
  };
  useEffect(() => {
    if (previewImages.every((i) => validURL(i))) return;
    fetchImages(previewImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImages]);

  useEffect(() => {
    if (!rawProduct) return;
    if (rawProduct?.id !== product?.id) {
      reset();
      fetchImages();
    }
    // TODO: Rework how image previews
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawProduct, product]);

  useEffect(() => {
    if (!rawProduct) return;
    if (isEmpty(rawProduct.categories) && isEmpty(rawProduct.hobbies)) return;
    if (
      rawProduct.categories?.length === categories?.length &&
      rawProduct.hobbies?.length === hobbies?.length &&
      rawProduct.ages?.length === ages?.length &&
      rawProduct.genders?.length === genders?.length
    )
      return;
    if (categoryData && hobbyData && ageData && genderData) {
      setHobbies(() => {
        return rawProduct.hobbies?.map((l) => ({
          id: l,
          name: getRelation(hobbyData, l, 'name'),
        }));
      });
      setCategories(() => {
        return rawProduct.categories?.map((c) => ({
          id: c,
          name: getRelation(categoryData, c, 'name'),
        }));
      });
      setAges(() => {
        return rawProduct.ages?.map((c) => ({
          id: c,
          name: getRelation(ageData, c, 'name'),
        }));
      });
      setGenders(() => {
        return rawProduct.genders?.map((c) => ({
          id: c,
          name: getRelation(genderData, c, 'name'),
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawProduct, categoryData, hobbyData]);

  if (productLoading) return <PageLoader />;
  if (!rawProduct) return null;

  const updateShippingPrice = (value: string) => {
    setShippingOverridePrice(parseFloat(value));
  };

  const cancel = () => {
    history.goBack();
  };

  const save = async () => {
    setLoading(true);
    // Only send brand new images
    const newImages = images.map((image) =>
      image.indexOf('https://firebasestorage') <= -1 ? image : null
    );
    const payload: UpdateProduct = {
      sku: sku || null,
      name,
      description,
      images: compact(newImages),
      categories,
      hobbies,
      ages,
      genders,
      stock,
      shopify: product.shopify,
      price: parseFloat(parseFloat(price).toFixed(2)),
      options: hasVariants ? options : null,
      variants: hasVariants ? variants : variants.slice(0, 1),
      featured,
      shipping: {
        type: shippingType,
        override: shippingOverridePrice,
      },
      isRestricted: restricted,
    };
    await toast.promise(updateProduct(product.id, payload), {
      success: `Product Updated Successfully: ${payload.name}`,
      error: `Error updating product. Please try again`,
      loading: `Updating product...`,
    });
    //reset();
    setLoading(false);
  };

  const spliceImage = (index: number) => {
    const newImages = [...images];
    const newPreviewImages = [...previewImages];
    newImages.splice(index, 1);
    newPreviewImages.splice(index, 1);
    setImages(newImages);
    setPreviewImages(newPreviewImages);
    imageUploadRef.current.remove(index);
  };

  const deleteImage = async (index: number) => {
    if (!rawProduct.images[index]) return spliceImage(index);
    const imageRef = await storage.ref(`/images/${rawProduct.images[index]}`);
    const update = {
      images: firestore.FieldValue.arrayRemove(rawProduct.images[index]),
    };
    const promise = Promise.all([
      firestore
        .collection(DBCollection.products)
        .doc(rawProduct.id)
        .update(update),
      imageRef.delete(),
    ]);
    await toast.promise(promise, {
      success: 'Successfully deleted image',
      error: 'Error deleting image, please try again',
      loading: 'Deleting image...',
    });
    // Create a copy because the state is immutable
    spliceImage(index);
  };

  const updateVariants = (
    variants: ProductVariant[],
    options: ProductVariantOption[]
  ) => {
    setVariants(variants);
    setOptions(options);
  };

  const updatePrice = (price: string) => {
    setPrice(price || '');
    const v = [...variants];
    v[0] = { ...v[0], price: parseFloat(price) };
    setVariants(v);
  };

  if (
    !product ||
    !isLoaded(rawProduct) ||
    !isLoaded(categoryData) ||
    !isLoaded(hobbyData)
  )
    return <PageLoader />;

  const renderImagesMobile = () => {
    if (isEmpty(images) && product?.images?.length <= 0) {
      return (
        <div className="NoImages">
          <h3 className="text-2xl">This product has no images</h3>
        </div>
      );
    }

    return (
      <Carousel
        className="col-span-12"
        showThumbs={false}
        emulateTouch={true}
        showArrows={false}
        showStatus={false}
      >
        {previewImages.map((image, index) => (
          <div className="mb-8 flex-1 w-full relative" key={image}>
            <div className="absolute top-2 right-2">
              <ExpandingButton
                icon={FiX}
                text="Delete Image"
                onClick={() => deleteImage(index)}
              />
            </div>
            <img className="flex-1 w-full" src={image} alt={product.name} />
          </div>
        ))}
      </Carousel>
    );
  };

  const renderImagesFull = () => {
    return (
      <Scrollbars
        autoHeight
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            style={{ display: 'none' }}
            className="track-horizontal"
          />
        )}
        className="pb-48 flex flex-col"
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        autoHeightMin="calc(96vh - 250px)"
        autoHide={false}
      >
        <div className="grid grid-cols-1">
          {previewImages
            .filter((i) => validURL(i))
            .map((image, index) => (
              <div className="mb-8 flex-1 w-full relative" key={image}>
                <div className="absolute top-2 right-2">
                  <ExpandingButton
                    icon={FiX}
                    text="Delete Image"
                    onClick={() => deleteImage(index)}
                  />
                </div>
                <img className="flex-1 w-full" src={image} alt={product.name} />
              </div>
            ))}
        </div>
      </Scrollbars>
    );
  };

  const renderImages = () => {
    switch (breakpoint) {
      case 'xl':
      case 'lg':
      case 'md':
      case 'sm':
        return renderImagesMobile();
      default:
        return renderImagesFull();
    }
  };

  return (
    <div className="p-6 max-h-screen flex column">
      <Helmet>
        <title>The Giftery | Product | {product.name}</title>
      </Helmet>
      <div className="flex-1 mb-24">
        <nav className="flex pb-4 px-2" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to="/products"
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-2 bg-white shadow sm:rounded-lg p-4">
          <div className="p-2 col-span-2">
            {!isEmpty(previewImages) ? (
              renderImages()
            ) : (
              <div className="NoImages flex flex-grow flex-col items-center justify-center h-full absolute mx-auto left-0 right-0 text-grey-800">
                <FiImage
                  size={72}
                  className="stroke-current text-grey-800 mb-2"
                />
                <h3 className="text-center">This Product has no images</h3>
                <p className="">Why don't you try adding some?</p>
              </div>
            )}
          </div>
          <div className="p-2 col-span-3">
            <form>
              <Scrollbars
                autoHeight
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal"
                  />
                )}
                autoHeightMin="calc(96vh - 250px)"
                autoHide
              >
                <div className="p-2">
                  <label className="font-bold">Product Name</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pr-12 sm:text-sm border-gray-300"
                    value={name}
                    onChange={(e) => setName(e.target.value || '')}
                    placeholder="Product Title"
                  />
                </div>
                <div className="p-2">
                  <label className="font-bold">SKU</label>

                  <input
                    type="text"
                    name="sku"
                    id="sku"
                    className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pr-12 sm:text-sm border-gray-300"
                    value={sku}
                    onChange={(e) => setSku(e.target.value || '')}
                    placeholder="SKU"
                  />
                </div>
                <div className="p-2">
                  <label className="font-bold">Description</label>

                  <TextEditor
                    className="block w-full p-2 rounded-0 sm:text-sm border-0 outline-none border border-gray-300 ring-1 ring-transparent focus:ring-secondary-500 focus:border-secondary-500"
                    placeholder="Product Description"
                    value={description}
                    onChange={setDescription}
                  />
                </div>
                <div className="grid grid-cols-2 grid-gap-2">
                  <div className="p-2">
                    <label className="font-bold">Category</label>
                    <TagsInput
                      containerClassName="bg-white border border-gray-300 rounded-0 p-2"
                      inputClassName="border-0 focus:ring-0 py-1"
                      data={categoryData}
                      initial={categories}
                      placeholder={'Add a category'}
                      onChange={(tags) => setCategories(tags)}
                    />
                  </div>
                  <div className="p-2">
                    <label className="font-bold">Hobby</label>
                    <TagsInput
                      containerClassName="bg-white border border-gray-300 rounded-0 p-2"
                      inputClassName="border-0 focus:ring-0 py-1"
                      data={hobbyData}
                      initial={hobbies}
                      placeholder={'Add a hobby'}
                      onChange={(tags) => setHobbies(tags)}
                    />
                  </div>

                  <div className="p-2">
                    <label className="font-bold">Target Age Group</label>
                    <TagsInput
                      containerClassName="bg-white border border-gray-300 rounded-0 p-2"
                      inputClassName="border-0 focus:ring-0 py-1"
                      data={ageData}
                      initial={ages}
                      placeholder={'Add an age group'}
                      onChange={(tags) => setAges(tags)}
                    />
                  </div>
                  <div className="p-2"></div>
                  <div className="p-2">
                    <label className="grid grid-cols-2 gap-2 flex items-center justify-start opacity-50">
                      <div className="">
                        <p className="font-bold my-0">Featured</p>
                        <p className="text-sm my-0 text-secondary-300 text-sm">
                          Coming soon
                        </p>
                      </div>
                      <Switch
                        disabled={true}
                        onChange={(checked) => setFeatured(checked)}
                        checked={featured}
                      />
                    </label>
                  </div>
                  <div className="py-2"></div>

                  <div className="p-2">
                    <label className="font-bold">Product Type (Shipping)</label>
                    <Select
                      className="ProductOrderBy font-sans"
                      classNamePrefix="ProductOrderBy"
                      options={shippingOptions}
                      styles={selectStyleFactory('secondary', 1)}
                      value={{
                        value: shippingType || 0,
                        label:
                          shippingOptions?.[shippingType]?.label ||
                          'Loading...',
                      }}
                      onChange={(option) => setShippingType(option.value)}
                      placeholder="Sort By"
                    />
                  </div>
                  <div className="p-2">
                    <label className="font-bold">Special Shipping Rate</label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                        min={0.0}
                        onFocus={(e) => e.target.select()}
                        value={shippingOverridePrice || 0}
                        onChange={(e) => updateShippingPrice(e.target.value)}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm"
                          id="price-currency"
                        >
                          NZD
                        </span>
                      </div>
                    </div>
                  </div>

                  {!hasVariants && (
                    <>
                      <div className="p-2">
                        <label className="font-bold">Stock</label>

                        <div>
                          <input
                            name="stock"
                            id="stock"
                            type="number"
                            className="focus:ring-secondary-500 focus:border-secondary-500 block w-full sm:text-sm border-gray-300"
                            min={0}
                            value={stock}
                            onChange={(e) =>
                              setStock(parseFloat(e.target.value) || 0)
                            }
                          />
                        </div>
                      </div>
                      <div className="p-2">
                        <label className="font-bold">Price</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="text"
                            className="focus:ring-secondary-500 focus:border-secondary-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                            min={0.0}
                            value={price}
                            onChange={(e) => updatePrice(e.target.value)}
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              NZD
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <div className="py-2">
                    <label className="flex">
                      <div className="flex flex-grow-0 items-center jusify-center text-center">
                        <Switch
                          onColor={colors.danger}
                          checkedIcon={
                            <FiLock className="stroke-current text-white absolute top-1 left-2" />
                          }
                          uncheckedIcon={null}
                          onChange={(checked) => setRestricted(checked)}
                          checked={restricted}
                        />
                      </div>
                      <div className="flex-grow pl-3">
                        <p className="font-bold my-0">Restricted Item?</p>
                        <p className="text-sm my-0 text-secondary-300 text-sm">
                          Is this product age-restricted? E.g. alcohol or
                          tobacco products.
                        </p>
                      </div>
                    </label>
                  </div>

                  <div>
                    <div>
                      <ImageUpload
                        ref={imageUploadRef}
                        onRequestClear={noop}
                        onRequestRevert={(id: string) => {
                          const index = images.indexOf(id);
                          if (index < 0) return;
                          setImages(images.filter((img, i) => i !== index));
                          setPreviewImages(
                            previewImages.filter((img, i) => i !== index)
                          );
                        }}
                        onRequestSave={(id: string) => {
                          setPreviewImages((images) => [...images, id]);
                          setImages((images) => [...images, id]);
                        }}
                        defaultFiles={[]}
                      />
                    </div>
                  </div>
                  <div className="p-2 pb-16">
                    <label className="font-bold flex justify-between items-center">
                      <div>Variants</div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            checked={hasVariants}
                            onChange={() => setHasVariants(!hasVariants)}
                            className="focus:ring-secondary-500 h-4 w-4 text-secondary-600 border-gray-300"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700 m-0"
                          >
                            This product has variants (e.g. size/color/style)
                          </label>
                        </div>
                      </div>
                    </label>
                    {hasVariants && (
                      <ProductVariants
                        product={product}
                        onChange={updateVariants}
                      />
                    )}
                  </div>
                </div>
              </Scrollbars>
              <div className="grid grid-cols-2 gap-2">
                <button
                  type="button"
                  className="bg-transparent flex-1 text-secondary-500 text-xl font-normal py-2 px-3 border-2 border-secondary-500 hover:bg-secondary-400 hover:border-secondary-400"
                  onClick={cancel}
                >
                  Cancel
                </button>
                <LoadingButton
                  type="button"
                  loading={loading}
                  feedback={'Saving...'}
                  className="bg-secondary-500 flex-1 text-white text-xl font-normal py-2 px-3 border-2 border-secondary-500 hover:bg-secondary-400 hover:border-secondary-400"
                  onClick={save}
                >
                  Save Product
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProductPage);
