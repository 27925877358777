import React from 'react';
import { FaShopify } from 'react-icons/fa';
import {
  FiBox,
  FiDollarSign,
  FiEye,
  FiLayout,
  FiMousePointer,
  FiShoppingCart,
} from 'react-icons/fi';
import { useStats, useStore } from '../../../../hooks';
import { INumberCard, NumberCard } from './NumberCard';

export const NumberCards: React.FC = () => {
  const store = useStore();
  const [statsCurrentMonth, statsTotal, loading] = useStats();
  const numberCards: INumberCard[] = [
    {
      title: 'Impressions',
      subtitle: 'The number of times your products were shown to customers',
      loading,
      number: statsCurrentMonth?.impressions ?? 0,
      Icon: FiLayout,
      url: '/products',
    },
    {
      title: 'Views',
      subtitle:
        'The number of times your products were clicked on by customers',
      loading,
      number: statsCurrentMonth?.views ?? 0,
      Icon: FiEye,
      url: '/products',
    },
    {
      title: 'CTR',
      subtitle:
        'A percentage showing the number of times your products were clicked on vs. shown to users (higher is better)',
      loading,
      number:
        ((statsCurrentMonth?.views || 0) /
          (statsCurrentMonth?.impressions || 1)) *
          100 ?? 0,
      unit: '%',
      Icon: FiMousePointer,
      url: '/orders',
    },
    {
      title: 'Conversion Rate',
      subtitle:
        'The rate at which your products are ordered vs. viewed (higher is better)',
      loading,
      number: store?.counters.orders.total
        ? (store?.counters.orders.total / statsCurrentMonth?.views || 1) * 100
        : 0,
      unit: '%',
      Icon: FiDollarSign,
      url: '/orders',
    },
  ];
  return (
    <div className="w-full h-full grid grid-cols-2 gap-2">
      {numberCards.map((card, index) => (
        <NumberCard
          key={`numbercard-${index}`}
          Icon={card.Icon}
          loading={card.loading}
          unit={card.unit}
          url={card.url}
          number={card.number}
          title={card.title}
          subtitle={card.subtitle}
        />
      ))}
    </div>
  );
};
