import { CartProduct } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import { storage } from '@giftery/firebase';
import { isEmpty } from 'lodash';
import ContentLoader from 'react-content-loader';
import { colors } from '@giftery/theme';
import { FiBox } from 'react-icons/fi';

interface ProductItemProps {
  product: CartProduct;
}

const ProductLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    className="ProductLoader"
    backgroundColor={colors.background}
    foregroundColor={colors.secondary}
    {...props}
  >
    <rect x="45" y="12" rx="3" ry="3" width="200" height="6" />
    <circle cx="15" cy="15" r="15" />
    <rect x="265" y="12" rx="3" ry="3" width="400" height="6" />
    <rect x="685" y="12" rx="3" ry="3" width="200" height="6" />
    <rect x="905" y="12" rx="3" ry="3" width="80" height="6" />
    <rect x="1005" y="12" rx="3" ry="3" width="80" height="6" />
    <rect x="1105" y="12" rx="3" ry="3" width="80" height="6" />
  </ContentLoader>
);

export const OrderProductItem: React.FC<ProductItemProps> = ({
  product: rawProduct,
}) => {
  const renderImage = () => {
    const imgUrl =
      product.images?.[0]?.indexOf('http') > -1 ? product.images[0] : null;
    if (!imgUrl) {
      return (
        <div className="h-10 w-10 rounded-full p-1 flex items-center justify-center">
          <FiBox size={24} className="stroke-current text-gray-300" />
        </div>
      );
    }
    return <img className="w-10" src={imgUrl} alt="" />;
  };

  const [product, setProduct] = useState<CartProduct>(null);
  useEffect(() => {
    if (rawProduct && !product) {
      const getImage = async () => {
        const p = Object.assign(
          {},
          {
            ...rawProduct,
            images: rawProduct.images ? [...rawProduct.images] : [],
          }
        );
        const mainImage = p.images[0];
        if (mainImage && mainImage.indexOf('http') < 0) {
          p.images[0] = await storage
            .ref(`/images/${mainImage}`)
            .getDownloadURL();
        }
        setProduct(p);
      };
      getImage().then();
    }
  }, [rawProduct, product]);

  useEffect(() => {
    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!product) return <ProductLoader />;

  return (
    <div className="grid grid-cols-12 gap-4 bg-secondary-100 mt-4 p-2 px-4">
      <div className="col-span-2">{renderImage()}</div>
      <div className="col-span-7 text-left flex items-center">
        <p className="text-sm my-0">{product.name}</p>
      </div>
      <div className="col-span-3 text-right flex items-center justify-end">
        <p className="text-sm my-0">
          {numeral(product.price * product.quantity).format('$0,0.00')}
        </p>
      </div>
    </div>
  );
};
