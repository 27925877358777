import React, { useEffect, useRef } from 'react';
import lottie, { AnimationConfigWithData } from 'lottie-web';

/* eslint-disable-next-line */
export interface LottieProps {
  animation: Record<string, unknown>;
  config?: Partial<AnimationConfigWithData>;
  className?: string;
}

export function Lottie(props: LottieProps) {
  const ref = useRef();

  useEffect(() => {
    const config = props.config || {};
    lottie.loadAnimation({
      container: ref.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: props.animation,
      ...config,
    });
  }, []);

  return <div ref={ref} className={props.className}></div>;
}
