/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from '@giftery/utils';

interface ProductStatusDropdownProps {
  active: boolean;
  onChange: (value: boolean) => void;
}

export const ProductStatusDropdown: React.FC<ProductStatusDropdownProps> = ({
  active,
  ...props
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div className="flex row items-center justify-center">
            <Menu.Button className="group p-2 hover:bg-grey-50 flex row items-center justify-center rounded-md">
              <div
                className={classNames(
                  active
                    ? 'bg-green-100 text-green-800'
                    : 'bg-gray-100 text-grey-700 group-hover:bg-gray-200',
                  'px-2 inline-flex text-xs leading-5 font-semibold rounded-full w-20 align-center justify-center'
                )}
              >
                {active ? 'Active' : 'Draft'}
              </div>
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5 fill-current text-grey-400 group-hover:text-grey-800"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute -right-8 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
            >
              <div className="py-1">
                <Menu.Item>
                  <button
                    type="button"
                    onClick={() => props.onChange(!active)}
                    className={classNames(
                      active ? 'text-gray-900' : 'text-grey-700',
                      'block px-4 py-2 text-sm w-full text-left hover:text-grey-800 hover:bg-gray-50'
                    )}
                  >
                    {active ? 'Set as Draft' : 'Publish'}
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
