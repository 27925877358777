import { storage } from '@giftery/firebase';
import { PageLoader } from '@giftery/ui/page-loader';
import React, { useState } from 'react';
import placeholder from '../../../../images/store-placeholder.png';
import Rating from 'react-rating';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

import './StoreSummary.scss';
import { isLoaded } from 'react-redux-firebase';
import { useStore } from '../../../../hooks';
import { Link } from 'react-router-dom';

const StoreSummary = () => {
  const [logo, setLogo] = useState<string>(null);

  const store = useStore();
  const storeRating = store?.rating || 5;
  const getLogo = async () => {
    if (store.logo) {
      const imageUrl = await storage
        .ref(`/images/${store.logo}`)
        .getDownloadURL();
      setLogo(imageUrl);
      return imageUrl;
    }
    return null;
  };
  if (!isLoaded(store) || !store) return <PageLoader />;
  getLogo();
  return (
    <div className="StoreSummary">
      <div className="StoreImage">
        <img src={logo || placeholder} alt={store.tradingName} />
      </div>
      <h3>{store.tradingName}</h3>
      <Rating
        start={0}
        stop={5}
        step={1}
        readonly={true}
        initialRating={storeRating}
        emptySymbol={<AiOutlineStar className={`icon`} />}
        fullSymbol={
          <AiFillStar
            className={`icon ${
              storeRating === 5 ? 'fill-current text-gold' : ''
            }`}
          />
        }
      />
      <div className="Counters">
        <div className="Counter">
          <Link
            to="/products"
            className="text-gray-800 no-underline hover:no-underline"
          >
            <div className="Count">{store.counters.products.total}</div>
            <div className="Label">Products</div>
          </Link>
        </div>
        <div className="Counter text-gray-800">
          <div className="Count">{store.counters.reviews.total}</div>
          <div className="Label">Reviews</div>
        </div>
      </div>
    </div>
  );
};

export default StoreSummary;
