import { Order, WithId } from '@giftery/api-interface';
import { chain, find } from 'lodash';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useMe } from './UseMe';

export function useOrder(id: string): WithId<Order> | null {
  const me = useMe();
  const populates = [
    { child: 'giftGiver', root: DBCollection.users },
    { child: 'giftReceivers', root: DBCollection.users },
  ];
  const collection = DBCollection.orders;
  useFirestoreConnect([
    {
      collection: DBCollection.orders,
      doc: id,
      populates,
    },
  ]);

  const { orders } = useSelector((state: SupplierRootState) => {
    return {
      orders: populate(state.firestore, collection, populates),
    };
  });

  const order = find(orders, (o) => o.id === id);
  if (!order) return null;
  order.products = chain(order.products)
    .map((p) => {
      return p.storeId === me?.supplier && p.status.selected ? p : null;
    })
    .compact()
    .keyBy('id')
    .value();

  return { ...order, id };
}
