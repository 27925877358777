import { DBCollection } from '@giftery/enums';
import { RootState } from '@giftery/ui/interfaces';
import { PageLoader } from '@giftery/ui/page-loader';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import ProductItem from './components/ProductItem';
import './PopularProducts.scss';

const PopularProducts = () => {
  const user = useSelector((state: RootState) => state.firebase?.profile);
  useFirestoreConnect([
    {
      collection: DBCollection.products,
      where: [
        ['counters.views', '>', 10],
        ['status.deleted', '==', false],
        ['supplierId', '==', user.supplier],
      ],
      storeAs: 'productsPopular',
      orderBy: ['counters.views', 'desc'],
      limit: 5,
    },
  ]);
  const products = useSelector(
    (state: RootState) => state.firestore?.ordered.productsPopular
  );

  if (!isLoaded(products)) return <PageLoader />;

  return (
    <div className="w-full p-0 mt-2">
      <div className="flex flex-row justify-between items-center py-2">
        <div className="Title text-2xl font-serif">Popular Products</div>
        <div className="font-sans">
          <Link to="/products">See All</Link>
        </div>
      </div>
      <div className="bg-transparent rounded-none">
        {isEmpty(products) ? (
          <h1 className="text-xl text-gray-400 text-left">
            We don't have enough data to show you popular products yet.
          </h1>
        ) : (
          <div className="bg-white">
            {products.map((product) => {
              return <ProductItem key={product.id} product={product} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopularProducts;
