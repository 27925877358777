import { request } from '@giftery/axios';

export const dispatchOrder = async (
  id: string,
  trackingNumber: string,
  files: string[]
) => {
  return await request('put', `/orders/${id}/dispatch`, {
    trackingNumber,
    files,
  });
};
