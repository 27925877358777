import { Supplier, SupplierSecure } from '@giftery/api-interface';
import { request } from '@giftery/axios';

export const updateSupplier = async (
  id: string,
  supplier: Partial<Supplier>,
  secure: Partial<SupplierSecure>
) => {
  return await request('put', `/suppliers/${id}`, { supplier, secure });
};

export const updatePassword = async (password: string) => {
  return await request('put', `/users/password`, { password });
};
