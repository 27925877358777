import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import './Login.scss';
import { useFirebase } from 'react-redux-firebase';
import { Logo } from '@giftery/ui/logo';
import { Gifter } from '@giftery/ui/gifter';
import toast from 'react-hot-toast';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const firebase = useFirebase();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      await firebase.auth().signOut();
      if (token) {
        const res = firebase.auth().signInWithCustomToken(token);
        toast.promise(res, {
          loading: `Logging you in...`,
          success: `Success! You will be redirected shortly.`,
          error: `Uh oh! We couldn't log you in automatically. Please contact support.`,
        });
        const user = (await res)?.user || null;
        if (user) return history.push('/');
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const res = await firebase.login({
        email,
        password,
      });
      if (res.user) return history.push('/');
    } catch (err) {
      setError(err.message);
    }
  };

  const renderErrors = () => {
    if (!error) return null;
    return <p className="bg-white text-primary-500 p-4 mb-4">{error}</p>;
  };

  return (
    <div className="min-h-screen bg-white flex w-full">
      <Helmet>
        <title>The Giftery | Login</title>
      </Helmet>
      <div className="w-full flex flex-col mx-auto justify-center py-12 xl:px-24">
        <div className="mx-auto max-w-96">
          <div>
            <Gifter className="h-36 w-36 stroke-current fill-current text-secondary-600" />
            <Logo
              className="h-12 stroke-current fill-current text-secondary-600"
              color="secondary-600"
            />
            <h2 className="mt-6 text-xl font-medium text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Don't have an account?{' '}
              <a
                href="https://thegiftery.co.nz/vendors/become-a-vendor"
                className="font-medium text-secondary-500 hover:text-secondary-500"
              >
                Sign up to become a vendor
              </a>
            </p>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form action="#" method="POST" className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value || '')}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value || '')}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  {renderErrors()}
                  <button
                    type="button"
                    onClick={login}
                    className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-secondary-500 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
