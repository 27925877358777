import React, { useState } from 'react';
import AccountPlanDetails from './components/Billing/AccountPlanDetails';
import BillingHistory from './components/Billing/BillingHistory';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeCustomer } from '../../../actions/Accounts';
import { Helmet } from 'react-helmet';

const stripePromise = loadStripe(
  process.env.NODE_ENV === 'production'
    ? process.env.NX_STRIPE_KEY_PROD
    : process.env.NX_STRIPE_KEY_DEV
);

const BillingPage = () => {
  const [customer, setCustomer] = useState<StripeCustomer>(null);

  return (
    <>
      {/* Plan */}
      <Elements stripe={stripePromise}>
        <AccountPlanDetails />
      </Elements>
      {/* Billing history */}
      <BillingHistory />
    </>
  );
};

export default BillingPage;
