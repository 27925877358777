import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useMe } from './UseMe';
import { useStore } from './UseStore';

export function useNotifications() {
  const me = useMe();
  const store = useStore();

  useFirestoreConnect([
    {
      collection: DBCollection.notifications,
      where: ['user', '==', me?.id || 'PENDING'],
      storeAs: 'userNotifications',
    },
    {
      collection: DBCollection.notifications,
      where: ['store', '==', store?.id || 'PENDING'],
      storeAs: 'storeNotifications',
    },
  ]);
  const [userNotifications, storeNotifications] = useSelector(
    (state: SupplierRootState) => [
      state.firestore.ordered.userNotifications || [],
      state.firestore.ordered.storeNotifications || [],
    ]
  );

  return [
    [...userNotifications, ...storeNotifications],
    userNotifications,
    storeNotifications,
  ];
}
