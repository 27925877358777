import React from 'react';
import { noop } from 'lodash';
import { createPortal } from 'react-dom';
import './ui-drawer.scss';

/* eslint-disable-next-line */
export interface DrawerProps {
  open: boolean;
  width: string;
  close: boolean;
  overlay?: boolean;
  closeOnOverlay?: boolean;
  onClose: () => void;
  placement: 'top' | 'left' | 'bottom' | 'right';
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  const portaledComponent = (
    <>
      {props.overlay && props.open && (
        <div
          className="drawer transform duration-150	bg-grey-800 opacity-50 z-40 fixed top-0 left-0 right-0 bottom-0"
          onClick={props.closeOnOverlay ? props.onClose : noop}
        ></div>
      )}
      <div
        className={classNames(
          props.open ? 'translate-x-100' : 'translate-x-full',
          'drawer transform duration-150	 p-8 right-0 fixed top-0 h-screen w-1/3 z-50 bg-white shadow-xl'
        )}
      >
        {/* overlay */}
        {/* close btn */}
        {props.close && (
          <button
            type="button"
            className="my-3 hover:text-secondary-500"
            onClick={props.onClose}
          >
            Close
          </button>
        )}
        {/* child component contents */}
        {children}
      </div>
    </>
  );
  return createPortal(portaledComponent, document.body);
};
