import { Stat } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useMe } from './UseMe';
export function useStats(): [Stat, Stat, boolean] {
  const me = useMe();
  const today = new Date();
  const statsDateId = format(today, 'yyyy-MM');
  useFirestoreConnect([
    {
      collection: DBCollection.suppliers,
      doc: me?.supplier || 'LOADING',
      subcollections: [
        {
          collection: DBCollection.stats,
          // TODO: Replace this with fieldpath id when lib is fixed
          // TODO: make this eventually only return current and last month
          storeAs: 'stats',
        },
      ],
      storeAs: 'stats',
    },
  ]);
  const stats = useSelector(
    (state: SupplierRootState) => state.firestore.data.stats || null
  );
  const loading = !isLoaded(stats);
  return stats
    ? [stats[statsDateId] || null, stats.total || null, loading]
    : [null, null, loading];
}
