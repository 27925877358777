import { Order, WithId } from '@giftery/api-interface';
import { chain, size } from 'lodash';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate } from 'react-redux-firebase';
import { SupplierRootState } from '../store/reducers';
import { useMe } from './UseMe';
import { subYears, startOfMonth } from 'date-fns';

export function useOrders(): WithId<Order>[] | null {
  const me = useMe();
  const populates = [
    { child: 'giftGiver', root: DBCollection.users },
    { child: 'giftReceivers', root: DBCollection.users },
  ];
  const collection = DBCollection.orders;
  const yearAgo = subYears(startOfMonth(new Date()), 1);
  useFirestoreConnect([
    {
      collection: DBCollection.orders,
      where: [
        ['stores', 'array-contains', me?.supplier || 'USER_NOT_LOADED'],
        ['audit.createdAt', '>=', yearAgo],
        ['status.selected', '==', true],
      ],
      populates,
    },
  ]);
  const { orders } = useSelector((state: SupplierRootState) => {
    return {
      orders: populate(state.firestore, collection, populates),
    };
  });
  return (
    chain(orders)
      .map((o, id) => {
        // Only return products for this store
        const order = { ...o };
        const products = chain(order.products)
          .map((p) => {
            return p.storeId === me?.supplier && p.status.selected ? p : null;
          })
          .compact()
          .keyBy('id')
          .value();
        order.products = products;
        // Filter out orders that have no products for this store;
        // TODO: This is a hack, we should be able to filter out orders on the api
        if (size(order.products) === 0) {
          return null;
        }
        return { ...order, id };
      })
      .compact()
      .value() || null
  );
}
