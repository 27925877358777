import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

const Content: React.FC = ({ children }) => {
  return (
    <div className=" col-span-11 xl:col-span-10 content">
      <Scrollbars
        autoHeight
        autoHeightMin="100vh"
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            style={{ display: 'none' }}
            className="track-horizontal"
          />
        )}
        className="testing"
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        autoHide={false}
      >
        {children}
      </Scrollbars>
    </div>
  );
};

export default Content;
