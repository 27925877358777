import { Category, Hobby, Age, Gender } from '@giftery/api-interface';
import { RootState } from '@giftery/ui/interfaces';
import { useSelector } from 'react-redux';
import { FirestoreReducer } from 'redux-firestore';

export function useProductMetadata() {
  const [categories, hobbies, ages, genders]: [
    FirestoreReducer.EntityWithId<Category>[],
    FirestoreReducer.EntityWithId<Hobby>[],
    FirestoreReducer.EntityWithId<Age>[],
    FirestoreReducer.EntityWithId<Gender>[]
  ] = useSelector((state: RootState) => [
    state.firestore.ordered.categories || null,
    state.firestore.ordered.hobbies || null,
    state.firestore.ordered.ages || null,
    state.firestore.ordered.genders || null,
  ]);
  return [categories, hobbies, ages, genders];
}
