import React, { useEffect, useState } from 'react';
import { useWizardStep } from 'react-wizard-primitive';
import { Supplier, WithId } from '@giftery/api-interface';
import { LoadingButton } from '@giftery/ui/loading-button';
import { useFirestore } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import { Gifter } from '@giftery/ui/gifter';
import { Logo } from '@giftery/ui/logo';

interface NameStepProps {
  supplier: WithId<Supplier>;
}

export const NameStep: React.FC<NameStepProps> = ({ supplier }) => {
  const { isActive, nextStep } = useWizardStep();
  const firestore = useFirestore();
  const [tradingName, setTradingName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setTradingName(supplier.tradingName || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isActive) return null;

  const submit = async () => {
    setLoading(true);
    const update = {
      tradingName,
    };
    await firestore
      .collection(DBCollection.suppliers)
      .doc(supplier.id)
      .update(update);
    setLoading(false);
    nextStep();
  };
  return (
    <div className="WizardStep text-secondary-500">
      <Gifter className="h-36 w-36 stroke-current fill-current text-secondary-600" />
      <Logo
        className="h-12 stroke-current fill-current text-secondary-600"
        color="secondary-600"
      />
      <div className="py-3">
        <h1 className="text-3xl font-serif">Getting Started</h1>
        <p className="text-xl font-sans">What is the name of your business?</p>
      </div>
      <div>
        <input
          type="text"
          name="business-name"
          className="w-full border-1 border-secondary-500 text-secondary-500 py-3 px-6"
          id="business-name"
          placeholder="e.g. Charlotte's Boutique Clothing"
          value={tradingName}
          onChange={(event) => setTradingName(event.target.value || '')}
        />
      </div>
      <div className="footer">
        <LoadingButton
          loading={loading}
          feedback="Sit Tight..."
          className="bg-secondary-600
          text-white text-xl font-normal
          py-2 px-3 mt-3
          hover:bg-secondary-700
          disabled:opacity-50 disabled:pointer-events-none"
          disabled={tradingName.trim() === ''}
          onClick={submit}
        >
          Continue
        </LoadingButton>
      </div>
    </div>
  );
};
