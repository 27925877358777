import React from 'react';

export interface LogoProps {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}

export function Logo(props: LogoProps) {
  return (
    <svg
      className={`stroke-current fill-current max-w-full max-h-full ${
        props.className || 'text-secondary-800'
      }`}
      height={props.height || 96}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 333.6 64.25"
    >
      <path
        d="M201.15,67V65.81H217V67c-.62.16-1.27.31-1.9.51a3.17,3.17,0,0,0-2.24,2.43,9,9,0,0,0-.35,2.17c0,4.15.06,8.3.12,12.45a13.23,13.23,0,0,0,.16,1.53c-.08,0-.15.08-.17.07-2.57-2-5.3-1.31-8.05-.54a33.92,33.92,0,0,1-8.89,1.5,21.89,21.89,0,0,1-22.19-18.67c-1.23-7.33,0-14.23,4.84-20.07,5.1-6.12,11.83-8.69,19.71-8.4a18.7,18.7,0,0,1,10.76,3.6A10.42,10.42,0,0,1,213.27,50a6.49,6.49,0,0,1-.11,2.52,3.06,3.06,0,0,1-3.2,2.22,3,3,0,0,1-3-2.24c-.23-.82-.28-1.69-.54-2.5-1.41-4.54-4.71-7-9.23-7.69a16.51,16.51,0,0,0-12,2.29A15.41,15.41,0,0,0,178.47,55c-1.42,7.08-.43,13.83,3.66,19.88,4.52,6.7,11,10,19,9.82a12.44,12.44,0,0,0,3.14-.53,4.06,4.06,0,0,0,3.26-4c.07-3.07,0-6.15-.23-9.22-.12-1.94-1.44-3-3.27-3.46C203.13,67.28,202.16,67.15,201.15,67Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M86.93,41.31c-.24-.71-.1-1.08.62-1.25,2-.46,4-1,6-1.45s2.68.13,2.68,2.14c0,4.33,0,8.67,0,13a27.62,27.62,0,0,0,.12,3,7.22,7.22,0,0,0,.54,1.56,7.22,7.22,0,0,0,1.41-.87,16.07,16.07,0,0,1,5.49-3.71c4.53-1.72,9.68-.66,12.55,2.65a11.93,11.93,0,0,1,2.71,8c.06,5.68,0,11.36,0,17,0,2.74.49,3.22,3.2,3.13H123c-.17.36-.22.72-.42.86a6.57,6.57,0,0,1-5.8,1.15,3.05,3.05,0,0,1-2.1-2.65,11.09,11.09,0,0,1-.16-2c0-5,0-10,0-14.93a19.63,19.63,0,0,0-.67-5.13c-1.35-4.61-5.51-6.87-10.25-5.8a9.13,9.13,0,0,0-7.19,8.63c-.18,4.38-.12,8.78-.16,13.18,0,1.22,0,2.44,0,3.66,0,1.94.68,2.77,2.55,3.23.68.17,1.37.29,2.12.45v1.14H87.07v-1.1c.92-.24,1.85-.41,2.74-.7a2.41,2.41,0,0,0,1.81-2.37c0-.43,0-.85,0-1.28V46.35c0-.77,0-1.53-.09-2.29a2.55,2.55,0,0,0-2.76-2.75C88.19,41.27,87.55,41.31,86.93,41.31Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M74.85,85.2v1.12H55.33c-.29-1.06-.22-1.11.81-1.24,1.48-.19,3-.38,4.42-.7s1.74-.93,1.84-2.28c0-.3,0-.61,0-.91V47.76a15.34,15.34,0,0,0-.09-1.92A2.47,2.47,0,0,0,60,43.34a19,19,0,0,0-7,0,5.21,5.21,0,0,0-3.6,2.45,18.31,18.31,0,0,0-1.55,3.1c-.32.87-.75,1.17-1.73.82.14-1.29.27-2.61.43-3.93s.37-2.6.48-3.9c.06-.79.44-1,1.13-.94,1.43.07,2.87.15,4.3.15q12.6,0,25.19,0c1.43,0,2.87-.08,4.3-.15.68,0,1,.21,1,.92.28,2.48.61,5,.92,7.44a3.38,3.38,0,0,1,0,.45c-.84.25-1.39.12-1.66-.83a12.17,12.17,0,0,0-1-2.25,5.85,5.85,0,0,0-5.12-3.48,44.65,44.65,0,0,0-6,.17,2.46,2.46,0,0,0-2.45,2.49,14,14,0,0,0-.08,1.83V81.29c0,2.27.44,2.83,2.66,3.31.74.16,1.5.24,2.26.34Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M367.47,53.52h12.1c.33.83,0,1.21-.76,1.4-3,.72-4.79,2.71-5.94,5.54C368.3,71.69,363.65,82.89,359,94.09A20.34,20.34,0,0,1,356.89,98a7.06,7.06,0,0,1-8.18,2.38,3.59,3.59,0,0,1-2.51-2.89,3.17,3.17,0,0,1,1.21-3.33c2-1.42,3.94-.7,4.48,1.67.06.3.11.6.17.89.32,1.59,1.55,2.06,2.85,1.09a3.24,3.24,0,0,0,.73-.7c2.18-3,3.78-6.33,3.73-10.2a14.79,14.79,0,0,0-1.44-5.44q-4.59-11.05-9.15-22.13a7.37,7.37,0,0,0-4.33-4.55c-.3-.11-.42-.74-.71-1.28h13.35c.3.83,0,1.17-.77,1.42-2.86.94-3.32,1.86-2.21,4.62q3.78,9.36,7.68,18.68a6.59,6.59,0,0,0,1.15,1.41A6.08,6.08,0,0,0,364,78.29q3.14-8,6.16-15.94a15.4,15.4,0,0,0,.74-3.1c.4-2.56-.19-3.47-2.65-4.31C367.31,54.61,367.31,54.61,367.47,53.52Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M154,79.2l.76.61a13.61,13.61,0,0,1-7.17,6c-9,3.24-19.93-1.53-21.72-12.92-.78-5-.08-9.82,3.08-14a15.28,15.28,0,0,1,11.65-6,13.77,13.77,0,0,1,8.88,1.89,8.34,8.34,0,0,1,3.91,8.31c-.24,2.4-1.82,3.68-4,4.43-3.24,1.14-6.63,1.25-10,1.44-2.23.12-4.46.14-6.68.29-1.84.11-2.35.79-2.11,2.61a11.94,11.94,0,0,0,4.57,8.27c4.53,3.43,9.55,4.22,14.82,2C151.43,81.5,152.58,80.24,154,79.2ZM137.15,67.69v.11c2.46-.23,4.92-.36,7.36-.7a4,4,0,0,0,3.77-3.54,11.09,11.09,0,0,0,.07-2,6.79,6.79,0,0,0-5.94-7,9.52,9.52,0,0,0-10.08,4.18,13.86,13.86,0,0,0-2,7.13c0,1.34.47,1.8,1.83,1.81C133.86,67.7,135.5,67.69,137.15,67.69Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M310.91,79.21l.75.57a13.53,13.53,0,0,1-5.15,5.09C299.16,89,287.22,86.69,283.57,76a18.16,18.16,0,0,1,1.58-16.11c2.81-4.54,7.16-6.66,12.39-7.05a13.83,13.83,0,0,1,8.71,1.81,8.39,8.39,0,0,1,4,8.7,4.66,4.66,0,0,1-3,3.75,22.29,22.29,0,0,1-8,1.63c-2.89.19-5.79.27-8.68.41a14.14,14.14,0,0,0-1.46.13c-1.09.14-1.53.58-1.52,1.67a11.56,11.56,0,0,0,2.82,7.46c3.75,4.55,11.32,6.3,16.51,3.71A31.19,31.19,0,0,0,310.91,79.21ZM294,67.69v.11c2.45-.23,4.92-.36,7.35-.7a4,4,0,0,0,3.65-2.88,8.87,8.87,0,0,0,.25-2.62c-.16-2.94-1.25-5.4-4.17-6.51a10,10,0,0,0-8.89.69c-3,1.74-5.48,7-4.95,10.42.16,1.07.59,1.47,1.72,1.48C290.68,67.7,292.36,67.69,294,67.69Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M256.54,53.83v2H253.7c-1.37,0-2.75-.05-4.11.08-1.2.13-1.55.63-1.56,1.85,0,2.63,0,5.25,0,7.88V81.12c0,2.27.79,3.21,3,3.58,1.11.19,2.22.32,3.45.49l0,1.1H238.4V85.18c.82-.15,1.62-.28,2.42-.45,1.93-.41,2.69-1.31,2.71-3.32,0-3.2,0-6.41,0-9.61V58.52c0-.39,0-.79,0-1.18-.13-1.2-.51-1.54-1.7-1.55h-3.64V54.51c.71-.18,1.47-.36,2.22-.56,2.49-.66,3.15-1.46,3.19-4.05a17.5,17.5,0,0,1,1.51-7.28,9.92,9.92,0,0,1,8.36-5.86,12.17,12.17,0,0,1,6.56.68,4,4,0,0,1,2.67,3.31,2.1,2.1,0,0,1-.83,2.27A2.68,2.68,0,0,1,259,43a10.75,10.75,0,0,1-2.07-2A6,6,0,0,0,252,38.74a3.31,3.31,0,0,0-3.62,3,20,20,0,0,0-.33,3.09c-.05,2.25,0,4.51,0,6.77,0,1.52.46,2.06,2,2.13,1.8.09,3.6.07,5.4.09C255.78,53.84,256.14,53.83,256.54,53.83Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M329.48,85.16V86.3H315.54V85.21c.88-.21,1.76-.36,2.59-.63a2.58,2.58,0,0,0,2-2.79c0-7.53,0-15.07,0-22.61,0-2.56-.95-3.43-3.53-3.43h-1.11c-.33-.75-.08-1.09.69-1.27,2.11-.48,4.2-1,6.3-1.54a1.55,1.55,0,0,1,2.1,1.5c.08.87,0,1.76.1,2.64,0,.31.17.8.35.84a1.3,1.3,0,0,0,1-.27c1-.78,1.85-1.7,2.87-2.41a11.53,11.53,0,0,1,9.19-2,4.83,4.83,0,0,1,4.05,5.1,2.75,2.75,0,0,1-4,2.58,2.64,2.64,0,0,1-1.24-1.53c-.75-3.33-2.9-4.57-6.14-3.38a8.66,8.66,0,0,0-5.37,6.39,21.19,21.19,0,0,0-.65,5.15c-.09,4.42,0,8.84,0,13.27,0,2.91.54,3.56,3.37,4.14Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M260.6,55.28c-.32-1,.06-1.4.81-1.78a15.23,15.23,0,0,0,6.31-6.06c.19-.32.3-.73.57-.93a2.22,2.22,0,0,1,1.23-.5c.17,0,.48.63.49,1,.05,1.49,0,3,0,4.48s.35,1.84,1.76,1.9c2,.1,4,.07,5.95.1h1.5v2.26c-1.49-.08-2.94-.18-4.4-.23a24.25,24.25,0,0,0-3.19,0c-1.27.13-1.62.59-1.62,1.88q0,5.91,0,11.81c0,3.18,0,6.35,0,9.53a14.23,14.23,0,0,0,.39,3c.58,2.4,2.6,3.62,5.22,3.11a25.83,25.83,0,0,0,3.17-1.08c.5.32.42.66-.11,1.11a9.15,9.15,0,0,1-10.28.58,6.69,6.69,0,0,1-2.81-5.12,26.56,26.56,0,0,1-.12-2.83q0-9.53,0-19.05c0-.31,0-.61,0-.92,0-1.92-.34-2.22-2.25-2.23Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M234.75,85.16v1.15H220.91V85.19c.8-.17,1.57-.3,2.33-.51a2.65,2.65,0,0,0,2.25-2.9c0-7.6,0-15.19,0-22.79,0-2.37-.95-3.22-3.32-3.24h-1.4V54.69c2.48-.6,5-1.25,7.48-1.81a1.4,1.4,0,0,1,1.69,1.19,5.52,5.52,0,0,1,.12,1.35q0,12.81,0,25.63c0,2.54.58,3.28,3.07,3.82C233.62,85,234.13,85.05,234.75,85.16Z"
        transform="translate(-46.1 -36.61)"
      />
      <path
        d="M230.08,40.49a3,3,0,1,1-5.92.09,3,3,0,0,1,5.92-.09Z"
        transform="translate(-46.1 -36.61)"
      />
    </svg>
  );
}
