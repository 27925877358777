import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { EditorOptions } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
}

export const TextEditor: React.FC<TextEditorProps> = ({
  value,
  placeholder,
  className,
  ...props
}) => {
  const getOptions = (): Partial<EditorOptions> => {
    return {
      extensions: [StarterKit, Placeholder],
      editorProps: {
        attributes: {
          placeholder: placeholder || 'abc',
          class: className || '',
        },
      },
      content: value,
      onUpdate: ({ editor }) => {
        props.onChange(editor.getHTML());
      },
    };
  };

  const editor = useEditor(getOptions());

  useEffect(() => {
    if (value && value !== editor?.getHTML()) {
      editor?.commands.setContent(value);
    }
  }, [editor, value]);

  return <EditorContent editor={editor} />;
};
