import { Product, UpdateProduct, WithId } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import './ProductItem.scss';
import { storage } from '@giftery/firebase';
import { isEmpty } from 'lodash';
import { FiBox, FiTrash2 } from 'react-icons/fi';
import { deleteProduct, updateProduct } from '../../../../actions/Products';
import swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { getRelation } from '@giftery/utils';
import ContentLoader from 'react-content-loader';
import { colors } from '@giftery/theme';
import { useProductMetadata } from '../../../../hooks';
import { FaShopify } from 'react-icons/fa';
import { SanitizeHTML } from '@giftery/ui/sanitize-html';
import { ProductStatusDropdown } from './ProductStatusDropdown';
import { isEqual, omit } from 'lodash';
interface ProductItemProps {
  product: WithId<Product>;
}

const ProductLoader = (props) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader viewBox="0 0 1060 40" height={40} {...props}>
      <rect x="34" y="13" rx="6" ry="6" width={200 * random} height="12" />
      <rect x="633" y="13" rx="6" ry="6" width={23 * random} height="12" />
      <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="12" />
      <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="12" />
      <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />

      <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>
  );
};

const ProductItem: React.FC<ProductItemProps> = ({ product: rawProduct }) => {
  const [product, setProduct] = useState<WithId<Product>>(null);
  const [categoryData, hobbyData] = useProductMetadata();

  const reset = () => {
    const getImage = async () => {
      const p = Object.assign(
        {},
        {
          ...rawProduct,
          images: rawProduct.images ? [...rawProduct.images] : [],
        }
      );
      const mainImage = p.images[0];
      if (mainImage && mainImage.indexOf('http') < 0) {
        try {
          const imageUrl = await storage
            .ref(`/images/${mainImage}`)
            .getDownloadURL();
          p.images[0] = imageUrl;
        } catch (err) {
          console.error(err);
        }
      }
      setProduct(p);
    };
    getImage();
  };

  useEffect(() => {
    if (
      (rawProduct && !product) ||
      !isEqual(omit(rawProduct, 'images'), omit(product, 'images'))
    ) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawProduct, product]);

  useEffect(() => {
    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const image = () => {
    if (!product || !product.images || isEmpty(product.images)) {
      return (
        <div className="h-10 w-10 rounded-full mr-2 flex items-center justify-center">
          <FiBox size={24} className="stroke-current text-gray-300" />
        </div>
      );
    }
    const imgUrl =
      product?.images[0].indexOf('http') > -1 ? product?.images[0] : null;
    return <img className="h-10 w-10 rounded-full" src={imgUrl} alt="" />;
  };

  if (!product)
    return (
      <tr>
        <td className="min-w-full" colSpan={5}>
          <ProductLoader />
        </td>
      </tr>
    );
  const deleteProductId = async () => {
    const result = await swal.fire({
      title: `Are you sure?`,
      text: `This action cannot be undone! Please confirm you'd like to delete the following product: ${product.name}`,
      showCancelButton: true,
      icon: 'error',
      cancelButtonText: 'No, Go Back',
      confirmButtonText: `Yes, Delete This Product`,
      confirmButtonColor: colors.danger,
      cancelButtonColor: colors.grey[100],
    });
    if (!result.isConfirmed) return;
    toast.promise(deleteProduct(product.id), {
      loading: 'Deleting product...',
      success: `Successfully deleted product`,
      error: `Error deleting product. Please try again later.`,
    });
  };

  const setProductStatus = async (active: boolean) => {
    const update: Partial<UpdateProduct> = {
      status: {
        ...product.status,
        active,
      },
    };
    await toast.promise(updateProduct(product.id, update), {
      loading: `Sit tight, we're ${
        active ? 'publishing' : 'unpublishing'
      } your product`,
      success: `Successfully ${active ? 'published' : 'unpublished'} product.`,
      error: `Oops! There was an error ${
        active ? 'publishing' : 'unpublishing'
      } your product. Please try again.`,
    });
    reset();
  };

  const renderControls = () => {
    return (
      <div className="Buttons mr-4">
        <button onClick={deleteProductId}>
          <FiTrash2 className="stroke-current text-grey-500 hover:text-primary-500" />
        </button>
      </div>
    );
  };

  return (
    <tr key={product.id}>
      <td className="px-4 py-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">{image()}</div>
          <div className="ml-4">
            <Link to={`/products/${product.id}`}>
              <div className="text-sm font-medium text-gray-900 overflow-ellipsis overflow-hidden w-72">
                {product.name}{' '}
                <span className="text-grey-500">(SKU: {product.sku})</span>
              </div>
              <div className="text-sm text-gray-500">
                <SanitizeHTML
                  html={product.description}
                  summary={true}
                  className="overflow-ellipsis overflow-hidden w-96"
                />
              </div>
            </Link>
          </div>
        </div>
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {getRelation(categoryData, product.categories[0], 'name')}
        </div>
        <div className="text-sm text-gray-500">
          {getRelation(hobbyData, product.hobbies[0], 'name')}
        </div>
      </td>
      <td className="px-4 py-2 whitespace-nowrap">
        <ProductStatusDropdown
          active={product.status.active}
          onChange={setProductStatus}
        />
      </td>
      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
        {product.shopify.productId ? (
          <FaShopify className="fill-current text-tertiary-500" />
        ) : null}
      </td>
      <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
        {renderControls()}
      </td>
    </tr>
  );
};

export default ProductItem;
