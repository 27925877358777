import Axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

// TODO: DNS Entry in functions
const environment =
  process.env.NODE_ENV === 'production'
    ? 'give-production'
    : 'giftery-development';

let baseUrl = `https://api.thegiftery.co.nz/${process.env.NX_FIREBASE_API_VERSION}`;
if (
  environment === 'giftery-development' &&
  window.location.hostname === 'localhost'
) {
  baseUrl = `http://localhost:5001/${environment}/us-central1/api/${process.env.NX_FIREBASE_API_VERSION}`;
} else if (environment === 'giftery-development') {
  baseUrl = `https://us-central1-giftery-development.cloudfunctions.net/api/${process.env.NX_FIREBASE_API_VERSION}`;
}

export const request = async <T = unknown>(
  method: Method,
  url: string,
  payload?: Record<string, unknown>
): Promise<AxiosResponse<T>> => {
  return new Promise((resolve, reject) => {
    const opts: AxiosRequestConfig = {
      method,
      url,
      data: payload,
    };
    axios(opts)
      .then((result: AxiosResponse<T>) => {
        return resolve(result);
      })
      .catch(reject);
  });
};

export const axios = Axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});
