import React, { useEffect, useState } from 'react';
import { useWizardStep } from 'react-wizard-primitive';
import './ContactStep.scss';
import { Supplier, WithId } from '@giftery/api-interface';
import firebase from 'firebase/app';
import { LoadingButton } from '@giftery/ui/loading-button';
import { useFirestore } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import { Gifter } from '@giftery/ui/gifter';
import { Logo } from '@giftery/ui/logo';
import { Place } from '@googlemaps/google-maps-services-js';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { find, includes } from 'lodash';
import { colors } from '@giftery/theme';
import { FiPlus } from 'react-icons/fi';

interface ContactStepProps {
  supplier: WithId<Supplier>;
}

export const ContactStep: React.FC<ContactStepProps> = ({ supplier }) => {
  const { isActive, nextStep } = useWizardStep();
  const firestore = useFirestore();
  const [editingAddress, setEditingAddress] = useState<boolean>(true);
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [country, setCountry] = useState<string>('New Zealand');
  const [loading, setLoading] = useState<boolean>(false);
  const [googlePlace, setGooglePlace] = useState<{
    label: string;
    value: Place;
  }>();
  useEffect(() => {
    setAddress(supplier.address || '');
    setCity(supplier.city || '');
    setRegion(supplier.region || '');
    setPostcode(supplier.postcode || '');
    setCountry(supplier.country || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findAddressComponent = (
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string | string[]
  ) => {
    return find(addressComponents, (component) =>
      includes(component.types, type)
    );
  };

  useEffect(() => {
    if (googlePlace && googlePlace.value.place_id) {
      geocodeByPlaceId(googlePlace.value.place_id)
        .then((results) => {
          const addressComponents = results[0]?.address_components;
          if (!addressComponents) return;
          // Extract address details from results
          // 1. Get Address
          const streetNumber = findAddressComponent(
            addressComponents,
            'street_number'
          );

          const streetName = findAddressComponent(addressComponents, 'route');
          if (streetName && streetNumber) {
            setAddress(`${streetNumber.long_name} ${streetName.long_name}`);
          } else {
            setAddress(null);
          }
          // 2. Get city
          const city = findAddressComponent(addressComponents, 'locality');
          setCity(city?.long_name ?? null);
          // 3. Get postcode
          const postcode = findAddressComponent(
            addressComponents,
            'postal_code'
          );
          setPostcode(postcode?.long_name ?? null);
          // 4. Get Region
          const region = findAddressComponent(
            addressComponents,
            'administrative_area_level_1'
          );
          setRegion(region?.long_name ?? null);
        })
        .catch((error) => console.error(error));
    }
  }, [googlePlace]);

  if (!isActive) return null;

  const cancelEditingAddress = () => {
    setEditingAddress(false);
    setAddress(supplier.address);
    setCity(supplier.city);
    setPostcode(supplier.postcode);
    setRegion(supplier.region);
  };

  const submit = async () => {
    setLoading(true);
    const update = {
      address,
      city,
      postcode,
      region,
      country,
    };
    await firestore
      .collection(DBCollection.suppliers)
      .doc(supplier.id)
      .update(update);
    setLoading(false);
    nextStep();
  };

  const canGoNext = () => {
    return address.trim() !== '';
  };

  const renderButtons = () => {
    if (!address) return null;
    return editingAddress ? (
      <button
        type="button"
        onClick={() => cancelEditingAddress()}
        className="inline-flex items-center px-4 py-2 border border-secondary-300 shadow-sm font-medium text-secondary-700 bg-white hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
      >
        Cancel
      </button>
    ) : (
      <button
        type="button"
        onClick={() => setEditingAddress(true)}
        className="inline-flex items-center px-4 py-2 border border-secondary-300 shadow-sm font-medium text-secondary-700 bg-white hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
      >
        Edit
      </button>
    );
  };

  return (
    <div className="WizardStep text-secondary-500">
      <Gifter className="h-36 w-36 stroke-current fill-current text-secondary-600" />
      <Logo
        className="h-12 stroke-current fill-current text-secondary-600"
        color="secondary-600"
      />
      <div className="py-3">
        <h1 className="text-3xl font-serif">Getting Started</h1>
        <p className="text-xl font-sans">Where are you located?</p>
      </div>

      <div>
        <div className="mt-2">
          {address && (
            <div className="bg-white border-2 border-secondary-500 px-6 py-2 flex items-center justify-between">
              <div className="flex items-center">
                <div className="my-3 ml-2">
                  <div className="text-sm font-medium text-secondary-600">
                    <h3>{address}</h3>
                  </div>
                  <div className="mt-1 text-sm text-secondary-600 sm:flex sm:items-center">
                    <div>{region}</div>
                  </div>
                  <div className="mt-1 text-sm text-secondary-600 sm:flex sm:items-center">
                    <div>{city}</div>
                    {address && (
                      <span
                        className="hidden sm:mx-2 sm:inline"
                        aria-hidden="true"
                      >
                        &middot;
                      </span>
                    )}
                    <div>
                      <strong>{postcode}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">{renderButtons()}</div>
            </div>
          )}
          {editingAddress && (
            <div className="mt-2">
              <GooglePlacesAutocomplete
                apiOptions={{ language: 'en', region: 'nz' }}
                apiKey="AIzaSyBOt_oMTA05lsT5SgmOFs27iXokXZJAKJo"
                selectProps={{
                  googlePlace,
                  onChange: setGooglePlace,
                  components: {
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  },
                  styles: {
                    control: () => ({
                      padding: '1em',
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: colors.secondary[500],
                    }),
                    input: () => ({
                      border: 'none',
                    }),
                  },
                  placeholder: 'Search for an address',
                }}
              />
            </div>
          )}
        </div>

        <div className="footer">
          <LoadingButton
            loading={loading}
            feedback="Sit Tight..."
            className="bg-secondary-600
          text-white text-xl font-normal
          py-2 px-3 mt-3
          hover:bg-secondary-700
          disabled:opacity-50 disabled:pointer-events-none
          "
            onClick={submit}
            disabled={!canGoNext()}
          >
            Continue
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
