import { useOrders } from '../../../../hooks';
import React from 'react';

import './RecentOrders.scss';
import { map } from 'lodash';
import { OrderItem } from './Order';
import { Link } from 'react-router-dom';

const RecentOrders = () => {
  const orders = useOrders();
  return (
    <div className="mt-8 text-center">
      {!orders || orders.length <= 0 ? (
        <h5>You have no recent orders.</h5>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-4">
            <div className="font-bold text-left p-2">Recent Orders</div>
            <div className="text-right p-2">
              <Link to="/orders">See All</Link>
            </div>
          </div>
          <div className="overflow-hidden">
            <ul className="divide-y divide-gray-200">
              {map(orders, (order) => (
                <OrderItem key={order.id as string} order={order} />
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default RecentOrders;
