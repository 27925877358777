import React, { useEffect } from 'react';
import Sidebar from './Sidebar/Sidebar';
import Content from './Content';
import versionCompare from 'version-compare';
import './AppWithSidebar.scss';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import { useAppMetadata } from '../hooks';
import toast from 'react-hot-toast';

const AppWithSideBar: React.FC = (props) => {
  const { children, ...rest } = props;
  const [metadata, currentVersion] = useAppMetadata();
  useEffect(() => {
    if (metadata && versionCompare(metadata.release.version, currentVersion)) {
      toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-lg w-full bg-white shadow-xl pointer-events-auto flex border border-transparent`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900 my-0">
                    <span role="img" aria-label="tada">
                      🎉
                    </span>{' '}
                    A new version of The Giftery is available!
                  </p>
                  <p className="mt-1 text-sm text-gray-500 my-0">
                    Reload your browser to update now.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => {
                  toast.dismiss(t.id);
                  window.location.assign(
                    `${window.location.href}?v=${new Date().getTime()}`
                  );
                }}
                className="w-full rounded-none p-4 flex items-center justify-center text-sm font-medium text-secondary-600 hover:text-secondary-500 focus:outline-none"
              >
                Reload
              </button>
            </div>
          </div>
        ),
        {
          position: 'bottom-center',
          id: 'release-notification',
          duration: Infinity,
        }
      );
    }
  }, [metadata, currentVersion]);
  return (
    <div className="grid grid-cols-12 bg-secondary-100 font-sans">
      <Sidebar />
      <Content>{children}</Content>
    </div>
  );
};

export default compose<React.ComponentType>(
  firestoreConnect(() => [
    {
      collection: DBCollection.categories,
    },
    {
      collection: DBCollection.hobbies,
    },
    {
      collection: DBCollection.ages,
    },
    {
      collection: DBCollection.genders,
    },
    {
      collection: DBCollection._metadata,
    },
  ])
)(AppWithSideBar);
