import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FiGrid,
  FiShoppingCart,
  FiSettings,
  FiBox,
  FiUser,
  FiPower,
} from 'react-icons/fi';
import { classNames } from '@giftery/utils';
import { Logo } from '@giftery/ui/logo';
import { useMe, useStore } from '../../hooks';
import { useBreakpoint } from '@giftery/ui/hooks';
import { IconType } from 'react-icons';

interface NavigationItem {
  name: string;
  icon: IconType;
  href: string;
  count?: number;
}

const SidebarMenu = () => {
  const me = useMe();
  const store = useStore();
  const breakpoint = useBreakpoint();

  const navigation: NavigationItem[] = [
    { name: 'Dashboard', icon: FiGrid, href: '/dashboard' },
    {
      name: 'Products',
      icon: FiBox,
      href: '/products',
      count: store?.counters.products.total ?? 0,
    },
    {
      name: 'Orders',
      icon: FiShoppingCart,
      href: '/orders',
      count: store?.counters.orders.open ?? 0,
    },
    { name: 'Settings', icon: FiSettings, href: '/settings' },
  ];

  const isActive = (href: string): boolean => {
    const pathname = window.location.pathname;
    return pathname.indexOf(href) > -1;
  };

  const renderMenuLabel = (item: NavigationItem) => {
    return (
      <>
        <span className="flex-1">{item.name}</span>
        {item.count ? (
          <span
            className={classNames(
              window.location.pathname.indexOf(item.href) > -1
                ? 'bg-secondary-600'
                : 'bg-secondary-700',
              'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full text-white'
            )}
          >
            {item.count}
          </span>
        ) : null}
      </>
    );
  };

  const renderUserIcon = () => {
    switch (breakpoint) {
      case 'xl':
      case 'lg':
      case 'md':
      case 'sm':
        return null;
      default:
        return (
          <NavLink to="/settings/profile" className="flex-shrink-0  group">
            <div className="flex items-center">
              <div>
                {me?.avatarUrl ? (
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={me?.avatarUrl || ''}
                    alt=""
                  />
                ) : (
                  <FiUser
                    className="inline-block h-9 w-9
                rounded-full stroke-current fill-current text-secondary-500 bg-secondary-50
                sm:h-6 sm:w-6
                "
                  />
                )}
              </div>
              <div className="ml-2 hover:no-underline">
                <p className="text-sm font-medium text-white my-0 no-underline group-hover:no-underline hover:no-underline">
                  {me?.displayName || me?.email?.split('@').shift()}
                </p>
                <p className="text-xs font-medium text-secondary-200 my-0 no-underline group-hover:text-white group-hover:no-underline hover:no-underline">
                  View profile
                </p>
              </div>
            </div>
          </NavLink>
        );
    }
  };

  return (
    <div className="flex flex-col col-span-1 xl:col-span-2 h-0 flex-1 bg-secondary-500 min-h-screen max-h-screen">
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <Logo className="max-h-12 fill-current text-white" height={48} />
        </div>
        <nav className="mt-5 flex-1 px-2 space-y-1" aria-label="Sidebar">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={classNames(
                isActive(item.href)
                  ? 'bg-secondary-700'
                  : 'text-secondary-100 hover:bg-secondary-600 hover:bg-opacity-75',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white hover:no-underline justify-center xl:justify-start'
              )}
            >
              <item.icon
                className="mr-0 xl:mr-3 flex-shrink-0 h-6 w-6 text-secondary-300"
                aria-hidden="true"
              />
              {renderMenuLabel(item)}
            </NavLink>
          ))}
        </nav>
      </div>
      <div className="flex-shrink-0 flex border-t border-secondary-800 p-4 justify-between">
        {renderUserIcon()}

        <NavLink
          to="/login"
          className="flex-shrink-0 group text-white flex justify-end items-center text-sm px-2"
        >
          <FiPower title="Sign out" />
        </NavLink>
      </div>
    </div>
  );
};

export default SidebarMenu;
